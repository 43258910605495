import React, { memo } from "react";
import PropTypes from "prop-types";
import { ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { AppConstant } from "const";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import AvatarGroup from "./avatar/AvatarGroup";

const AvatarInfo = props => {
  const {
    avatar,
    title,
    secondTitle,
    endComponent,
    size,
    avatarProps,
    titleProps,
    privateF,
    itemProps,
    avatarRootProps,
    ...otherProps
  } = props;
  const classes = useStyleBySize(size, Boolean(avatarProps?.onClick));
  const defaultClasses = useStyles();

  return (
    <ListItem secondaryAction={endComponent} {...otherProps} classes={{ root: itemProps }}>
      <ListItemAvatar {...avatarProps}>
        <AvatarGroup
          group={{
            groupType: avatar.type,
            groupName: avatar?.name,
            privateF: privateF,
          }}
          avatarId={avatar.avatarId}
          sx={classes.avatar}
          classes={{ root: clsx(defaultClasses.defaultAvatar, avatarRootProps) }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography sx={classes.title} className="ellipsis" {...titleProps}>
            {title || ""}
          </Typography>
        }
        secondary={
          <Typography sx={classes.secondTitle} className="ellipsis" dangerouslySetInnerHTML={{ __html: secondTitle }} />
        }
      />
    </ListItem>
  );
};

AvatarInfo.propTypes = {
  avatar: PropTypes.shape({
    name: PropTypes.string,
    src: PropTypes.string,
    avatarId: PropTypes.string,
    type: PropTypes.number,
  }),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  endComponent: PropTypes.node,
  size: PropTypes.string,
  button: PropTypes.bool,
  avatarProps: PropTypes.object,
  titleProps: PropTypes.object,

  onClick: PropTypes.func,
};

export default memo(AvatarInfo);

const useStyleBySize = (size, hasClickEvent) => {
  const commonStyle = { minWidth: "unset", marginRight: 2, cursor: hasClickEvent ? "pointer" : "default" };
  switch (size) {
    case AppConstant.SIZE_OBJ.small:
      return {
        avatar: { width: 36, height: 36, ...commonStyle },
        title: { fontWeight: 600, fontSize: 12 },
        secondTitle: { fontWeight: 500, fontSize: 10, color: "#6C7078" },
      };
    case AppConstant.SIZE_OBJ.large:
      return {
        avatar: { width: 54, height: 54, ...commonStyle },
        title: { fontWeight: 700, fontSize: 15 },
        secondTitle: { fontWeight: 700, fontSize: 12, color: "#6C7078" },
      };

    default:
      return {
        avatar: { width: 48, height: 48, ...commonStyle },
        title: { fontWeight: 600, fontSize: 14 },
        secondTitle: { fontWeight: 600, fontSize: 12, color: "#6C7078" },
      };
  }
};

const useStyles = makeStyles({
  defaultAvatar: {
    backgroundColor: "#BEBEBE",
  },
});

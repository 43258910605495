import { ApiConstant, AppConstant, KeyConstant, SystemConstant } from "const";
import { BGlobalServerImage } from "const/image.const";
import store, { DeviceActions } from "redux-store";
import { saveKeysOfDevice } from "sagas/account-key.saga";
import { checkCurrentBranchByPrefix } from "sagas/saga.helper";
import { CommonBranchInfoService, remoteApiFactory } from "services";
import { LocalDbManagement, getInteractor } from "services/local.service";
import { StorageUtil, getDataFromPrefixKey, toCamel } from "utils";
import { getCommonLang } from "utils/lang.utils";
import { isEqual } from "lodash";
import { changeBranchServer } from "utils/branch.utils";
import { handlingLogoutBranch } from "utils/auth.utils";

export const deviceNewService = async (prefixKey, accountId, deviceId) => {
  try {
    const response = await remoteApiFactory.getBranchApi(prefixKey).getDevice({
      account_id: accountId,
      device_id: deviceId,
    });

    if (response.status === ApiConstant.STT_OK) {
      const remoteDeviceList = response.data.data || [];
      await getInteractor(prefixKey).LocalDeviceService.save(remoteDeviceList);

      await saveKeysOfDevice(prefixKey, { accountId, deviceId });

      return true;
    }
  } catch (error) {
    console.log("get one device from remote fail: ", error);
  }

  return false;
};

export const deviceUpdateService = async (prefixKey, accountId, deviceId) => {
  const deviceUpdate = await getDevice(prefixKey, accountId, deviceId);
  if (deviceUpdate.id) {
    await saveKeysOfDevice(prefixKey, { accountId, deviceId });
  }

  const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  if (currentAccountId === accountId) {
    store.dispatch(
      DeviceActions.deviceSet({
        fetchStatus: Date.now(),
      }),
    );
  }

  return true;
};

// Device is logged out
export const deviceDeleteService = async (prefixKey, accountId, deleteDeviceId) => {
  const currentDeviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
  if (currentDeviceId === deleteDeviceId) {
    window.dispatchEvent(
      new CustomEvent(AppConstant.NOTICE_EVENT_NAME, {
        detail: {
          content: getCommonLang("TXT_SESSION_EXPIRED"),
          callback: () => handleLogoutAndChooseBranch(prefixKey),
        },
      }),
    );
  } else if (deviceId === deleteDeviceId) {
    await handlingLogoutBranch(prefixKey);
  } else {
    await getDevice(prefixKey, accountId, deleteDeviceId);
    const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    if (currentAccountId === accountId) {
      store.dispatch(
        DeviceActions.deviceSet({
          fetchStatus: Date.now(),
        }),
      );
    }
  }

  return true;
};

export const deviceUpdateKeyService = async (prefixKey, accountId, deviceId) => {
  try {
    const device = await getDevice(prefixKey, accountId, deviceId);
    if (device && device.id) {
      await saveKeysOfDevice(prefixKey, { accountId, deviceId });
    }

    return true;
  } catch (error) {
    console.log("device update key fail: ", error);
  }

  return false;
};

export const deviceChangeMasterService = async (prefixKey, accountId) => {
  try {
    const response = await remoteApiFactory.getBranchApi(prefixKey).getDevice({
      account_id: accountId,
    });
    if (response.status === ApiConstant.STT_OK) {
      const responseData = response.data.data;
      await getInteractor(prefixKey).LocalDeviceService.save(responseData);

      const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
      if (currentAccountId === accountId) {
        store.dispatch(
          DeviceActions.deviceSet({
            fetchStatus: Date.now(),
          }),
        );
      }

      return true;
    }
  } catch (error) {
    console.log("device change master service fail: ", error);
  }

  return false;
};

export const getAllDeviceFromRemote = async (prefixKey, accountId) => {
  prefixKey = prefixKey || StorageUtil.getCurrentPrefixKey();
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
  const isCurrentBranch = checkCurrentBranchByPrefix(prefixKey);
  try {
    let accountIds;
    if (accountId) accountIds = [accountId];
    else {
      const localAccountList = await getInteractor(prefixKey).LocalAccountService.getAllInBranch(branchId);
      accountIds = localAccountList.map(item => item.id);
    }

    const lastTimeOfDevice = await getInteractor(prefixKey).LocalDeviceService.getLastDevices(accountIds); // [{account_id; last_time}]
    const accountWithDeviceId = lastTimeOfDevice.map(item => item.account_id) || [];
    const accountWithoutDeviceObj = accountIds
      .filter(item => false === Boolean(accountWithDeviceId.includes(item)))
      .map(item => ({ account_id: item, last_time: 0 }));

    const response = await remoteApiFactory
      .getBranchApi(prefixKey)
      .getDevice({ account_time_ids: JSON.stringify([...lastTimeOfDevice, ...accountWithoutDeviceObj]) });

    const remoteDeviceList = response.data?.data;
    if (response.status === ApiConstant.STT_OK && Array.isArray(remoteDeviceList)) {
      await getInteractor(prefixKey).LocalDeviceService.save(remoteDeviceList);
    }

    if (isCurrentBranch) {
      store.dispatch(
        DeviceActions.deviceSet({
          fetchStatus: Date.now(),
        }),
      );
    }

    return true;
  } catch (error) {
    console.log("get all device from remote fail: ", error);
  }

  return false;
};

export const getDevice = async (prefixKey, accountId, deviceId) => {
  let localDevice = await getInteractor(prefixKey).LocalDeviceService.get(deviceId);
  try {
    const response = await remoteApiFactory.getBranchApi(prefixKey).getDevice({
      account_id: accountId,
      device_id: deviceId,
    });
    if (response.status === ApiConstant.STT_OK) {
      const remoteDeviceList = response.data?.data || [];
      const remoteDevice = remoteDeviceList.find(item => item.id === deviceId);
      if (remoteDevice && false === isEqual(localDevice, remoteDevice)) {
        await getInteractor(prefixKey).LocalDeviceService.save([remoteDevice]);
        localDevice = remoteDevice;
      }
    }
  } catch (error) {
    console.log("get one device from remote fail: ", error);
  }

  return localDevice;
};

export const handleLogoutAndChooseBranch = async prefixKey => {
  const currentPrefixKey = StorageUtil.getCommonKey(KeyConstant.KEY_PREFIX);
  await handlingLogoutBranch(prefixKey);

  if (prefixKey === currentPrefixKey) {
    const [accountId, branchId] = getDataFromPrefixKey(prefixKey);
    const activeBranchList = await LocalDbManagement.find({ state: SystemConstant.STATE.active });
    let dbManagementInfo = activeBranchList.find(
      item => false === Boolean(item.account_id === accountId && item.branch_id === branchId),
    );

    if (dbManagementInfo) {
      dbManagementInfo = toCamel(dbManagementInfo);
      const avatarUrl = CommonBranchInfoService.getBranchAvatarUrl(
        dbManagementInfo.branchDomain,
        dbManagementInfo.branchId,
      );
      const selectBranch = {
        id: dbManagementInfo.branchId,
        accountId: dbManagementInfo.accountId,
        loginState: dbManagementInfo.state,
        phone: dbManagementInfo.ownerName,
        name: dbManagementInfo.branchName,
        domain: dbManagementInfo.branchDomain,
        type:
          dbManagementInfo.branchId === SystemConstant.GLOBAL_BRANCH_ID
            ? SystemConstant.SERVER_TYPE.server
            : SystemConstant.SERVER_TYPE.branch,
        branchIcon: avatarUrl || BGlobalServerImage,
      };

      changeBranchServer(selectBranch);
      window.location.reload();
    }
  }
};

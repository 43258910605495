import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchBar from "components/SearchBar";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import ForwardCategory from "./ForwardCategory";
import clsx from "clsx";
import { getSearchResult } from "utils/view.utils";
import { BranchSelectors } from "redux-store";
import { getInteractor } from "services/local.service";
import { useAccountList, useContactList } from "hooks";
import InfiniteScroll from "components/InfiniteScroll";
import { uniqBy } from "lodash";
import { convertString2JSON, StorageUtil, toSnake } from "utils";
import { saveMessageInQueue } from "pages/HomePage/components/MessengerChat/ConversationContext";
import PreviewMessage from "./PreviewMessage";
import { useAlertContext } from "components/context/AlertContext";
import { getMessageFileList, replaceId2Name } from "utils/message.utils";

const PAGING_SIZE = 10;
const QUERY_MODE = {
  personal: 0,
  group: 1,
  channel: 2,
  end: 3,
};

const ForwardingDialog = ({ open, onClose, forwardMessage, forwardAttachmentId }) => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const classes = useStyles();
  const searchRef = useRef("");
  const { showAlert } = useAlertContext();
  const isBranchServer = useSelector(BranchSelectors.isBranchServer);
  const isForwardAttachment = Boolean(forwardAttachmentId);
  let messageFileList = getMessageFileList(forwardMessage);
  if (forwardAttachmentId) messageFileList = messageFileList.filter(item => item.attachmentId === forwardAttachmentId);

  const { accountList } = useAccountList();
  const { contactList } = useContactList();

  const [channels, setChannels] = useState([]);
  const [groups, setGroups] = useState([]);
  const [personals, setPersonals] = useState([]);
  const [queryMode, setQueryMode] = useState(isBranchServer ? QUERY_MODE.channel : QUERY_MODE.group);
  const [totalItems, setTotalItems] = useState({
    channels: 0,
    groups: 0,
    personals: 0,
  });
  const [isSearching, setIsSearching] = useState(false);

  const handleSearch = async searchValue => {
    searchRef.current = searchValue;
    setIsSearching(Boolean(searchValue));
    if (isBranchServer) {
      handleQueryChannel(true);
    } else {
      handleQueryGroup(true);
    }
  };

  const handleQueryChannel = async isSearching => {
    const newChannelList = await getInteractor(prefixKey).LocalGroupService.searchGroupByCondition({
      keyword: searchRef.current,
      group_type: SystemConstant.GROUP_CHAT_TYPE.channel,
      limit: PAGING_SIZE,
      offset: isSearching ? 0 : channels.length,
      account_id: accountId,
      branch_id: branchId,
    });

    const uniqueArray = uniqBy(isSearching ? newChannelList : channels.concat(newChannelList), "id");
    setChannels(uniqueArray);

    const addNumber = PAGING_SIZE - newChannelList.length;
    if (addNumber > 0) {
      setQueryMode(QUERY_MODE.group);
      handleQueryGroup(isSearching);
    } else {
      setQueryMode(QUERY_MODE.channel);
    }
  };

  const handleQueryGroup = async isSearching => {
    const newGroupList = await getInteractor(prefixKey).LocalGroupService.searchGroupByCondition({
      keyword: searchRef.current,
      group_type: SystemConstant.GROUP_CHAT_TYPE.group,
      limit: PAGING_SIZE,
      offset: isSearching ? 0 : groups.length,
      account_id: accountId,
      branch_id: branchId,
    });

    const uniqueArray = uniqBy(isSearching ? newGroupList : groups.concat(newGroupList), "id");
    setGroups(uniqueArray);

    const addNumber = PAGING_SIZE - newGroupList.length;
    if (addNumber > 0) {
      setQueryMode(QUERY_MODE.personal);
      handleQueryPersonal(isSearching);
    } else {
      setQueryMode(QUERY_MODE.group);
    }
  };

  const handleQueryPersonal = isSearching => {
    const searchPersonalList = getSearchResult(searchRef.current, isBranchServer ? accountList : contactList, [
      "name",
      "contactName",
      "phone",
    ]);
    const offset = isSearching ? 0 : personals.length;
    const addPersonalItems = searchPersonalList.slice(offset, offset + PAGING_SIZE);

    const uniqueArray = uniqBy(isSearching ? addPersonalItems : personals.concat(addPersonalItems), "id");
    setPersonals(uniqueArray);
    if (uniqueArray.length === searchPersonalList.length) setQueryMode(QUERY_MODE.end);
  };

  const handleScrollDown = async () => {
    switch (queryMode) {
      case QUERY_MODE.channel:
        handleQueryChannel();
        break;

      case QUERY_MODE.group:
        handleQueryGroup();
        break;

      case QUERY_MODE.personal:
        handleQueryPersonal();
        break;

      default:
        break;
    }
  };

  const handleSetTotalItems = async () => {
    const totalItems = { channels: 0, groups: 0, personals: 0 };

    if (isBranchServer) {
      const totalChannel = await getInteractor(prefixKey).LocalGroupService.count({
        accountId,
        branchId,
        state: SystemConstant.STATE.active,
        group_type: SystemConstant.GROUP_CHAT_TYPE.channel,
      });

      totalItems.channels = totalChannel;
    }

    const totalGroup = await getInteractor(prefixKey).LocalGroupService.count({
      accountId,
      branchId,
      state: SystemConstant.STATE.active,
      group_type: SystemConstant.GROUP_CHAT_TYPE.group,
    });
    totalItems.groups = totalGroup;

    totalItems.personals = isBranchServer ? accountList.length : contactList.length;

    setTotalItems(totalItems);
  };

  const handleForward = async groupId => {
    if (groupId) {
      const attachmentIds = messageFileList.map(item => item.attachmentId);
      const forward = {
        attachment_ids: attachmentIds,
        from_group_id: forwardMessage.groupId,
      };

      const currentGroup = (await getInteractor(prefixKey).LocalGroupService.get(groupId)) || {};
      const mentions = convertString2JSON(forwardMessage.mentions, null);

      let newContent = forwardMessage.content;
      if (FILE_SEND_TYPE.includes(forwardMessage.sendType)) {
        const oldMessageContent = convertString2JSON(forwardMessage.content, {});
        let content_message = isForwardAttachment ? "" : oldMessageContent.content_message;
        if (mentions && content_message) {
          content_message = await replaceId2Name(oldMessageContent.content_message, currentGroup.groupMembers, false);
        }
        const newContentObj = {
          ...oldMessageContent,
          content_message,
        };

        if (oldMessageContent.multiple_file) {
          const multipleFile = messageFileList.map(item => ({
            attachmentId: item.attachmentId,
            metaData: item.metaData,
            aesKeyInfo: item.aesKeyInfo,
          }));
          newContentObj.multiple_file = toSnake(multipleFile);
        }

        newContent = JSON.stringify(newContentObj);
      } else if (mentions) {
        newContent = await replaceId2Name(forwardMessage.content, currentGroup.groupMembers, false);
      }

      const data2Forward = {
        prefixKey: StorageUtil.getCurrentPrefixKey(),
        groupId: groupId,
        threadId: null,
        sendType: forwardMessage.sendType,
        content: newContent,
        options: forwardMessage.options,
        forward: forward,
      };

      await saveMessageInQueue(data2Forward);
      showAlert({ content: getCommonLang("TXT_FORWARD_SUCCESS") });
    } else {
      showAlert({ content: getCommonLang("TXT_FORWARD_FAILED"), alertProps: { severity: "error" } });
    }

    onClose();
  };

  useEffect(() => {
    handleSetTotalItems();
  }, [accountList, contactList, isBranchServer]);

  useEffect(() => {
    if (isBranchServer) {
      handleQueryChannel();
    } else {
      handleQueryGroup();
    }
  }, [isBranchServer]);

  return (
    <Dialog open={open} classes={{ paper: classes.forwardRoot }}>
      <DialogTitle>
        <Stack mt={1}>
          <Typography className={classes.title}>{getCommonLang("TXT_FORWARD")}</Typography>
          <IconButton className={classes.closeBtn} edge="end" onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.forwardPreview}>
          <PreviewMessage
            message={forwardMessage}
            messageFileList={messageFileList}
            isForwardAttachment={isForwardAttachment}
          />
        </Box>

        <SearchBar
          onChange={handleSearch}
          placeholder={getNSLang(LangConstant.NS_HOME, "TXT_CHAT_SEARCH_PLACE_HOLDER")}
          classes={{ root: classes.margin }}
        />

        <InfiniteScroll className={clsx(classes.margin, classes.forwardSelectList)} onScrollToBottom={handleScrollDown}>
          <Stack spacing={1}>
            {channels.length > 0 && isBranchServer && (
              <ForwardCategory
                type={SystemConstant.GROUP_CHAT_TYPE.channel}
                data={channels}
                totalItems={totalItems.channels}
                onSend={handleForward}
                isSearching={isSearching}
              />
            )}

            {groups.length > 0 && (
              <ForwardCategory
                type={SystemConstant.GROUP_CHAT_TYPE.group}
                data={groups}
                totalItems={totalItems.groups}
                onSend={handleForward}
                isSearching={isSearching}
              />
            )}

            {personals.length > 0 && (
              <ForwardCategory
                type={SystemConstant.GROUP_CHAT_TYPE.personal}
                data={personals}
                totalItems={totalItems.personals}
                onSend={handleForward}
                isSearching={isSearching}
              />
            )}
          </Stack>
        </InfiniteScroll>
      </DialogContent>
    </Dialog>
  );
};

export default ForwardingDialog;

const FILE_SEND_TYPE = [SystemConstant.SEND_TYPE.file, SystemConstant.SEND_TYPE.image];

const useStyles = makeStyles({
  forwardRoot: {
    width: 500,
    height: "fit-content",
    maxHeight: "90%",
  },

  title: {
    fontSize: 16,
    fontWeight: 700,
    margin: "auto",
    position: "relative",
  },

  closeBtn: {
    position: "absolute",
    right: 24,
    top: 12,
  },

  forwardPreview: {
    width: "100%",
    height: "fit-content",
    maxHeight: 250,
    borderRadius: 20,
    overflowY: "auto",
  },

  margin: {
    marginTop: 10,
  },

  forwardSelectList: {
    maxHeight: 350,
  },
});

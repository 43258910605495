import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StorageUtil } from "utils";
import { KeyConstant, SystemConstant } from "const";

const useBlockedAccountStatus = () => {
  const blockedAccount = useSelector(state => state.conversationRedux.blockedAccount);

  const [isBlockedAccount, setIsBlockedAccount] = useState(false); // Login account block receiver
  const [isBlockedByAccount, setIsBlockedByAccount] = useState(false); // Receiver block login account

  useEffect(() => {
    const isValidData = blockedAccount && blockedAccount.contactId && blockedAccount.accountId;
    if (!isValidData || blockedAccount.status === SystemConstant.CONTACT_STATUS.normal) {
      setIsBlockedAccount(false);
      setIsBlockedByAccount(false);
      return;
    }

    const loginAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID); // loginAccountId: sender

    // Case 1: receiver block login account
    setIsBlockedByAccount(blockedAccount.contactId === loginAccountId);

    // Case 2: login account block receiver
    setIsBlockedAccount(blockedAccount.accountId === loginAccountId);
  }, [blockedAccount]);

  return {
    isBlockedAccount,
    isBlockedByAccount,
    isBlocked: isBlockedAccount || isBlockedByAccount,
  };
};

export default useBlockedAccountStatus;

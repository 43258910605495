import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ConversationSelectors } from "redux-store";
import { SystemConstant } from "const";
import { getInteractor } from "services/local.service";
import { getAllDeviceFromRemote } from "pubsub/services/device.service";
import { StorageUtil } from "utils";

const useOnlineGroup = () => {
  const selectedGroupId = useSelector(ConversationSelectors.getSelectedGroupId);
  const [isOnline, setIsOnline] = useState(false);

  const checkOnline = async prefixKey => {
    const groupDetail = await getInteractor(prefixKey).LocalGroupService.get(selectedGroupId);
    const isChatWithPersonal =
      Array.isArray(groupDetail.groupMembers) &&
      groupDetail.groupMembers.length > 0 &&
      groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;
    const personalAccountId = isChatWithPersonal ? groupDetail.groupMembers[0].id : null;

    if (personalAccountId) {
      await getAllDeviceFromRemote(prefixKey, personalAccountId);
      const isOnline = await getInteractor(prefixKey).LocalDeviceService.isOnlineAccount(personalAccountId);
      setIsOnline(isOnline);
    } else {
      setIsOnline(false);
    }
  };

  useEffect(() => {
    const prefixKey = StorageUtil.getCurrentPrefixKey();
    checkOnline(prefixKey);
  }, [selectedGroupId]);

  return { isOnline };
};

export default useOnlineGroup;

import { useCallback, useEffect, useState } from "react";
import { AppConstant, KeyConstant, SystemConstant } from "const";
import { StorageUtil } from "utils";
import { useSelector } from "react-redux";
import { BranchSelectors, GroupInfoSelectors, SystemSelectors } from "redux-store";
import { getInteractor } from "services/local.service";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";

export const LIMIT_DISPLAY = 5;
const useConversation = (groupTypes = Object.values(SystemConstant.GROUP_CHAT_TYPE)) => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const isSynchronizing = useSelector(SystemSelectors.isSystemSynchronizing);
  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);
  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);
  const sendingMessageLocal = useSelector(state => state.conversationRedux.sendingMessageLocal);
  const deleteGroup = useSelector(GroupInfoSelectors.getDeleteGroup);
  const updatingGroupData = useSelector(GroupInfoSelectors.getUpdatingGroup);

  const [limit, setLimit] = useState(LIMIT_DISPLAY);
  const [totalItems, setTotalItems] = useState(0);
  const [groupList, setGroupList] = useState([]);

  const getTotalGroup = async () => {
    const countItems = await getInteractor(prefixKey).LocalGroupService.count({
      account_id: accountId,
      branch_id: branchId,
      state: SystemConstant.STATE.active,
      group_type: groupTypes,
    });
    if (countItems !== totalItems) setTotalItems(countItems);
  };

  const searchConversation = async (limitNum = LIMIT_DISPLAY) => {
    setLimit(limitNum);
    await getTotalGroup();

    const conversationList = await getInteractor(prefixKey).LocalGroupService.searchGroupByCondition({
      group_type: groupTypes,
      limit: limitNum,
      offset: 0,
      account_id: accountId,
      branch_id: branchId,
    });

    if (Array.isArray(conversationList) && !isEqual(conversationList, groupList)) setGroupList(conversationList);
  };

  const getConversation = useCallback(debounce(searchConversation, AppConstant.DEBOUNCE_TIME), [prefixKey]);

  const handleChangingGroup = async () => {
    getTotalGroup();

    // Update group
    const group = await getInteractor(prefixKey).LocalGroupService.get(updatingGroupData.id);
    if (!groupTypes.includes(group?.groupType)) return;

    const selectedIndex = groupList.findIndex(item => item.id === updatingGroupData.id);
    const newGroupList = [...groupList];
    const isChangeContent = Boolean(updatingGroupData.groupName || updatingGroupData.avatarId);

    // Updating group
    if (isChangeContent && selectedIndex >= 0) {
      const newGroup = { ...group, ...updatingGroupData };
      newGroupList[selectedIndex] = newGroup;
      setGroupList(newGroupList);
    } else if (selectedIndex < 0) {
      // New group
      newGroupList.unshift(group);
      setGroupList(newGroupList);
    }
  };

  useEffect(() => {
    getConversation(LIMIT_DISPLAY);
  }, [selectedBranch]);

  useEffect(() => {
    if (!isSynchronizing) getConversation(groupList.length || limit);
  }, [isSynchronizing, isUpdateViewMode, sendingMessageLocal]);

  useEffect(() => {
    if (deleteGroup && groupList.length > 0) {
      getTotalGroup();

      const deletedIndex = groupList.findIndex(item => item.id === deleteGroup.groupId);
      if (deletedIndex >= 0) setGroupList(groupList.filter(item => item.id !== deleteGroup.groupId));
    }
  }, [deleteGroup]);

  useEffect(() => {
    const isValidData = updatingGroupData && updatingGroupData.id && groupList;
    if (isValidData) {
      handleChangingGroup();
    }
  }, [updatingGroupData]);

  return {
    groupList,
    totalItems,
    getConversation,
  };
};

export default useConversation;

import { makeStyles } from "@mui/styles";
import ChatTypography from "../ChatTypography";
import MediaBox from "./MediaBox";

const SingleMedia = ({ fileInfo, onClick, groupId, caption, mentions, isCurrentDevice }) => {
  const classes = useStyles();

  return (
    <>
      <MediaBox fileInfo={fileInfo} onClick={onClick} groupId={groupId} isCurrentDevice={isCurrentDevice} />
      {caption && <ChatTypography messageContent={caption} mentions={mentions} className={classes.caption} />}
    </>
  );
};

export default SingleMedia;

const useStyles = makeStyles({
  caption: {
    fontSize: 14,
    fontWeight: 600,
    borderTop: "1px dotted #a7a7a7",
    marginTop: 5,
    width: "100%",
    padding: "10px 0 5px",
  },
});

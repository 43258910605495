import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Fab } from "@mui/material";
import { PersonAdd } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { LangConstant, SystemConstant } from "const";
import clsx from "clsx";
import { toCamel } from "utils";
import { AppDrawer, PrimaryTab, TabPanel } from "components";
import AddingGroupMembers from "./AddingGroupMembers";
import GroupMemberListItem from "./GroupMemberListItem";
import { getInteractor } from "services/local.service";
import { AccountSelectors } from "redux-store";

const GroupMemberList = ({ open, onClose, data, onCloseParent, isAdminGroup }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const dataMemberToAdmin = useSelector(state => state.groupInfoRedux.dataMemberToAdmin);

  const blockAccountId = useSelector(AccountSelectors.getBlockAccountId);

  const [selectedViewMode, setSelectedViewMode] = useState(VIEW_MODE_TAB.all);
  const [isListAddGroup, setIsListAddGroup] = useState(false);
  const [adminArray, setAdminArray] = useState([]);
  const [accountBlockArray, setAccountBlockArray] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);

  const onCloseAll = () => {
    onClose();
    onCloseParent();
  };

  const tabArray = [
    {
      label: getLabel(LangConstant.TXT_ALL_MEMBER_LIST),
    },
    {
      label: getLabel(LangConstant.TXT_ADMIN_MEMBER_LIST),
    },
  ];

  const handleRemoveMember = memberId => {
    setGroupMembers(groupMembers.filter(item => item.id !== memberId));
  };

  useEffect(() => {
    const memberArray = data.groupMembers || [];
    getInteractor()
      .LocalAccountGroupService.find({ group_id: data.id })
      .then(recordList => {
        const adminList = toCamel(recordList || [])
          .filter(item => item.type === SystemConstant.ROLE_OBJ.admin)
          .map(item => memberArray.find(member => member.id === item.accountId));
        setAdminArray(adminList);
        setGroupMembers(memberArray);
      });
  }, [data, dataMemberToAdmin]);

  useEffect(() => {
    getInteractor()
      .LocalContactService.find({
        status: SystemConstant.CONTACT_STATUS.block,
      })
      .then(blockedList => setAccountBlockArray(toCamel(blockedList || [])));
  }, [blockAccountId]);

  return (
    <AppDrawer
      title={getLabel(LangConstant.TXT_MEMBERS)}
      onClose={onClose}
      anchor="right"
      open={open}
      hideBackdrop={false}
      classes={{
        root: classes.drawerRoot,
      }}
    >
      <PrimaryTab
        TabIndicatorProps={{
          className: "hidden",
        }}
        classes={{
          root: classes.primaryTab,
          tabButtonSelected: classes.tabButtonSelected,
          tabLabel: clsx("semiBold-md-txt", classes.tabLabel),
          tabButton: classes.tabRoot,
          tabLabelSelected: classes.tabLabelSelected,
        }}
        onChange={setSelectedViewMode}
        selectedTabIndex={selectedViewMode}
        tabArray={tabArray}
      />
      <TabPanel index={VIEW_MODE_TAB.all} value={selectedViewMode}>
        <GroupMemberListItem
          data={groupMembers}
          groupDetail={data}
          onCloseParent={onCloseAll}
          dataAdmin={adminArray}
          accountBlockArray={accountBlockArray}
          isAdminGroup={isAdminGroup}
          onRemoveMember={handleRemoveMember}
        />
      </TabPanel>
      <TabPanel index={VIEW_MODE_TAB.admin} value={selectedViewMode}>
        <GroupMemberListItem
          data={adminArray}
          onCloseParent={onCloseAll}
          groupDetail={data}
          isAdminGroup={isAdminGroup}
          accountBlockArray={accountBlockArray}
          onRemoveMember={handleRemoveMember}
        />
      </TabPanel>
      {isAdminGroup && (
        <Fab
          color="primary"
          aria-label="add"
          className={classes.btnAdding}
          onClick={() => setIsListAddGroup(!isListAddGroup)}
        >
          <PersonAdd className={classes.icon} />
        </Fab>
      )}
      {isListAddGroup && <AddingGroupMembers open={true} onClose={() => setIsListAddGroup(false)} groupId={data.id} />}
    </AppDrawer>
  );
};

const VIEW_MODE_TAB = {
  all: 0,
  admin: 1,
};

GroupMemberList.propTypes = {};

export default memo(GroupMemberList);

const useStyles = makeStyles(theme => ({
  tabButtonSelected: {
    backgroundColor: "#F5F5F5",
  },

  tabRoot: {
    minHeight: "unset",
    borderRadius: 22,
    color: "black !important",
  },

  tabLabel: {
    marginTop: 0,
    color: "#7F7F7F",
  },
  tabLabelSelected: {
    color: "#1E272E",
  },
  drawerRoot: {
    "&>.MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
  },
  primaryTab: {
    padding: "0px 16px",
  },
  btnAdding: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    right: 16,
    bottom: 32,
    position: "absolute",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: "none",
    },
  },
  icon: {
    width: 24,
    height: 24,
  },
}));

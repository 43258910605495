import { ApiConstant } from "const";
import { ExecuteEvent } from "pubsub/PubSub.const";
import store, { ConversationActions } from "redux-store";
import { remoteApiFactory } from "services";
import { getInteractor } from "services/local.service";
import { toCamel } from "utils";

export const getFileList = async (prefixKey, attachmentIds, groupId) => {
  try {
    const params = {
      attachment_ids: attachmentIds,
    };
    if (groupId) params.group_id = groupId;
    const response = await remoteApiFactory.getBranchApi(prefixKey).getFilesInfo(params);
    if (response.status === ApiConstant.STT_OK) {
      const responseData = response.data;
      await getInteractor(prefixKey).LocalFileService.save(responseData);
      return responseData;
    } else if (response.status === ApiConstant.STT_NOT_FOUND) {
      return [];
    }
  } catch (error) {
    console.log("get file list error: ", error);
  }

  return null;
};

export const updateFileStatus = async (prefixKey, attachmentId, fileStatus, groupId) => {
  try {
    const params = {
      attachment_id: attachmentId,
      status: fileStatus,
    };
    if (groupId) params.group_id = groupId;

    const updateResponse = await remoteApiFactory.getBranchApi(prefixKey).postFileStatus(params);
    if (updateResponse.status === ApiConstant.STT_OK) {
      const responseData = updateResponse.data;
      await getInteractor(prefixKey).LocalFileService.update(
        {
          status: responseData.status,
          modified: Date.now(),
        },
        { id: responseData.id },
      );

      return true;
    }
  } catch (error) {
    console.log("file update status fail: ", error);
  }

  return false;
};

export const uploadedFileService = async (prefixKey, groupId, attachmentId) => {
  try {
    const remoteFileInfo = await getFileList(prefixKey, [attachmentId], groupId);
    if (remoteFileInfo) {
      await updateRemoteMessageFile(prefixKey, attachmentId, groupId);
      return ExecuteEvent.SUCCESSES;
    }
  } catch (error) {
    console.log("uploaded file error: ", error);
  }
  return ExecuteEvent.RETRY;
};

export const failedFileService = async (prefixKey, groupId, attachmentId) => {
  try {
    const remoteFileInfo = await getFileList(prefixKey, [attachmentId], groupId);
    if (remoteFileInfo) {
      await updateRemoteMessageFile(prefixKey, attachmentId, groupId);
      return ExecuteEvent.SUCCESSES;
    }
  } catch (error) {
    console.log("failed file service: ", error);
  }

  return ExecuteEvent.RETRY;
};

export const deleteFileService = () => {
  return ExecuteEvent.FAIL;
};

const updateRemoteMessageFile = async (prefixKey, attachmentId, groupId) => {
  const message = await getInteractor(prefixKey).LocalMessageService.getOneMsgByContent(groupId, attachmentId);
  if (message) {
    store.dispatch(
      ConversationActions.conversationSet({
        remoteUploadMessage: toCamel({ ...message, modified: Date.now() }),
      }),
    );
  }
};

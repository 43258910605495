import { ImageList, ImageListItem } from "@mui/material";
import MediaBox from "./MediaBox";
import { makeStyles } from "@mui/styles";

const MultipleMedia = ({ fileList, onClick, groupId, imageWidth, isCurrentDevice }) => {
  const classes = useStyles();
  const { columns, rowHeight } = getColumns(fileList.length, imageWidth);

  return (
    <ImageList cols={columns} gap={2} rowHeight={rowHeight}>
      {fileList.map(item => (
        <ImageListItem key={item.attachmentId} sx={{ width: rowHeight }}>
          <MediaBox
            fileInfo={item}
            onClick={onClick}
            customStyles={classes.imageItem}
            groupId={groupId}
            isCurrentDevice={isCurrentDevice}
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default MultipleMedia;

const IMAGE_WIDTH = 150;

const getColumns = (length, imageWidth) => {
  let columns = 1;
  if (length === 2 || length === 4) columns = 2;
  else columns = 3;
  imageWidth = imageWidth || IMAGE_WIDTH;
  const rowHeight = (imageWidth * 3) / columns; // Max display 3x3 square, each square has size 150x150

  return { columns, rowHeight };
};

const useStyles = makeStyles({
  imageItem: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 0,
  },
});

import { AppConstant, SystemConstant } from "const";
import { useEffect, useState } from "react";
import { getFileLocalDetails } from "services/attachment.service";
import { getInteractor } from "services/local.service";
import { isImage, isVideo, StorageUtil } from "utils";
import { getImageBlob, getUrlTemporary, getVideoThumbnailBlob } from "utils/file.utils";
import useCleanUpEffect from "./useCleanUpEffect";

// file: {metaData, groupId}
const useMediaUrl = (
  file,
  options = {
    isThumbnail: false,
  }, // {isThumbnail, width, height}
) => {
  const { isThumbnail, isCurrentDevice, ...compressOptions } = options || {};
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const isVideoUrl = isVideo(file?.metaData?.mimeType);
  const isImageUrl = isImage(file?.metaData?.mimeType);
  const { isMounted } = useCleanUpEffect();

  const [mediaUrl, setMediaUrl] = useState("");
  const [status, setStatus] = useState(SystemConstant.UPLOAD_STATUS.inProgress);

  const handleSetMediaSource = async () => {
    const fileRecord = await getInteractor(prefixKey).LocalFileService.get(file.attachmentId);
    if (false === Boolean(fileRecord && fileRecord.id)) return;

    if (fileRecord.status === SystemConstant.UPLOAD_STATUS.failed && !isCurrentDevice) {
      setStatus(SystemConstant.UPLOAD_STATUS.failed);
      return;
    }

    const mediaData = await getFileLocalDetails(prefixKey, file, {
      groupId: file.groupId,
      isCurrentDevice: Boolean(isCurrentDevice),
    });
    if (!mediaData.mediaPath) return false;

    // Using origin file
    let mediaUrl = "";
    if (false === Boolean(isThumbnail)) {
      mediaUrl = mediaData.mediaPath;
    } else {
      // Thumbnail: compress file before display
      let responseBlob;
      try {
        if (isVideoUrl) {
          responseBlob = await getVideoThumbnailBlob(mediaData.mediaPath);
        } else if (isImageUrl) {
          responseBlob = await getImageBlob(mediaData.mediaPath);
        }
      } catch (error) {
        console.error(error, mediaData);
      }

      if (responseBlob && responseBlob.size > 0) {
        const urlTemporary = await getUrlTemporary(responseBlob, compressOptions);
        if (urlTemporary) {
          mediaUrl = urlTemporary;
        }
      } else {
        console.log("Error", { mediaData, responseBlob });
      }
    }

    if (isMounted()) {
      setMediaUrl(mediaUrl);
      if (fileRecord.status === SystemConstant.UPLOAD_STATUS.failed) {
        setStatus(SystemConstant.UPLOAD_STATUS.failed);
      } else if (isCurrentDevice) {
        setStatus(fileRecord.status);
      } else if (Boolean(mediaUrl)) {
        setStatus(SystemConstant.UPLOAD_STATUS.success);
      }
    }
  };

  useEffect(() => {
    if (file && file.attachmentId && file.metaData) handleSetMediaSource();
  }, [file]);

  useEffect(() => {
    const checkingLoadingTimeout = setTimeout(() => {
      setStatus(mediaUrl ? SystemConstant.UPLOAD_STATUS.success : SystemConstant.UPLOAD_STATUS.failed);
      if (!mediaUrl) getInteractor(prefixKey).LocalFileService.updateFileFail(file.attachmentId);
    }, AppConstant.CHECK_UPLOAD_TIMEOUT);

    return () => {
      if (isThumbnail && mediaUrl) {
        console.log("revokeObjectURL", mediaUrl);
        URL.revokeObjectURL(mediaUrl);
      }

      if (checkingLoadingTimeout) clearTimeout(checkingLoadingTimeout);
    };
  }, [mediaUrl]);

  return {
    mediaUrl,
    isFailedLoading: status === SystemConstant.UPLOAD_STATUS.failed,
    isLoading: status === SystemConstant.UPLOAD_STATUS.inProgress,
  };
};

export default useMediaUrl;

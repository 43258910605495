import { call, put, select } from "redux-saga/effects";
import { remoteApiFactory } from "services";
import { ApiConstant, KeyConstant, SystemConstant } from "const";
import { AttachmentUtil, toCamel, toSnake, uuid } from "utils";
import { getInteractor } from "services/local.service";
import store, { ConversationActions, ConversationSelectors, GroupInfoActions } from "redux-store";
import { StorageUtil } from "utils";
import {
  checkBlockedContact,
  createGroupService,
  deleteLocalConversationData,
  getConversation,
} from "pubsub/services/conversation.service";
import { tusUpload } from "services/multiple-file/tus-upload";
import { getSavedServer } from "utils/branch.utils";

export const changeGroupPhoto = action => {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const attachmentId = uuid();

    const onError = error => {
      console.log("change group photo fail: ", error);
    };

    const onSuccess = () => {
      remoteApiFactory.getBranchApi(prefixKey).updateConversation(
        toSnake({
          groupId: data.groupId,
          avatarId: attachmentId,
        }),
      );

      getInteractor(prefixKey).LocalGroupService.update({ avatar_id: attachmentId }, { id: data.groupId });

      store.dispatch(
        GroupInfoActions.groupInfoSuccess({
          updatingGroupData: {
            id: data.groupId,
            avatarId: attachmentId,
          },
        }),
      );
    };

    // Save file to local
    const fileName = `${attachmentId}.png`;
    AttachmentUtil.copyToUpload(data.upload.path, attachmentId, fileName, data.groupId, false);

    tusUpload(prefixKey, attachmentId, data.upload.path, SystemConstant.UPLOAD_TYPE.account, null, {
      onError,
      onSuccess,
    });
  } catch (error) {
    console.log(error);
  }
};

export function* removeMemberGroup(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    let response = yield call(
      remoteApiFactory.getBranchApi(prefixKey).deleteConversation,
      toSnake({
        groupId: data.groupId,
        memberId: data.memberId,
      }),
    );
    if (response.status === ApiConstant.STT_OK) {
      yield getInteractor(prefixKey).LocalAccountGroupService.removeMemberGroup(data.groupId, data.memberId);
      const { groupMembers } = yield getInteractor(prefixKey).LocalGroupService.get(data.groupId);
      yield put(
        GroupInfoActions.groupInfoSuccess({
          updatingGroupData: {
            id: data.groupId,
            groupMembers: groupMembers,
            modified: new Date().getTime(),
          },
        }),
      );

      // Send message to remove member
      yield put(
        ConversationActions.sendMessage({
          groupId: data.groupId,
          sendType: SystemConstant.SEND_TYPE.leaveGroup,
          content: data.memberId,
          parentId: null,
          deviceList: [],
          branchId: getSavedServer().id,
          mentionIdsArr: [],
          threadId: null,
          removingId: data.memberId,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* addMemberGroup(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).addConversationMembers, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      if (Array.isArray(response.data)) {
        const saveData = response.data.map(item => ({
          id: item.id,
          account_id: item.account_id,
          group_id: item.group_id,
          state: item.state,
          options: item.details ? item.details : null,
          created: item.created,
          modified: item.modified,
          invite_by: item.invite_by,
          type: item.type,
        }));

        yield getInteractor(prefixKey).LocalAccountGroupService.save(saveData);
      }

      const { groupName, groupMembers } = toCamel(yield getInteractor(prefixKey).LocalGroupService.get(data.groupId));
      yield put(
        GroupInfoActions.groupInfoSuccess({
          updatingGroupData: {
            id: data.groupId,
            name: groupName,
            groupName: groupName,
            groupMembers: groupMembers,
            modified: new Date().getTime(),
          },
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* muteGroupNotification(action) {
  const { data } = action;
  const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

  try {
    const selectGroup = yield getInteractor(prefixKey).LocalGroupSettingService.getByGroupId(data.groupId);
    let newSetting;

    if (selectGroup && selectGroup.id) {
      newSetting = {
        ...selectGroup,
        state: data.status,
      };
    } else {
      newSetting = {
        id: uuid(),
        groupId: data.groupId,
        settingId: SystemConstant.SETTING_TYPE.notification,
        status: SystemConstant.STATUS.active,
        state: data.status,
        options: "",
        created: Date.now(),
        modified: 0,
      };
    }

    yield getInteractor(prefixKey).LocalGroupSettingService.save([toSnake(newSetting)]);
    yield put(
      GroupInfoActions.groupInfoSuccess({
        updatingGroupData: {
          id: data.groupId,
          status: newSetting.status,
        },
      }),
    );
  } catch (error) {
    console.log(error);
  }
}

export function* addAdmin(action) {
  const { data } = action;
  const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

  try {
    let response = yield call(remoteApiFactory.getBranchApi(prefixKey).updateConversation, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield getInteractor(prefixKey).LocalAccountGroupService.memberToAdmin(
        response.data.group_id,
        response.data.account_id,
      );
      yield put(
        GroupInfoActions.groupInfoSuccess({
          dataMemberToAdmin: toCamel(response).data,
        }),
      );
    }
  } catch (error) {
    console.log({ error });
  }
}

export const requestUploadImageCall = action => {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const attachmentId = uuid();

    const onError = error => {
      console.log("requestUploadImageCall: ", error);
    };
    const onSuccess = () => {
      if (data.initCall) {
        store.dispatch(
          ConversationActions.conversationSet({
            idGroupCallAvatar: attachmentId,
          }),
        );
      } else {
        store.dispatch(
          ConversationActions.conversationSet({
            idAvatarEdit: attachmentId,
          }),
        );
      }
    };

    tusUpload(prefixKey, attachmentId, data.upload.path, SystemConstant.UPLOAD_TYPE.account, null, {
      onError,
      onSuccess,
    });
  } catch (error) {
    console.log(error);
  }
};

export function* createGroup(action) {
  try {
    const prefixKey = action.prefixKey || StorageUtil.getCurrentPrefixKey();
    const newGroup = yield createGroupService(prefixKey, action.data);
    if (newGroup) {
      yield put(
        ConversationActions.conversationSet({
          demandingVoiceCall: Boolean(action.data.isDemandVoiceCall) ? newGroup.id : null,
          demandingVideoCall: Boolean(action.data.isDemandVideoCall) ? newGroup.id : null,
        }),
      );
      yield put(
        ConversationActions.setSelectGroupId({
          selectedGroupId: newGroup.id,
        }),
      );

      yield put(
        GroupInfoActions.groupInfoSet({
          isCreateGroupSuccess: true,
          updatingGroupData: yield getInteractor(prefixKey).LocalGroupService.getGroupInfo(newGroup),
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteGroup(action) {
  try {
    const { data } = action;
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).deleteConversation, toSnake(data));
    if (![ApiConstant.STT_OK, ApiConstant.STT_NOT_FOUND].includes(response.status)) {
      console.log("DELETE_GROUP_ERROR: ", response);
    }
    yield deleteLocalConversationData(prefixKey, data.groupId);
    yield put(
      GroupInfoActions.groupInfoSuccess({
        deleteGroup: { groupId: data.groupId, modified: Date.now() },
      }),
    );

    const selectedGroupId = yield select(ConversationSelectors.getSelectedGroupId);
    if (selectedGroupId === data.groupId) {
      yield put(
        ConversationActions.setSelectGroupId({
          threadingId: null,
          selectedGroupId: null,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updateGroup(action) {
  try {
    const { groupName, ...payload } = action.data || {};
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const response = yield call(remoteApiFactory.getBranchApi(prefixKey).updateConversation, toSnake(payload));
    if (response.status === ApiConstant.STT_OK) {
      yield getInteractor(prefixKey).LocalGroupService.update(
        { name: payload.name, group_name: groupName },
        { id: payload.groupId },
      );

      yield put(
        GroupInfoActions.groupInfoSuccess({
          updatingGroupData: {
            id: payload.groupId,
            name: payload.name,
            groupName: groupName,
            modified: Date.now(),
          },
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export const handleSelectConversation = async action => {
  try {
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
    const { selectedGroupId } = action.data;
    if (!selectedGroupId) return; // Skip if selectedGroupId does not exist

    // Synch group info
    await getConversation(prefixKey, selectedGroupId);

    const selectedGroup = toCamel(await getInteractor(prefixKey).LocalGroupService.get(selectedGroupId));

    const isChatWithPersonal =
      Array.isArray(selectedGroup.groupMembers) &&
      selectedGroup.groupMembers.length > 0 &&
      selectedGroup.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;

    // Checking chatting person is block or not
    if (isChatWithPersonal) {
      const loginAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID); // loginAccountId: sender
      const otherPerson = selectedGroup.groupMembers.find(memberItem => memberItem.id !== loginAccountId);
      await checkBlockedContact(prefixKey, selectedGroupId, [otherPerson.id, loginAccountId]);
    }
  } catch (error) {
    console.log("handleSelectConversation", error);
  }
};

import { Call, Videocam } from "@mui/icons-material";
import { Box, Button, Checkbox, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppConstant, FormatConstant, KeyConstant, SystemConstant } from "const";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { CallingActions } from "redux-store";
import { StorageUtil, toCamel, uuid } from "utils";
import { convertMillisecondsToDate } from "utils/date.utils";
import { isGroupOrChannelType } from "utils/view.utils";
import { CallIcon } from "../ChatGroupItem";
import InitGroupCallPopup from "pages/HomePage/components/MessengerChat/InitGroupCallPopup";
import { AvatarGroup } from "components";

const CallHistoryItem = ({ callHistory = {}, onSelect, checked }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isVoiceCall = callHistory.type === SystemConstant.CALL_HISTORY_TYPE.audioCall;
  const isMissedCall = callHistory.status === SystemConstant.CALL_HISTORY_STATUS.missed;
  const callType = getCallType(callHistory);

  const [isOpenInitGroupCall, setIsOpenInitGroupCall] = useState(false);

  const handleCall = () => {
    if (isGroupOrChannelType(callHistory.groupDetail.groupType)) {
      setIsOpenInitGroupCall(true);
    } else {
      dispatch(
        CallingActions.callingSet({
          isOpenCallingDialog: AppConstant.CALLING_STATUS.checking,
          callingGroupDetail: toCamel({ ...callHistory.groupDetail, room_id: uuid() }),
          isVideoCall: callHistory.type === SystemConstant.CALL_HISTORY_TYPE.videoCall,
        }),
      );

      const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
      const callingMember = callHistory.groupDetail.groupMembers.find(item => item.id !== accountId);

      dispatch(CallingActions.onCallCheck({ accountId: callingMember.id }));
    }
  };

  return (
    <Stack direction="row" alignItems="center" p="8px 16px">
      <Checkbox edge="start" onChange={onSelect} checked={checked} />

      <Stack direction="row" alignItems="center" width="100%">
        <AvatarGroup
          group={{
            groupType: callHistory.groupDetail.groupType,
            groupName: callHistory.groupDetail.groupName,
            privateF: callHistory.groupDetail.privateF,
          }}
          avatarId={callHistory.groupDetail.avatarId}
        />
        <Box ml={1}>
          <Typography className="ellipsis">{callHistory.groupDetail.groupName}</Typography>
          <CallIcon
            messageType={callType}
            callContent={convertMillisecondsToDate(callHistory.timeStart, FormatConstant.FM_HH_MM_DD_MM_YYYY)}
            className={isMissedCall && classes.missedCallIcon}
          />
        </Box>
      </Stack>
      <Button
        variant="contained"
        className={classes.callButton}
        classes={{ root: classes.rootButton, disabled: classes.disabledButton }}
        onClick={handleCall}
      >
        {isVoiceCall ? <Call color="white" /> : <Videocam color="white" />}
      </Button>

      {isOpenInitGroupCall && (
        <InitGroupCallPopup data={callHistory.groupDetail} open onClose={() => setIsOpenInitGroupCall(false)} />
      )}
    </Stack>
  );
};

export default CallHistoryItem;

const VIDEO_CALL_TYPE = [SystemConstant.CALL_HISTORY_TYPE.videoCall, SystemConstant.CALL_HISTORY_TYPE.conference];
const getCallType = callHistory => {
  let callType;
  switch (true) {
    case VIDEO_CALL_TYPE.includes(callHistory.type):
      callType = AppConstant.LAST_MESSAGE_TYPE.missingVideo;
      break;

    case callHistory.status === SystemConstant.CALL_HISTORY_STATUS.missed &&
      callHistory.type === SystemConstant.CALL_HISTORY_TYPE.audioCall:
      callType = AppConstant.LAST_MESSAGE_TYPE.missingAudio;
      break;

    default:
      callType = AppConstant.LAST_MESSAGE_TYPE.endedCall;
      break;
  }

  return callType;
};

const useStyles = makeStyles(theme => ({
  disabledButton: {
    backgroundColor: "#72B9E6 !important",
    color: theme.palette.white + "!important",
  },

  rootButton: {
    padding: 0,
    minWidth: 42,
    minHeight: 30,
  },

  missedCallIcon: {
    color: "#EB544B",
  },
}));

export const compressImageFile = (file, options = {}) => {
  const Compressor = require("compressorjs");
  return new Promise((resolve, reject) => {
    const compressOption = {
      quality: 0.8, // Quality
      maxWidth: 1920, // Full HD
      maxHeight: 1080,
      ...options,
    };

    new Compressor(file, {
      ...compressOption,
      success(result) {
        resolve(result);
      },
      error(err) {
        console.log(err.message, file);
        reject(null);
      },
    });
  });
};

export const getUrlTemporary = async (imageBlob, options) => {
  const compressedFile = await compressImageFile(imageBlob, options);

  if (compressedFile) {
    const urlTemporary = URL.createObjectURL(compressedFile);
    return urlTemporary;
  }

  return null;
};

export const getVideoThumbnailBlob = (fileUrl, seekTo = 0.01) => {
  return new Promise((resolve, reject) => {
    // Load the file to a video player
    const videoPlayer = document.createElement("video");
    videoPlayer.setAttribute("src", fileUrl);
    videoPlayer.load();
    videoPlayer.addEventListener("error", ex => {
      reject("error when loading video file", ex);
    });

    // Load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject("video is too short.");
        return;
      }

      // Delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 100);

      // Extract video thumbnail once seeking is complete
      videoPlayer.addEventListener("seeked", () => {
        // Define a canvas to have the same dimension as the video
        const canvas = document.createElement("canvas");
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // Draw the video frame to canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // Return the canvas image as a blob
        ctx.canvas.toBlob(blob => {
          resolve(blob);
          videoPlayer.remove();
        }, "image/jpeg");
      });
    });
  });
};

export const getImageBlob = fileUrl => {
  return new Promise((resolve, reject) => {
    // Load the file to a video player
    var image = new Image();
    image.onerror = function (error) {
      reject(error);
    };
    image.onload = function () {
      // Define a canvas to have the same dimension as the video
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      // Draw the video frame to canvas
      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      // Return the canvas image as a blob
      ctx.canvas.toBlob(blob => {
        resolve(blob);
        image.remove();
      }, "image/jpeg");
    };
    image.src = fileUrl;
  });
};

export const convertFileSizeUnit = (size = 0) => {
  let result = "";
  const unitKB = 1024;
  const unitMB = Math.pow(unitKB, 2);
  const unitGB = Math.pow(unitKB, 3);

  if (size >= unitGB) {
    let gb = (size / unitGB).toFixed(2);
    result = `${gb} GB`;
  } else if (size >= unitMB) {
    let mb = (size / unitMB).toFixed(2);
    result = `${mb} MB`;
  } else if (size >= unitKB) {
    let kb = (size / unitKB).toFixed(2);
    result = `${kb} KB`;
  } else if (size > 0) {
    result = `${size} byte`;
  } else {
    result = "0 byte";
  }

  return result;
};

export const getFileColor = fileName => {
  let result = "#799098";
  if (false === Boolean(fileName)) return result;
  switch (true) {
    case fileName.endsWith(".doc"):
    case fileName.endsWith(".docs"):
    case fileName.endsWith(".docx"):
      result = "#2A65C0";
      break;

    case fileName.endsWith(".xlsx"):
    case fileName.endsWith(".xls"):
      result = "#229C5B";
      break;

    case fileName.endsWith(".pptx"):
    case fileName.endsWith(".pptm"):
    case fileName.endsWith(".ppt"):
      result = "#E85332";
      break;

    case fileName.endsWith(".pdf"):
      result = "#F40F02";
      break;

    default:
      result = "#799098";
      break;
  }

  return result;
};

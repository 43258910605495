import PropTypes from "prop-types";
import { Avatar, Box, Button, Stack, styled, Typography } from "@mui/material";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import PersonalCallHistory from "./PersonalCallHistory";
import ChatItem from "./ChatItem";
import LineChat from "./LineChat";
import { convertLineChatTime } from "utils/date.utils";
import { getFirstLetter, StorageUtil } from "utils";
import { getCreatedTimeText, useMessageStyle } from "./ChatItem/ChatItem.helper";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const MessageItem = ({ message, isThreadMode }) => {
  return (
    <MessageContainer>
      {message.isShowTime && <LineChat data={convertLineChatTime(message.created)} />}

      {message.isDeleted ? (
        <DeleteMessage message={message} />
      ) : SystemConstant.ARR_CALLING_TYPES.includes(message.sendType) ? (
        Boolean(message.senderId) && <PersonalCallHistory data={message} />
      ) : (
        <ChatItem data={message} isThreadMode={isThreadMode} />
      )}
    </MessageContainer>
  );
};

MessageItem.prototype = {
  message: PropTypes.object,
  isThreadMode: PropTypes.bool,
};
export default MessageItem;

const DeleteMessage = ({ message }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const messengerClasses = useMessageStyle();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const isMine = message.senderId === accountId;
  const isAvatar = Boolean(message.isAvatar);
  const msgCreatedTime = getCreatedTimeText(message);

  const isDeletedByMe = isMine || message.state === SystemConstant.STATE.inactive;
  return (
    <DeleteMessageContainer justifyContent={isMine ? "flex-end" : "flex-start"}>
      <Stack direction="row" spacing={2}>
        {!isMine && (
          <Avatar
            className={clsx(!isAvatar && messengerClasses.hiddenAvatar)}
            alt="chatting-avatar"
            src={message.avatarUrl}
          >
            {getFirstLetter(message?.senderName)}
          </Avatar>
        )}

        <Box>
          {msgCreatedTime && (
            <Typography className={clsx("ellipsis", messengerClasses.createdTimeText)}>{msgCreatedTime}</Typography>
          )}

          <Button variant="outlined" disabled>
            {getLabel(isDeletedByMe ? "FM_DELETED_MSG_BY_ME" : "FM_DELETED_MSG_BY_OTHER", { name: message.senderName })}
          </Button>
        </Box>
      </Stack>
    </DeleteMessageContainer>
  );
};

const MessageContainer = styled(Box)({
  position: "relative",
  width: "100%",
});

const DeleteMessageContainer = styled(Box)({
  position: "relative",
  display: "flex",
  flexDirection: "row",
  paddingTop: 8,
  paddingBottom: 0,
  borderRadius: 20,

  "& button": {
    textTransform: "none",
    borderRadius: 20,
    padding: "6px 12px",
  },
});

import { Box, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { isImage, StorageUtil } from "utils";
import useUploadFile from "hooks/useUploadFile";
import { AppConstant, SystemConstant } from "const";
import { useEffect, useRef, useState } from "react";
import { getFileLocalPath } from "services/attachment.service";
import { useConversationContext } from "../../ConversationContext";
import { ImageMedia, VideoMedia } from "components";
import { getCommonLang } from "utils/lang.utils";
import { useAlertContext } from "components/context/AlertContext";
import { getInteractor } from "services/local.service";
import { LoadingMedia } from "components/AppMedia";

const MediaBox = ({ fileInfo, onClick, customStyles, groupId, isCurrentDevice, isThumbnail }) => {
  const classes = useStyles();
  const statusRef = useRef();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { clickMedia } = useConversationContext();
  const { showAlert } = useAlertContext();
  const { uploadStatus } = useUploadFile(prefixKey, fileInfo.attachmentId, SystemConstant.UPLOAD_TYPE.group, groupId);

  const [uploadMediaStatus, setUploadMediaStatus] = useState(SystemConstant.UPLOAD_STATUS.inProgress);

  const handleClickMedia = () => {
    if (isFailed) {
      showAlert({ content: getCommonLang("ERR_IMAGE_FAILED_LOADING"), alertProps: { severity: "error" } });
    } else if (uploadMediaStatus === SystemConstant.UPLOAD_STATUS.success) {
      if (onClick) onClick();
      clickMedia(fileInfo);
    }
  };

  useEffect(() => {
    statusRef.current = uploadStatus;
    setUploadMediaStatus(uploadStatus);
  }, [uploadStatus]);

  useEffect(() => {
    const uploadStatusTimeout = setTimeout(() => {
      if (statusRef.current === SystemConstant.UPLOAD_STATUS.inProgress) {
        setUploadMediaStatus(SystemConstant.UPLOAD_STATUS.failed);
        getInteractor(prefixKey).LocalFileService.updateFileFail(fileInfo.attachmentId);
      }
    }, AppConstant.CHECK_UPLOAD_TIMEOUT);

    return () => clearTimeout(uploadStatusTimeout);
  }, []);

  const isImageMedia = isImage(fileInfo.metaData.mimeType);
  const isFailed = uploadMediaStatus.status === SystemConstant.UPLOAD_STATUS.failed;
  const isUploadFile = isCurrentDevice && uploadStatus === SystemConstant.UPLOAD_STATUS.inProgress;

  return (
    <Stack className="flex-center" width="100%" height="100%">
      <Box className={clsx(classes.previewMedia, customStyles)}>
        {isUploadFile ? (
          <UploadMediaFile fileInfo={fileInfo} groupId={groupId} isCurrentDevice={isCurrentDevice} />
        ) : isImageMedia ? (
          <ImageMedia
            file={{ groupId, ...fileInfo }}
            className={customStyles}
            isThumbnail={isThumbnail}
            isCurrentDevice={isCurrentDevice}
            onClick={handleClickMedia}
          />
        ) : (
          <VideoMedia
            file={{ groupId, ...fileInfo }}
            isThumbnail={isThumbnail}
            isCurrentDevice={isCurrentDevice}
            onClick={event => {
              event.preventDefault();
              handleClickMedia();
            }}
          />
        )}
      </Box>
    </Stack>
  );
};

export default MediaBox;

const UploadMediaFile = props => {
  const { fileInfo, groupId, isCurrentDevice } = props;
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const [mediaSrc, setMediaSrc] = useState("");

  useEffect(() => {
    let isMounted = true;
    const getMediaDataTimeout = setTimeout(() => {
      getFileLocalPath(prefixKey, fileInfo, { groupId, isCurrentDevice }).then(mediaPath => {
        if (isMounted) setMediaSrc(mediaPath);
      });
    }, AppConstant.DEBOUNCE_TIME);

    return () => {
      isMounted = false;
      clearTimeout(getMediaDataTimeout);
    };
  }, [props]);

  return <LoadingMedia backgroundSrc={mediaSrc} />;
};

const useStyles = makeStyles({
  previewMedia: {
    width: "100%",
    height: "100%",
    maxWidth: 500,
    borderRadius: 20,
    overflow: "hidden",

    "& img": {
      maxHeight: 400,
      maxWidth: "100%",
    },

    "& video": {
      maxHeight: 500,
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
});

/**
 * Saga index: connects action type and saga
 */

import { all, takeLatest, fork, takeEvery, takeLeading } from "redux-saga/effects";

/* ------------- Types ------------- */
import {
  AuthTypes,
  ConversationTypes,
  ContactTypes,
  CallingTypes,
  DeviceTypes,
  GroupInfoTypes,
  BranchTypes,
  RestoreTypes,
  ProfileTypes,
  AccountTypes,
  SystemTypes,
} from "redux-store";

/* ------------- Sagas ------------- */
import { requestLogin, requestVerify } from "./auth.saga";
import { logoutDevice } from "./account-device.saga";
import { getAccountList, blockUser, unblockUser } from "./account.saga";
import { getBranches } from "./branch.saga";
import {
  uploadMessageFile,
  updateMessageStatus,
  markReadMessageInGroup,
  getMessageDetail,
} from "./conversation-message.saga";
import {
  changeGroupPhoto,
  removeMemberGroup,
  muteGroupNotification,
  addAdmin,
  createGroup,
  updateGroup,
  deleteGroup,
  requestUploadImageCall,
  addMemberGroup,
  handleSelectConversation,
} from "./conversation-info.saga";
import { findContact, updateContact, addNewContact, unfriendContact } from "./contact.saga";
import {
  checkReceiversStatus,
  checkCallingStatus,
  getCallHistory,
  updateCallHistory,
  getImageIncomingCall,
} from "./calling.saga";
import { changeProfileImage, updateProfileInfo, updateAccountStatus } from "./profile.saga";
import { synchronizeData } from "./synchronize.saga";
import { backup, changeDeviceRole, restore, verifyChangeDeviceRole, restoreToLocal } from "./restore.saga";
import { saveMsgInQueue } from "./message-queue.saga";
import { executeQueue } from "./handle-queue.saga";
import pubSubFactory from "pubsub/PubSubFactory";
import { checkBlockedContact } from "pubsub/services/conversation.service";

/* ------------- Connect Types To Sagas ------------- */
export default function* root() {
  yield pubSubFactory.initFromDB();
  yield fork(executeQueue);

  yield all([
    // Authentication
    takeLatest(AuthTypes.REQUEST_LOGIN, requestLogin),
    takeLatest(AuthTypes.REQUEST_VERIFY, requestVerify),

    // Account
    takeLatest(AccountTypes.GET_ACCOUNT_LIST, getAccountList),
    takeLatest(AccountTypes.BLOCK_USER, blockUser),
    takeLatest(AccountTypes.UNBLOCK_USER, unblockUser),

    // Branch
    takeLatest(BranchTypes.GET_BRANCH, getBranches),

    // Conversation Message
    takeLatest(ConversationTypes.UPLOAD_MESSAGE_FILE, uploadMessageFile),
    takeLatest(ConversationTypes.UPDATE_MESSAGE_STATUS, updateMessageStatus),
    takeEvery(ConversationTypes.SEND_MESSAGE, saveMsgInQueue),
    takeLatest(ConversationTypes.MARK_READ_MESSAGE_IN_GROUP, markReadMessageInGroup),
    takeLatest(ConversationTypes.GET_MESSAGE_DETAIL, getMessageDetail),

    // Conversation Info: Group, Channel, Personal
    takeLatest(GroupInfoTypes.CHANGE_GROUP_PHOTO, changeGroupPhoto),
    takeLatest(GroupInfoTypes.REMOVE_MEMBER_GROUP, removeMemberGroup),
    takeLatest(GroupInfoTypes.ADD_MEMBER_GROUP, addMemberGroup),
    takeLatest(GroupInfoTypes.MUTE_GROUP_NOTIFICATION, muteGroupNotification),
    takeLatest(GroupInfoTypes.ADD_ADMIN, addAdmin),
    takeLatest(GroupInfoTypes.CREATE_GROUP, createGroup),
    takeLatest(GroupInfoTypes.UPDATE_GROUP, updateGroup),
    takeLatest(GroupInfoTypes.DELETE_GROUP, deleteGroup),
    takeLatest(ConversationTypes.REQUEST_UPLOAD_IMAGE_CALL, requestUploadImageCall),
    takeLatest(ConversationTypes.SET_SELECT_GROUP_ID, handleSelectConversation),
    takeLatest(ConversationTypes.CHECK_BLOCKED_CONTACT, checkBlockedContact),

    // Contact
    takeLatest(ContactTypes.FIND_CONTACT, findContact),
    takeLatest(ContactTypes.UPDATE_CONTACT, updateContact),
    takeLatest(ContactTypes.ADD_NEW_CONTACT, addNewContact),
    takeLatest(ContactTypes.UNFRIEND_CONTACT, unfriendContact),

    // Calling
    takeLatest(CallingTypes.ON_CALL_CHECK, checkCallingStatus),
    takeLatest(CallingTypes.ON_CHECK_RECEIVERS_STATUS, checkReceiversStatus),
    takeLatest(CallingTypes.GET_CALL_HISTORY, getCallHistory),
    takeLatest(CallingTypes.UPDATE_CALL_HISTORY, updateCallHistory),
    takeLatest(CallingTypes.GET_IMAGE_INCOMING_CALL, getImageIncomingCall),

    // Device Management
    takeLatest(DeviceTypes.DEVICE_LOGOUT, logoutDevice),

    // Profile
    takeLatest(ProfileTypes.CHANGE_PROFILE_IMAGE, changeProfileImage),
    takeLatest(ProfileTypes.UPDATE_PROFILE_STATUS, updateAccountStatus),
    takeLatest(ProfileTypes.UPDATE_PROFILE_INFO, updateProfileInfo),

    // Synchronize
    takeLatest(SystemTypes.SYNCHRONIZE_DATA, synchronizeData),
    takeLeading(SystemTypes.EXECUTE_QUEUE, executeQueue),

    // Synchronize message

    // Backup/ Restore Data
    takeLatest(RestoreTypes.CHANGE_DEVICE_ROLE, changeDeviceRole),
    takeLatest(RestoreTypes.VERIFY_CHANGE_DEVICE_ROLE, verifyChangeDeviceRole),
    takeLatest(RestoreTypes.RESTORE, restore),
    takeLatest(RestoreTypes.RESTORE_TO_LOCAL, restoreToLocal),
    takeLatest(RestoreTypes.BACKUP, backup),
  ]);
}

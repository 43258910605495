import { makeStyles } from "@mui/styles";
import { convertString2JSON } from "utils/index";
import { getInteractor } from "services/local.service";
import { SystemConstant } from "const";

export const getCreatedTimeText = message => {
  let msgCreatedTime;

  if (message && message.isAvatar) {
    if (message.senderName && message.sentTime) {
      msgCreatedTime = `${message.senderName}, ${message.sentTime}`;
    } else if (message.senderName) {
      msgCreatedTime = message.senderName;
    } else if (message.sentTime) {
      msgCreatedTime = message.sentTime;
    }
  }
  return msgCreatedTime;
};

export const getMentionMembers = async (prefixKey, mentions) => {
  const mentionMemberIds = convertString2JSON(mentions, []);
  const mentionList = await getInteractor(prefixKey).LocalAccountService.getAccountByIds(mentionMemberIds);
  if (mentionMemberIds.includes(`@{${SystemConstant.MENTION_ALL.id}}`)) {
    mentionList.push(SystemConstant.MENTION_ALL);
  }

  return mentionList;
};

export const getLatestMessage = async (prefixKey, chattingMessage) => {
  const lastChildMessage = await getInteractor(prefixKey).LocalMessageService.getChildMessages(
    chattingMessage.sourceId,
    [chattingMessage.sendType, SystemConstant.SEND_TYPE.groupMessage, SystemConstant.SEND_TYPE.message],
  )[0];

  const hasEditedMessage = lastChildMessage && lastChildMessage.id;
  if (hasEditedMessage) {
    chattingMessage = {
      ...chattingMessage,
      mentions: lastChildMessage.mentions,
      content: lastChildMessage.content,
    };
  }

  chattingMessage = {
    ...chattingMessage,
    isEdited: hasEditedMessage,
  };
  return chattingMessage;
};

export const useMessageStyle = makeStyles(theme => ({
  messengerRoot: {
    width: "fit-content",
    borderRadius: 20,
    backgroundColor: "#F1F3F6",
    overflow: "hidden",
    color: theme.palette.black,
  },

  myMessage: {
    backgroundColor: "#008FE8",
    color: "white",
  },

  spacing: {
    padding: "12px 16px",
  },

  hiddenAvatar: {
    "& *": { display: "none" },
    background: "white",
  },

  createdTimeText: {
    fontSize: 12,
    fontWeight: 550,
    marginBottom: 6,
  },
}));

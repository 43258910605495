import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { StorageUtil, toCamel } from "utils";
import { AppDrawer, PrimaryTab } from "components";
import Media from "./Media";
import Link from "./Link";
import File from "./File";
import { getInteractor } from "services/local.service";
import { Box } from "@mui/material";

const Gallery = ({ groupId, onClose, open }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const [selectedTab, setSelectedTab] = useState(GALLERY_TYPE.media);
  const [dataLink, setDataLink] = useState([]);
  const [dataFile, setDataFile] = useState([]);
  const [dataImage, setDataImage] = useState([]);
  const [galleryTabArray] = useState([
    {
      label: getLabel(LangConstant.TXT_MEDIA),
    },
    {
      label: getLabel(LangConstant.TXT_LINK),
    },
    {
      label: getLabel(LangConstant.TXT_FILES),
    },
  ]);

  const onChangeTab = newValue => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (groupId) {
      const gallerySendType = Object.values(SystemConstant.MEDIA_SEND_TYPE).concat([SystemConstant.SEND_TYPE.link]);

      getInteractor(prefixKey)
        .LocalMessageService.getMediaMessages({
          group_id: groupId,
          branch_id: branchId,
          send_type: gallerySendType,
        })
        .then(recordList => {
          const mediaMessages = toCamel(recordList);

          const messageMedia = mediaMessages.filter(item => MEDIA_TYPE.includes(item?.sendType));

          const messageLink = mediaMessages.filter(item => item?.sendType === SystemConstant.SEND_TYPE.link);

          const messageFile = mediaMessages.filter(item => item?.sendType === SystemConstant.SEND_TYPE.file);

          setDataImage(messageMedia);
          setDataLink(messageLink);
          setDataFile(messageFile);
        });
    }
  }, [groupId]);

  return (
    <AppDrawer
      open={open}
      onClose={onClose}
      anchor="right"
      title={getLabel(LangConstant.TXT_GALLERY)}
      hideBackdrop={false}
      classes={{ root: classes.drawerRoot }}
    >
      <PrimaryTab
        tabArray={galleryTabArray}
        classes={{
          flexContainer: classes.flexContainer,
          tabButton: classes.tabButton,
          tabLabel: classes.tabLabel,
          tabLabelSelected: classes.tabLabelSelected,
          tabButtonSelected: classes.tabButtonSelected,
          indicator: "hidden",
        }}
        onChange={onChangeTab}
        selectedTabIndex={selectedTab}
      />

      <Box className={selectedTab !== GALLERY_TYPE.media && "hidden"}>
        <Media data={dataImage} />
      </Box>

      <Box className={selectedTab !== GALLERY_TYPE.link && "hidden"}>
        <Link data={dataLink} />
      </Box>

      <Box className={selectedTab !== GALLERY_TYPE.file && "hidden"}>
        <File data={dataFile} groupId={groupId} />
      </Box>
    </AppDrawer>
  );
};

Gallery.propTypes = { onClose: PropTypes.func };

Gallery.defaultProps = { onClose: () => {} };

export default Gallery;

const MEDIA_TYPE = [SystemConstant.SEND_TYPE.image, SystemConstant.SEND_TYPE.video];

const GALLERY_TYPE = { media: 0, link: 1, file: 2, dataImage: 3, dataAudio: 5, dataVideo: 6, dataLinks: 9 };

const useStyles = makeStyles(theme => ({
  flexContainer: {
    padding: "12px 14px",
  },

  tabButton: {
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    height: 30,
    minHeight: "unset",
    borderRadius: 15.5,
  },

  tabLabel: {
    marginTop: 0,
    color: theme.palette.grey[500],
  },

  tabLabelSelected: {
    color: theme.palette.black,
  },

  tabButtonSelected: {
    backgroundColor: theme.palette.grey[100],
  },

  drawerRoot: {
    "&>.MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
  },
}));

import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Box, CardMedia, Grid, ImageList, ImageListItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { StorageUtil } from "utils";
import { ImageConstant, KeyConstant, LangConstant } from "const";
import NoData from "./NoData";
import MediaBox from "../MessengerChat/ChatItem/MediaPreview/MediaBox";
import { getCreateTimeObject } from "utils/date.utils";
import { getExistFileFromMessage } from "utils/message.utils";

const Media = ({ data, ...otherProps }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const [dateTimeData, setDateTimeData] = useState({});

  useEffect(() => {
    setDateTimeData(getExistFilesByTime(data));
  }, [data]);

  return (
    <Box className={classes.root} {...otherProps}>
      {dateTimeData && Object.keys(dateTimeData).length > 0 ? (
        Object.keys(dateTimeData).map(key => (
          <Grid key={key} className={classes.mediaContainer}>
            <Typography className={classes.titleMedia} variant="subtitle2">
              {key}
            </Typography>
            <ImageList cols={3} gap={0} rowHeight={124} className={classes.imageList}>
              {dateTimeData[key].map(item => (
                <ImageListItem key={item.mediaId} sx={{ width: "100%" }}>
                  <MediaBox
                    fileInfo={item}
                    customStyles={classes.imageItem}
                    groupId={item.groupId}
                    isCurrentDevice={item.isCurrentDevice}
                    isThumbnail={true}
                    width={150}
                    height={150}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
        ))
      ) : (
        <NoData
          media={<CardMedia component="img" alt="cover" className={classes.cover} src={ImageConstant.NoMediaImage} />}
          textOne={getLabel(LangConstant.TXT_CONTENT_NO_MEDIA)}
          textTwo={getLabel(LangConstant.TXT_NO_MEDIA)}
        />
      )}
    </Box>
  );
};

Media.propTypes = {
  name: PropTypes.array,
};

Media.defaultProps = {
  data: [],
};

export default memo(Media);

export const getExistFilesByTime = messageList => {
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);

  if (Array.isArray(messageList)) {
    let mediaList = [];
    messageList.forEach(message => {
      let messageFileList = getExistFileFromMessage(StorageUtil.getCurrentPrefixKey(), message);

      messageFileList = messageFileList.map(item => ({
        ...item,
        created: message.created,
        groupId: message.groupId,
        isCurrentDevice: message.senderDeviceId === deviceId,
      }));

      mediaList = mediaList.concat(messageFileList);
    });

    return getCreateTimeObject(mediaList);
  } else {
    return {};
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 14,
    overflowX: "hidden",
  },

  cover: {
    position: "relative",
    marginBottom: 12,
    height: "auto",
    maxWidth: "100%",
    width: "unset",
  },

  titleMedia: {
    marginBottom: 30,
    paddingLeft: 14,
  },

  mediaContainer: {
    marginBottom: 14,
  },

  imageList: {
    width: "100%",
    overflowX: "hidden",
  },

  imageItem: {
    border: "solid 1px",
    borderColor: theme.palette.grey[100],
    borderRadius: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

import { FileUtil, StorageUtil } from "utils";
import StringFormat from "string-format";
import { ApiConstant, AppConstant, KeyConstant, SystemConstant } from "const";
import tus from "tus-js-client";
import mime from "mime";
import { getUploadDomain } from "./tus-get";

export const tusUpload = async (prefixKey, attachmentId, filePath, uploadType = 1, groupId, callbackFuncs) => {
  try {
    const tusDomain = getUploadDomain(prefixKey);
    const endpointTus = StringFormat(ApiConstant.UPLOAD_TUS_ENDPOINT, tusDomain, attachmentId);
    const accessToken = StorageUtil.getItem(KeyConstant.KEY_TOKEN, prefixKey);
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

    const data = FileUtil.readBlobFile(filePath);

    const tusHeaders = {
      type: uploadType,
      authorization: `Bearer ${accessToken}`,
    };
    uploadType === SystemConstant.UPLOAD_TYPE.group
      ? (tusHeaders.group_id = groupId)
      : (tusHeaders.account_id = accountId);

    const fileType = mime.getType(filePath) || AppConstant.DEFAULT_MIME_TYPE;
    const extension = mime.getExtension(fileType);
    const uploadingEndpoint = localStorage.getItem(attachmentId); // Url is uploading

    const tusOptions = {
      endpoint: endpointTus,
      uploadUrl: uploadingEndpoint ? uploadingEndpoint : null,
      resume: true,
      overridePatchMethod: true, // => true: using POST method
      retryDelays: [0, 1000, 3000, 5000],
      headers: tusHeaders,
      metadata: {
        filename: `${attachmentId}.${extension}`,
        filetype: fileType,
      },
      urlStorage: tus.DefaultUrlStorage, // In browser using local storage
      removeFingerprintOnSuccess: true,

      fingerprint() {
        return `${attachmentId}`; // Save uploading url in localStorage with key = attachmentId
      },
      onError(error) {
        if (callbackFuncs.onError) callbackFuncs.onError(error);
        return false;
      },
      onProgress(bytesUploaded, bytesTotal) {
        if (callbackFuncs.onProgress) callbackFuncs.onProgress(bytesUploaded, bytesTotal);
      },
      onSuccess() {
        if (callbackFuncs.onSuccess) callbackFuncs.onSuccess();
        return true;
      },
    };

    const upload = new tus.Upload(data, tusOptions);
    upload.start();
  } catch (error) {
    console.log("tus upload error: ", error);
  }
};

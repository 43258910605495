import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AttachmentUtil } from "utils";
import { withCloseBadge } from "components/hoc";
import { AvatarGroup } from "components";
import { memo } from "react";

const ACSelectContact = ({ contact, onDelete }) => {
  const classes = useStyles();

  contact.avatarUrl = AttachmentUtil.getAvatarRemoteUrl(contact.avatarId);

  const SelectedContact = withCloseBadge(AvatarGroup);

  return (
    <Box className={classes.selectedItem} key={`${contact.phone}`}>
      <SelectedContact
        ComponentProps={{ avatarId: contact.avatarId }}
        IconProps={{ onClick: () => onDelete(contact), customIconStyle: classes.closeButton }}
      />
      <Typography className={clsx(classes.selectContactName, "ellipsis")}>
        {contact.contactName || contact.name || contact.phone}
      </Typography>
    </Box>
  );
};

ACSelectContact.propTypes = {
  contact: PropTypes.object,
  onDelete: PropTypes.func,
};

ACSelectContact.defaultProps = {
  contact: {},
  onDelete: () => {},
};

export default memo(ACSelectContact);

const useStyles = makeStyles(() => ({
  selectedItem: {
    width: 64,
    minWidth: 64,
    paddingTop: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  selectContactName: {
    fontSize: "12px",
  },

  closeButton: {
    fontSize: 14,
    right: 0,
  },
}));

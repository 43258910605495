import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { getLabel } from "language";
import store, { BranchActions, SystemActions } from "redux-store";
import { formatPagingParams } from "sagas/saga.helper";
import { remoteApiFactory } from "services";
import { LocalAppNotificationService, getInteractor } from "services/local.service";
import { StorageUtil, toCamel } from "utils";

export const synchNotificationList = async (prefixKey, time2FetchNotification) => {
  try {
    const localTime2FetchNotification = StorageUtil.getItem(KeyConstant.KEY_TIME_2_FETCH_NOTIFICATION, prefixKey) || 0;
    time2FetchNotification =
      time2FetchNotification - localTime2FetchNotification > 0 ? time2FetchNotification : localTime2FetchNotification;
    const fetchNotificationParams = formatPagingParams({
      sinceTime: time2FetchNotification,
      limit: 10000,
    });

    const response = await remoteApiFactory.getBranchApi(prefixKey).getNotificationList(fetchNotificationParams);
    if (response.status === ApiConstant.STT_OK) {
      const remoteNoticeList = response.data.data || [];

      for (let index = 0; index < remoteNoticeList.length; index++) {
        const notification = remoteNoticeList[index];
        const localNotification = await getInteractor(prefixKey).LocalNotificationService.get(notification.id);
        if (localNotification) continue;
        switch (notification.type) {
          case SystemConstant.NotifyType.NOTIFICATION:
            break;

          case SystemConstant.NotifyType.SMARTOTP:
            smartOtpService(prefixKey, notification);
            break;

          case SystemConstant.NotifyType.INVITE:
            const globalBranchId = SystemConstant.GLOBAL_BRANCH_ID;
            if (globalBranchId === notification.branch_id)
              store.dispatch(
                SystemActions.systemSet({
                  newNotification: toCamel(notification),
                }),
              );
            break;

          case SystemConstant.NotifyType.OWNER_NOTIFICATION:
            ownerNotification(prefixKey, notification);
            break;

          default:
            break;
        }
      }
      await getInteractor(prefixKey).LocalNotificationService.save(remoteNoticeList);
      StorageUtil.setItem(KeyConstant.KEY_TIME_2_FETCH_NOTIFICATION, time2FetchNotification, prefixKey);

      return true;
    }
  } catch (error) {
    console.log("synch notification fail: ", error);
  }

  return false;
};

export const branchInviteService = async (prefixKey, accountId, branchId) => {
  try {
    const localNotification = await getInteractor(prefixKey).LocalNotificationService.find({
      notice_to_account_id: accountId,
      branch_id: branchId,
      type: SystemConstant.NotifyType.INVITE,
    });

    if (localNotification) return true;
  } catch (error) {
    console.log("branch invite service fail: ", error);
  }

  return false;
};

export const branchRemoveService = inactiveBranchId => {
  store.dispatch(
    BranchActions.branchSet({
      inactiveBranchArray: inactiveBranchId,
    }),
  );

  return true;
};

const smartOtpService = (prefixKey, notification) => {
  try {
    LocalAppNotificationService.showNotification(getLabel(LangConstant.TXT_APP_NAME), {
      content: `Smart-OTP: ${notification.content}`,
      prefixKey,
    });

    return true;
  } catch (error) {
    console.log("smart otp notification fail: ", error);
  }

  return false;
};

export const mentionsNotification = async (prefixKey, messageSourceId) => {
  try {
    const message = await getInteractor(prefixKey).LocalMessageService.findOne({ source_id: messageSourceId });
    if (message && message.id) {
      const mentionNotification = await getInteractor(prefixKey).LocalNotificationService.findOne({
        content: message.id,
      });
      if (mentionNotification) return true;
    }
  } catch (error) {
    console.log("mention notification fail: ", error);
  }

  return false;
};

const ownerNotification = (prefixKey, notification) => {
  try {
    if (notification && notification.id) {
      LocalAppNotificationService.showNotification(getLabel(LangConstant.TXT_APP_NAME), {
        content: `Thông báo: ${notification.content}`,
        prefixKey,
      });

      return true;
    }
  } catch (error) {
    console.log("owner notification fail: ", error);
  }

  return false;
};

export const handlePersonalNotification = async (prefixKey, notificationId) => {
  try {
    const localNotification = await getInteractor(prefixKey).LocalNotificationService.get(notificationId);
    if (false === Boolean(localNotification && localNotification.id)) {
      const localTime2FetchNotification =
        StorageUtil.getItem(KeyConstant.KEY_TIME_2_FETCH_NOTIFICATION, prefixKey) || 0;
      synchNotificationList(prefixKey, localTime2FetchNotification);
    }

    return true;
  } catch (error) {
    console.log("other notification error: ", error);
  }

  return false;
};

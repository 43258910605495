import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppConstant, LangConstant, SystemConstant } from "const";
import { RestoreIcon } from "components/icons";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getNSLang } from "utils/lang.utils";
import { convertFileSizeUnit } from "utils/file.utils";

const RestoreProgress = ({ hasError, size, onFinish, onRetry, onCancel }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const restoreStatusRef = useRef();
  const progressRef = useRef(0);

  const restoreStatus = useSelector(state => state.restoreRedux.restoreStatus);
  restoreStatusRef.current = restoreStatus;

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (restoreStatusRef.current === SystemConstant.RESTORE_STATUS.success) {
      onFinish(true);
      setProgress(100);
    } else {
      const progressInterval = setInterval(() => {
        if (!Boolean(hasError) && restoreStatusRef.current === SystemConstant.RESTORE_STATUS.inProgress) {
          const isWaiting2Finish =
            progressRef.current > 80 && restoreStatusRef.current !== SystemConstant.RESTORE_STATUS.success;

          if (!isWaiting2Finish) {
            const diff = Math.random() * 10;
            const downloadingProgress = Math.min(progressRef.current + diff, 100);

            progressRef.current = parseFloat(downloadingProgress.toFixed(2));
            setProgress(progressRef.current);
          }
        }
      }, AppConstant.DEBOUNCE_TIME);

      return () => {
        if (progressInterval) {
          clearInterval(progressInterval);
        }
      };
    }
  }, [restoreStatus]);

  return (
    <Box className={classes.component}>
      <RestoreIcon className={hasError ? classes.errorLogo : classes.logo} />
      {hasError ? (
        <>
          <Typography className={classes.title}>{getLabel(LangConstant.TXT_NOT_CONNECT)}</Typography>
          <Typography className={classes.errorContent}>{getLabel(LangConstant.TXT_NOT_CONNECT_INFO)}</Typography>
          <Box className={classes.actionBtnBox}>
            <Button fullWidth variant="contained" className={classes.actionBtn} onClick={onRetry}>
              {getLabel(LangConstant.TXT_RETRY)}
            </Button>
            <Button fullWidth className={classes.actionBtn} onClick={onCancel}>
              {getLabel(LangConstant.TXT_CANCEL)}
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography className={classes.title}>{getLabel(LangConstant.TXT_KEEP_CONNECT_INTERNET)}</Typography>
          <Box className={classes.progressBox}>
            <LinearProgress variant="determinate" value={progress} />
            <Typography className={classes.downloadProgress}>
              {getNSLang(LangConstant.NS_HOME, LangConstant.FM_DOWNLOADED_CONTENT, {
                // change to size of dataRestore
                downloaded: convertFileSizeUnit(parseInt(progress * 0.01 * size)),
                total: convertFileSizeUnit(size),
                percentage: progress,
              })}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default RestoreProgress;

const useStyles = makeStyles({
  logo: {
    width: 170,
    height: 100,
    color: "#008EF8",
    marginBottom: 30,
  },

  component: {
    textAlign: "center",
  },

  errorLogo: {
    width: 100,
    height: 89,
    color: "#FF2C28",
  },

  title: {
    fontWeight: "bold",
    marginBottom: 10,
  },

  errorContent: {
    marginBottom: 20,
  },

  actionBtn: {
    marginBottom: 10,
    minHeight: 40,
  },

  downloadProgress: {
    marginTop: 10,
    fontSize: 12,
    color: "#7F7F80",
  },
});

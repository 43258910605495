import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { PrimaryTab, SearchBar, TabPanel, InfiniteScroll } from "components";
import DataListByLetter from "../../AddingContact/DataListByLetter";
import AddingContact from "../../AddingContact";
import { useAccountList, useContactList } from "hooks";
import { getFirstLetter, StorageUtil } from "utils";
import { getSearchResult } from "utils/view.utils";
import { useSelector } from "react-redux";
import { BranchSelectors } from "redux-store";
import { getInteractor } from "services/local.service";

const ContactListTab = ({ onClick }) => {
  const classes = useStyles();
  const searchRef = useRef();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const { contactList } = useContactList();
  const { accountList } = useAccountList();
  const isBranchServer = useSelector(BranchSelectors.isBranchServer);
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const [selectedTab, setSelectedTab] = useState(CHAT_TAB_INDEX.personal);
  const [orderContactByName, setOrderContactByName] = useState({}); // Group contact
  const [filterGroupList, setFilterGroupList] = useState([]);

  const contactTabArray = [
    {
      label: getLabel(isBranchServer ? LangConstant.TXT_MEMBERS : LangConstant.TXT_MY_CONTACT),
    },

    {
      label: getLabel(LangConstant.TXT_GROUP),
    },
  ];

  const onChangeSearchInput = content => {
    searchRef.current = content;
    let viewList = [];

    if (selectedTab === CHAT_TAB_INDEX.personal) {
      if (isBranchServer && content) {
        viewList = getSearchResult(content, accountList || [], ["name", "phone"]);
      } else {
        viewList = getSearchResult(content, contactList || [], ["name", "phone"]);
      }

      setOrderContactByName(groupDataByLetter(viewList, "name"));
    } else if (selectedTab === CHAT_TAB_INDEX.group) {
      getInteractor(prefixKey)
        .LocalGroupService.searchGroupByCondition({
          keyword: content,
          group_type: SystemConstant.GROUP_CHAT_TYPE.group,
          account_id: accountId,
          branch_id: branchId,
        })
        .then(sortListGroup => setFilterGroupList(groupDataByLetter(sortListGroup, "groupName")));
    }
  };

  useEffect(() => {
    const fetchDataTimeout = setTimeout(() => {
      onChangeSearchInput(searchRef.current);
    }, AppConstant.DEBOUNCE_TIME);

    return () => clearTimeout(fetchDataTimeout);
  }, [accountList, contactList, isBranchServer, selectedTab]);

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.fixedHeader}>
          <PrimaryTab
            tabArray={contactTabArray}
            onChange={setSelectedTab}
            TabIndicatorProps={{
              className: "hidden",
            }}
            selectedTabIndex={selectedTab}
            classes={{
              flexContainer: classes.flexContainer,
              tabButton: classes.tabButton,
              tabLabel: clsx(classes.tabLabel, "bold-sm-txt"),
              tabLabelSelected: classes.tabLabelSelected,
              tabButtonSelected: classes.tabButtonSelected,
            }}
          />

          <SearchBar
            onChange={onChangeSearchInput}
            classes={{
              root: classes.searchBarRoot,
            }}
          />
        </Box>

        <TabPanel value={selectedTab} index={CHAT_TAB_INDEX.personal}>
          <InfiniteScroll className={classes.contactListBox}>
            {Object.keys(orderContactByName).map((firstLetter, index) => (
              <DataListByLetter
                key={index}
                dataList={orderContactByName[firstLetter]}
                letter={firstLetter}
                onItemClick={onClick}
              />
            ))}
          </InfiniteScroll>
        </TabPanel>

        <TabPanel value={selectedTab} index={CHAT_TAB_INDEX.group}>
          <InfiniteScroll className={classes.contactListBox}>
            {Object.keys(filterGroupList).map((firstLetter, index) => (
              <DataListByLetter
                key={index}
                dataList={filterGroupList[firstLetter]}
                letter={firstLetter}
                onItemClick={onClick}
                isGroup={true}
              />
            ))}
          </InfiniteScroll>
        </TabPanel>
      </Box>

      <AddingContact isGroupTab={selectedTab === CHAT_TAB_INDEX.group} />
    </>
  );
};

ContactListTab.propTypes = {
  onClick: PropTypes.func,
};

ContactListTab.defaultProps = {
  onClick: () => {},
};

const CHAT_TAB_INDEX = {
  personal: 0,
  group: 1,
};

export default memo(ContactListTab);

const groupDataByLetter = (dataList, groupField) => {
  if (!dataList || dataList.length === 0) return [];

  const handleContactsObj = dataList.reduce((newContactList, contact) => {
    const firstLetter = contact[groupField] ? getFirstLetter(contact[groupField]).toUpperCase() : "#";
    newContactList[firstLetter] = (newContactList[firstLetter] || []).concat(contact);

    return newContactList;
  }, {});

  return handleContactsObj;
};

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
  },

  flexContainer: {
    padding: "12px 14px",
  },

  tabButton: {
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    height: 30,
    minHeight: "unset",
    borderRadius: 15.5,
  },

  tabLabel: {
    marginTop: 0,
    color: "#7F7F7F",
  },

  tabLabelSelected: {
    color: "#1E272E",
  },

  tabButtonSelected: {
    backgroundColor: theme.palette.grey[100],
  },

  fixedHeader: {
    position: "sticky",
  },

  searchBarRoot: {
    margin: "0 14px",
    maxWidth: "calc(100% - 28px)",
  },

  contactListBox: {
    maxHeight: "calc(100vh - 330px)",
    overflow: "auto",
    padding: "16px 14px",
  },
}));

import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Button, Dialog, Divider, Typography } from "@mui/material";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { useDispatch } from "react-redux";
import UploadDocument from "./UploadDocument";
import { saveMessageInQueue, useConversationContext } from "../ConversationContext";
import UploadMedia from "./UploadMedia";
import { ConversationActions } from "redux-store";
import { DEFAULT_ATTACHMENT } from "redux-store/conversation.redux";
import { AttachmentUtil, MultipleFileUtil, StorageUtil, convertHex2rgba, isImage, isVideo } from "utils";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { getInteractor } from "services/local.service";
import MessengerChatInput from "../MessengerChatInput";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useAlertContext } from "components/context/AlertContext";
import { Processing } from "components";
import { compressImageFile } from "utils/file.utils";

const MAX_UPLOADING = 9;
const UploadFileDialog = ({ isMediaType, multipleFile, onClose }) => {
  const hasMultipleFile = multipleFile.length > 0;
  const isUploadMedia = Boolean(hasMultipleFile && isMediaType);
  const isUploadFile = Boolean(hasMultipleFile && !isMediaType);
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    groupDetail,
    store: { selectedThreadId },
  } = useConversationContext();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const { showAlert } = useAlertContext();

  const [uploadFiles, setUploadFiles] = useState(multipleFile);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseDialog = () => {
    dispatch(
      ConversationActions.conversationSet({
        uploadingAttachment: DEFAULT_ATTACHMENT,
      }),
    );

    onClose();
  };

  const handleConfirm = async (caption = null) => {
    setIsLoading(true);
    try {
      const savingFiles = [];
      for (let index = 0; index < uploadFiles.length; index++) {
        const fileItem = uploadFiles[index];
        const fileType = fileItem.file.type || AppConstant.DEFAULT_MIME_TYPE;
        // Compress image file before saving
        const compressedFile = isImage(fileType) ? await compressImageFile(fileItem.file) : fileItem.file;
        const savingPath = await AttachmentUtil.saveFile2Local(fileItem.attachmentId, compressedFile, groupDetail.id, {
          name: fileItem.file.name,
        });

        if (!savingPath) throw new Error("NOT_SAVING_FILE");

        savingFiles.push({
          ...fileItem,
          file: {
            ...compressedFile,
            name: fileItem.file.name,
            type: fileType,
            path: savingPath,
            size: compressedFile.size,
          },
        });
      }

      // Encryption message content and save file info in file.table
      const encryptionResults = await MultipleFileUtil.encryptMultipleFile(savingFiles);
      if (encryptionResults) {
        await getInteractor().LocalFileService.saveMultipleFile(
          encryptionResults,
          SystemConstant.UPLOAD_TYPE.group,
          accountId,
          groupDetail.id,
        );

        // Send request to handle message in queue
        const messageContent = {
          attachment_id: "",
          meta_data: "",
          aes_key_info: "",
          multiple_file: encryptionResults,
        };
        if (caption && typeof caption === "string") {
          messageContent.content_message = caption.trim();
        }
        await handleSendMessage(messageContent);
      } else {
        throw new Error("ENCRYPTION_ERROR");
      }
    } catch (error) {
      console.error(error);
      showAlert({ content: getCommonLang("TXT_SERVER_ERROR"), alertProps: { severity: "error" } });
    }

    setIsLoading(false);
  };

  const handleSendMessage = async content => {
    let mentionIds = [];

    if (content.content_message) {
      const mentionMemberList = (groupDetail.groupMembers || []).concat([SystemConstant.MENTION_ALL]);
      mentionIds = mentionMemberList.reduce((results, item) => {
        if (content.content_message.includes(item.id) && item.id === SystemConstant.MENTION_ALL.id) {
          results.push(`@{${item.id}}`);
        } else if (content.content_message.includes(item.id)) {
          results.push(item.id);
        }

        return results;
      }, []);
    }

    const sendType = isMediaType ? SystemConstant.SEND_TYPE.image : SystemConstant.SEND_TYPE.file;

    await saveMessageInQueue({
      prefixKey,
      groupId: groupDetail.id,
      threadId: selectedThreadId,
      sendType: sendType,
      content: JSON.stringify(content),
      mentionIds: mentionIds,
    });
    handleCloseDialog();
  };

  const handleChangeFile = async removedFile => {
    const newUploadList = uploadFiles.filter(item => item.attachmentId !== removedFile.attachmentId);
    if (newUploadList.length === 0) handleCloseDialog();
    setUploadFiles(newUploadList);
  };

  const isSingleMediaFile =
    isUploadMedia &&
    multipleFile.length === 1 &&
    (isImage(multipleFile[0].file.type) || isVideo(multipleFile[0].file.type));
  const isDisabledButton = uploadFiles.length > MAX_UPLOADING || isLoading;

  if (isLoading) {
    return <Processing isShow={true} />;
  }

  return (
    <Dialog
      open={hasMultipleFile}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <Box>
        {isUploadFile && (
          <UploadDocument multipleFile={multipleFile} onChangeFile={handleChangeFile} onClose={handleCloseDialog} />
        )}

        {isUploadMedia && (
          <UploadMedia multipleFile={multipleFile} onChangeFile={handleChangeFile} onClose={handleCloseDialog} />
        )}
      </Box>

      {isSingleMediaFile ? (
        <Box p={2}>
          <MessengerChatInput
            placeholder={getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_INPUT_CAPTION")}
            isAllowAttach={false}
            onSendMessage={handleConfirm}
          />
        </Box>
      ) : (
        <>
          <Divider />
          <Box className={classes.actionContainer} px={2}>
            <TotalUploadingFiles count={uploadFiles.length} />
            <Button
              className={classes.actionBtn}
              variant="contained"
              disabled={isDisabledButton}
              onClick={handleConfirm}
            >
              {getCommonLang(LangConstant.TXT_CONFIRM)}
            </Button>
          </Box>
        </>
      )}
    </Dialog>
  );
};

UploadFileDialog.propTypes = {
  isMediaType: PropTypes.bool,
  multipleFile: PropTypes.any.isRequired,

  onClose: PropTypes.func.isRequired,
};

export default memo(UploadFileDialog);

const TotalUploadingFiles = ({ count }) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();

  return (
    <Typography
      className={clsx(classes.totalText, count > MAX_UPLOADING && "warning")}
      dangerouslySetInnerHTML={{ __html: getLabel("FM_TOTAL", { count, limit: MAX_UPLOADING }) }}
    />
  );
};

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    width: "100%",
  },

  loadingBox: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: convertHex2rgba("#808080", 0.9),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: theme.zIndex.tooltip + 1,
  },

  actionContainer: {
    height: 64,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  actionBtn: {
    // width: 120,
    padding: "9px 16px",
    borderRadius: 10,
  },

  totalText: {
    "&.warning": {
      "& span": {
        color: "#FF0000",
        fontSize: 16,
      },
    },
  },
}));

import MessengerChatInput from "./MessengerChatInput";
import { useBlockedAccountStatus } from "hooks";
import { saveMessageInQueue, useConversationContext } from "./ConversationContext";
import { useTranslation } from "react-i18next";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { getNSLang } from "utils/lang.utils";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Button } from "@mui/material";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { AccountActions } from "redux-store";
import { StorageUtil } from "utils";
import { isGroupOrChannelType } from "pages/Call";

const INPUT_VIEW_STATE = {
  normal: 1,
  inactive: 2,
  block: 3,
};

const MessengerInputLayout = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const { isBlockedAccount, isBlockedByAccount } = useBlockedAccountStatus();
  const {
    groupDetail,
    store: { isInactive, selectedThreadId },
  } = useConversationContext();
  const prefixKey = StorageUtil.getCurrentPrefixKey();

  const handleSendMessage = async (content, mentionIds) => {
    await saveMessageInQueue({ prefixKey, groupId: groupDetail.id, threadId: selectedThreadId, content, mentionIds });
  };

  let viewState = INPUT_VIEW_STATE.normal;
  switch (true) {
    case isGroupOrChannelType(groupDetail.groupType):
      viewState = INPUT_VIEW_STATE.normal;
      break;
    case isInactive:
      viewState = INPUT_VIEW_STATE.inactive;
      break;
    case isBlockedAccount || isBlockedByAccount:
      viewState = INPUT_VIEW_STATE.block;
      break;

    default:
      viewState = INPUT_VIEW_STATE.normal;
      break;
  }

  return (
    <Box>
      <Box className={viewState !== INPUT_VIEW_STATE.normal && "hidden"} px={2.75} pb={2.75}>
        <MessengerChatInput
          placeholder={getNSLang(LangConstant.NS_COMMON, LangConstant.TXT_PLACEHOLDER)}
          onSendMessage={handleSendMessage}
        />
      </Box>

      {viewState === INPUT_VIEW_STATE.inactive && <InactiveInput getLabel={getLabel} classes={classes} />}

      {viewState === INPUT_VIEW_STATE.block && (
        <BlockInput
          groupDetail={groupDetail}
          getLabel={getLabel}
          classes={classes}
          isBlockedByAccount={isBlockedByAccount}
        />
      )}
    </Box>
  );
};

export default MessengerInputLayout;

const InactiveInput = ({ classes }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  return (
    <Box className={classes.blockedBox}>
      <Typography className={classes.blockedMessage}>{getLabel(LangConstant.TXT_INACTIVE_USER)}</Typography>
      <Button className={clsx(classes.unblockButton, "semiBold-lg-txt")}>{getLabel("TXT_UNDERSTAND")}</Button>
    </Box>
  );
};

const BlockInput = ({ classes, groupDetail, isBlockedByAccount }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const dispatch = useDispatch();

  const onUnblockUser = () => {
    // Implement only for direct personal chatting
    if (!groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) return;

    // Checking receiver is active or not in case personal conversation
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const chattingMember = groupDetail.groupMembers.find(member => member.id !== accountId);
    if (chattingMember?.id)
      dispatch(AccountActions.unblockUser({ groupId: groupDetail.id, blockedAccountId: chattingMember.id }));
  };

  return (
    <Box className={classes.blockedBox}>
      <Typography className={classes.blockedMessage}>
        {getLabel(isBlockedByAccount ? LangConstant.TXT_BEING_BLOCKED_MESSAGE : LangConstant.TXT_BLOCKED_MESSAGE)}
      </Typography>
      {!isBlockedByAccount && (
        <Button className={clsx(classes.unblockButton, "semiBold-lg-txt")} onClick={onUnblockUser}>
          {getLabel(LangConstant.TXT_UN_BLOCK)}
        </Button>
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  blockedBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 20,
    height: 120,
  },

  blockedMessage: {
    fontWeight: 600,
    lineHeight: "16px",
  },

  unblockButton: {
    backgroundColor: theme.palette.grey[500],
    textTransform: "none",
    color: theme.palette.white,
    padding: "14px 132px",
    marginBottom: 23,
    marginTop: 16,
    "&:hover": {
      backgroundColor: theme.palette.grey[800],
      boxShadow: "none",
    },
  },
}));

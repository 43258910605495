import { SystemConstant } from "const";
import { ExecuteEvent } from "pubsub/PubSub.const";
import store, { CallingActions } from "redux-store";
import { getInteractor } from "services/local.service";

export const deleteCallHistory = async (prefixKey, roomIds) => {
  try {
    const updateCallList = roomIds.map(item => ({
      updateValues: {
        state: SystemConstant.STATE.inactive,
        modified: Date.now(),
      },
      condition: {
        room_id: item,
      },
    }));
    await getInteractor(prefixKey).LocalCallHistoryService.updateListByCondition(updateCallList);

    store.dispatch(
      CallingActions.callingSet({
        deleteCallRoomIds: roomIds,
      }),
    );

    return ExecuteEvent.SUCCESSES;
  } catch (error) {
    console.log("event delete call history fail: ", error);
  }

  return ExecuteEvent.FAIL;
};

import { AppConstant, KeyConstant } from "const";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { notificationClear } from "utils/view.utils";
import { StorageUtil, convertString2JSON } from "utils";
import { BranchSelectors } from "redux-store";
import { getInteractor } from "services/local.service";
import { isLoginBranch } from "utils/auth.utils";

const useUnreadMessage = groupId => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);
  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);

  const [allUnreadMessages, setAllUnreadMessages] = useState(0);
  const [unreadInGroupNumber, setUnreadInGroupNumber] = useState(0);
  const [unreadNotification, setUnreadNotification] = useState(0);
  const [totalUnread, setTotalUnread] = useState(0);

  const updateTotalUnread = async () => {
    const numberUnreadNotice = (
      await getInteractor(prefixKey).LocalNotificationService.getUnreadNormalNoticeInBranch(selectedBranch.id)
    ).length;
    if (unreadNotification !== numberUnreadNotice) setUnreadNotification(numberUnreadNotice);

    const tmpTotalUnread = numberUnreadNotice + allUnreadMessages;
    if (tmpTotalUnread !== totalUnread) setTotalUnread(tmpTotalUnread);
  };

  useEffect(() => {
    const fetchDataTimeout = setTimeout(async () => {
      if (isLoginBranch(selectedBranch)) {
        const numberUnreadNotice = (
          await getInteractor(prefixKey).LocalNotificationService.getUnreadNormalNoticeInBranch(selectedBranch.id)
        ).length;
        if (unreadNotification !== numberUnreadNotice) setUnreadNotification(numberUnreadNotice);

        const unreadMsg = await getInteractor(prefixKey).LocalMessageService.getUnreadMessageInGroup({
          group_id: groupId,
          account_id: accountId,
          branch_id: branchId,
        });

        const allUnreadMessage = await notificationClear(unreadMsg);
        const allMsgNotSeen = allUnreadMessage.allNotSeen;

        let tmpUnreadMsg = [];

        for (const msg of Object.values(allMsgNotSeen).filter(item => item.branch_id === selectedBranch.id)) {
          const currentGroup = await getInteractor(prefixKey).LocalGroupService.findById(msg.group_id);
          const options = convertString2JSON(currentGroup.options, {});

          if (!(options.hidden && options.hidden.includes(accountId))) {
            tmpUnreadMsg = [...tmpUnreadMsg, msg];
          }
        }

        if (groupId) {
          const unreadMsgInGroupLength = tmpUnreadMsg.filter(item => item.group_id === groupId).length;
          if (unreadMsgInGroupLength !== unreadInGroupNumber) setUnreadInGroupNumber(unreadMsgInGroupLength);
        } else {
          if (tmpUnreadMsg.length !== allUnreadMessages) setAllUnreadMessages(tmpUnreadMsg.length);
          const tmpTotalUnread = numberUnreadNotice + tmpUnreadMsg.length;
          if (tmpTotalUnread !== totalUnread) setTotalUnread(tmpTotalUnread);
        }
      }
    }, AppConstant.DEBOUNCE_TIME);

    return () => clearTimeout(fetchDataTimeout);
  }, [isUpdateViewMode, selectedBranch, groupId]);

  return { allUnreadMessages, unreadInGroupNumber, unreadNotification, totalUnread, updateTotalUnread };
};

export default useUnreadMessage;

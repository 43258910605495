import { useEffect, useState } from "react";
import { getInteractor } from "services/local.service";
import { StorageUtil, toCamel } from "utils";
import { uniqBy } from "lodash";
import { useSelector } from "react-redux";
import store, { BranchSelectors, CallingActions, CallingSelectors } from "redux-store";

const DEFAULT_CONDITIONS = {
  limit: 20,
  isGetLatest: false,
};
const useCallHistory = () => {
  const selectedServerRedux = useSelector(BranchSelectors.getSelectedBranch);
  const isFetchHistoryTimestamp = useSelector(CallingSelectors.getFetchTimestamp);
  const deleteCallRoomIds = useSelector(CallingSelectors.getDeleteCallRoomIds);

  const [callHistoryList, setCallHistoryList] = useState([]);

  const handleSetCallHistory = async (prefixKey, conditions = {}, currentCallList) => {
    const compareTime = conditions.compareTime || callHistoryList[callHistoryList.length - 1]?.created || Date.now();
    const queryConditions = { ...DEFAULT_CONDITIONS, ...conditions, compareTime };
    const endCallList = await getInteractor(prefixKey).LocalCallHistoryService.getNotCalling(queryConditions);

    const callDetailList = [];
    for (let index = 0; index < endCallList.length; index++) {
      const callHistory = endCallList[index];
      const groupDetail = await getInteractor(prefixKey).LocalGroupService.get(callHistory.group_id);
      const initCall = await getInteractor(prefixKey).LocalCallHistoryService.getFirstByRoomId(callHistory.room_id);
      const timeStart = initCall.created;

      callDetailList.push(
        toCamel({
          ...callHistory,
          groupDetail,
          timeStart,
        }),
      );
    }

    currentCallList = currentCallList || callHistoryList;
    const newCallList = uniqBy(
      conditions.isGetLatest ? callDetailList.concat(currentCallList) : currentCallList.concat(callDetailList),
      "id",
    );
    setCallHistoryList(newCallList);
  };

  const deleteCallHistory = roomIds => {
    if (false === Boolean(Array.isArray(roomIds) && roomIds.length > 0)) return;

    const newCallHistory = callHistoryList.filter(item => false === Boolean(roomIds.includes(item.roomId)));
    setCallHistoryList(newCallHistory);
  };

  useEffect(() => {
    const prefixKey = StorageUtil.getCurrentPrefixKey();
    handleSetCallHistory(prefixKey, { compareTime: Date.now() }, []);
  }, [selectedServerRedux]);

  // Add new call history
  useEffect(() => {
    const prefixKey = StorageUtil.getCurrentPrefixKey();
    const latestCallHistory = callHistoryList[0] || {};
    const latestCreated = latestCallHistory.created;
    handleSetCallHistory(prefixKey, { compareTime: latestCreated, isGetLatest: true }, callHistoryList);
  }, [isFetchHistoryTimestamp]);

  // Delete call history
  useEffect(() => {
    if (Boolean(Array.isArray(deleteCallRoomIds) && deleteCallRoomIds.length > 0)) {
      deleteCallHistory(deleteCallRoomIds);
      store.dispatch(
        CallingActions.callingSet({
          deleteCallRoomIds: [],
        }),
      );
    }
  }, [deleteCallRoomIds]);

  return { callHistoryList, handleSetCallHistory, deleteCallHistory };
};

export default useCallHistory;

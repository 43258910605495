import { useEffect, useRef, useState } from "react";
import { KeyConstant, SystemConstant } from "const";
import { getInteractor } from "services/local.service";
import { getFileList, updateFileStatus } from "pubsub/services/file.service";
import { uploadFile } from "services/multiple-file";
import { AttachmentUtil, FileUtil, StorageUtil } from "utils";
import { useSelector } from "react-redux";

// Handle upload file status with tus
const useUploadFile = (prefixKey, attachmentId, uploadType, groupId) => {
  const cleanUpRef = useRef(false);
  const remoteUploadMessage = useSelector(state => state.conversationRedux.remoteUploadMessage);

  const [uploadStatus, setUploadStatus] = useState(SystemConstant.UPLOAD_STATUS.inProgress);

  const handleSetUploadStatus = param => {
    if (cleanUpRef.current) return null;
    setUploadStatus(param);
  };

  const clearFile = attachmentId => {
    const encryptFolder = AttachmentUtil.getEncryptionFolder(attachmentId);
    const encryptZipFile = AttachmentUtil.getEncryptionZipPath(attachmentId);

    if (FileUtil.isExistFile(encryptFolder)) FileUtil.removeFolder(encryptFolder);
    if (FileUtil.isExistFile(encryptZipFile)) FileUtil.removeFile(encryptZipFile);
  };

  const onError = async error => {
    console.log("upload error: ", error);

    handleSetUploadStatus(SystemConstant.UPLOAD_STATUS.failed);
    await getInteractor(prefixKey).LocalFileService.update(
      { status: SystemConstant.UPLOAD_STATUS.failed, modified: Date.now() },
      { id: attachmentId },
    );
    clearFile(attachmentId);

    // Update file status to server
    await updateFileStatus(prefixKey, attachmentId, SystemConstant.UPLOAD_STATUS.failed, groupId);
  };

  const onProgress = (bytesUploaded, bytesTotal) => {
    const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
    console.log({ percentage });
    handleSetUploadStatus(SystemConstant.UPLOAD_STATUS.inProgress);
  };

  const onSuccess = async () => {
    handleSetUploadStatus(SystemConstant.UPLOAD_STATUS.success);

    await getInteractor(prefixKey).LocalFileService.update(
      { status: SystemConstant.UPLOAD_STATUS.success, modified: Date.now() },
      { id: attachmentId },
    );
    clearFile(attachmentId);
  };

  const handleUpload = async () => {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    let fileRecord = await getInteractor(prefixKey).LocalFileService.get(attachmentId);
    if (false === Boolean(fileRecord)) {
      const remoteList = (await getFileList(prefixKey, [attachmentId], groupId)) || [];
      fileRecord = remoteList[0];
    }

    if (fileRecord) {
      handleSetUploadStatus(fileRecord.status);

      if (
        fileRecord.status === SystemConstant.UPLOAD_STATUS.inProgress &&
        fileRecord.created_by_account_id === accountId
      ) {
        const zipFilePath = AttachmentUtil.getEncryptionZipPath(attachmentId);
        if (FileUtil.isExistFile(zipFilePath)) {
          uploadFile(prefixKey, attachmentId, uploadType, groupId, { onError, onProgress, onSuccess });
        }
      }
    }
  };

  useEffect(() => {
    handleUpload();

    return () => {
      cleanUpRef.current = true;
    };
  }, []);

  useEffect(() => {
    if (false === Boolean(remoteUploadMessage)) return;
    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
    const isCurrentDevice = remoteUploadMessage.senderDeviceId === deviceId;
    const messageContent = remoteUploadMessage.content || "";
    if (messageContent.includes(attachmentId) && !isCurrentDevice) {
      handleUpload();
    }
  }, [remoteUploadMessage]);

  return { uploadStatus };
};

export default useUploadFile;

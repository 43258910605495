import React, { useState, createContext, useContext, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import store, { AccountSelectors, ConversationActions, ConversationSelectors } from "redux-store";
import { StorageUtil, convertString2JSON, isNotEqual, toCamel, uuid } from "utils";
import { getSendType } from "utils/view.utils";
import { CarouselMedia } from "components";
import { getInteractor } from "services/local.service";
import { debounce } from "lodash";
import { KeyConstant, SystemConstant } from "const";
import { refactorFiles } from "pages/ManageFilePage/file.helper";
import uniqBy from "lodash/uniqBy";
import { getSavedServer } from "utils/branch.utils";

/* ------------- Initial State ------------- */
const DEFAULT_GROUP_DATA = {
  groupName: "",
  groupMembers: [],
};

const DEFAULT_STORE = {
  selectedThreadId: null,
  selectedMediaId: null, // Not select media
  isInactive: false, // False: User is inactive in case personal group, else True
};

const INITIAL_STATE = {
  store: DEFAULT_STORE,
  groupDetail: DEFAULT_GROUP_DATA,

  saveConversation: store => {},
  clickMedia: fileInfo => {},
};

const ConversationContext = createContext(INITIAL_STATE);
export const useConversationContext = () => useContext(ConversationContext);

export const ConversationProvider = ({ children }) => {
  const groupRef = useRef({
    detail: {},
    threadId: null,
  });
  console.log("ConversationProvider");
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const selectedGroupId = useSelector(ConversationSelectors.getSelectedGroupId);
  const selectedThreadId = useSelector(ConversationSelectors.getThreadingId);
  groupRef.current.threadId = selectedThreadId;
  const updateChattingMember = useSelector(AccountSelectors.getUpdateChattingMember);

  const [store, setStore] = useState(DEFAULT_STORE);
  const [groupDetail, setGroupDetail] = useState({
    ...DEFAULT_GROUP_DATA,
  });

  const handleUpdateGroup = async group => {
    if (!group) group = { groupName: "", groupMembers: [] };
    else if (!Array.isArray(group.groupMembers)) group.groupMembers = [];
    // Checking receiver is active or not in case personal conversation
    const chattingMember = group.groupMembers.find(member => member.id !== accountId);
    const inactiveAccountIds = (await getInteractor().LocalBranchAccountService.getAllInactiveAccount(branchId)).map(
      item => item.account_id,
    );
    const deletedAccountIds = (await getInteractor().LocalBranchAccountService.getAllDeleteAccount(branchId)).map(
      item => item.account_id,
    );

    const isDeletedAccount = chattingMember && deletedAccountIds.includes(chattingMember.id);
    const isInactiveAccount = chattingMember && inactiveAccountIds.includes(chattingMember.id);
    const isDisableChatting =
      group.groupType === SystemConstant.GROUP_CHAT_TYPE.personal && (isDeletedAccount || isInactiveAccount);

    saveConversation({
      isInactive: isDisableChatting,
    });

    groupRef.current.detail = group;
    setGroupDetail(group);
  };

  const handleGetGroupFromDB = useCallback(
    debounce(async selectedGroupId => {
      if (!selectedGroupId) return;

      const groupInDB = await getInteractor().LocalGroupService.get(selectedGroupId);
      if (isNotEqual(groupInDB, groupRef.current.detail)) handleUpdateGroup(groupInDB);
    }, 10),
    [],
  );

  const handleChangeGroup = async () => {
    let handleGroupId = selectedGroupId;
    if (selectedThreadId) {
      const selectedThread = toCamel(await getInteractor().LocalThreadService.getAllByBranchId(branchId)).find(
        item => item.threadId === selectedThreadId,
      );
      if (selectedThread?.groupId) handleGroupId = selectedThread.groupId;
    }

    if (handleGroupId) {
      handleGetGroupFromDB(handleGroupId);
    } else {
      handleUpdateGroup();
    }

    setStore(preState => ({ ...preState, selectedThreadId: selectedThreadId }));
  };

  const saveConversation = useCallback(newData => setStore(preState => ({ ...preState, ...newData })), []);

  const clickMedia = useCallback(fileInfo => {
    saveConversation({
      selectedMediaId: fileInfo.mediaId,
    });
  }, []);

  useEffect(() => {
    handleChangeGroup();
  }, [selectedGroupId, selectedThreadId]);

  useEffect(() => {
    const groupMembersId = groupDetail.groupMembers.map(item => item.id);
    if (
      groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal &&
      groupMembersId &&
      groupMembersId.includes(updateChattingMember.id)
    ) {
      saveConversation({
        isInactive: false === Boolean(updateChattingMember.state && updateChattingMember.status),
      });
      handleGetGroupFromDB(groupDetail.id);
    }
  }, [updateChattingMember]);

  return (
    <ConversationContext.Provider value={{ store, groupDetail, saveConversation, clickMedia }}>
      {children}

      <MediaCarouselDialog
        groupDetail={groupDetail}
        selectedMediaId={store.selectedMediaId}
        onClose={() => saveConversation({ selectedMediaId: null })}
      />
    </ConversationContext.Provider>
  );
};

const PAGE_SIZE = 12;
const MediaCarouselDialog = ({ groupDetail, selectedMediaId, onClose }) => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();

  const [mediaFileList, setMediaFileList] = useState([]);

  const handleQueryMediaMessage = isNewer => async () => {
    let recordList = await getInteractor(prefixKey).LocalFileGroupService.getFiles({
      group_id: groupDetail.id,
      file_type: [SystemConstant.FILE_TYPE.IMAGE, SystemConstant.FILE_TYPE.VIDEO],
      limit: PAGE_SIZE,
      isNewer,
      compare_date: mediaFileList[isNewer ? mediaFileList.length - 1 : 0].created,
    });

    if (!isNewer) recordList.reverse();
    recordList = await refactorFiles(prefixKey, recordList);
    const newFileList = uniqBy(isNewer ? mediaFileList.concat(recordList) : recordList.concat(mediaFileList), "id");

    setMediaFileList(newFileList);
  };

  const handleInitData = async () => {
    const [sourceMessageId, fileId] = selectedMediaId.split("_"); // <sourceMessageId>_<fileId>

    const selectedFile = await getSelectedFile(prefixKey, {
      groupId: groupDetail.id,
      attachmentId: fileId,
      sourceMessageId: sourceMessageId,
    });

    if (!(selectedFile && selectedFile.id)) return;

    // Show selected media file before
    const initList = await refactorFiles(prefixKey, [selectedFile]);
    setMediaFileList(() => initList);

    // Query file list around selected file
    const recordList = await getInteractor(prefixKey).LocalFileGroupService.getAroundFiles(selectedFile, {
      group_id: groupDetail.id,
      limit: PAGE_SIZE,
      file_type: [SystemConstant.FILE_TYPE.IMAGE, SystemConstant.FILE_TYPE.VIDEO],
    });

    refactorFiles(prefixKey, recordList).then(fileList => setMediaFileList(fileList));
  };

  useEffect(() => {
    const isValid = groupDetail && groupDetail.id && selectedMediaId;
    if (!isValid) return;

    setMediaFileList([]);
    handleInitData();
  }, [groupDetail, selectedMediaId]);

  const selectedMediaIndex = mediaFileList.findIndex(fileItem => fileItem.metaData.mediaId === selectedMediaId);
  const isShowCarousel = selectedMediaIndex >= 0;

  return (
    <CarouselMedia
      open={isShowCarousel}
      onClose={onClose}
      data={mediaFileList}
      initialIndex={selectedMediaIndex}
      onScrollRightEnd={handleQueryMediaMessage(true)}
      onScrollLeftEnd={handleQueryMediaMessage(false)}
    />
  );
};

export const saveMessageInQueue = async ({
  prefixKey = StorageUtil.getCurrentPrefixKey(),
  groupId,
  threadId,
  sendType,
  content,
  parentId = null,
  mentionIds = [],
  forward,
}) => {
  const isValid = groupId && prefixKey;
  if (!isValid) {
    console.error("INVALID_MESSAGE: ", { groupId, prefixKey, isValid });
    throw new Error("INVALID_MESSAGE");
  }

  try {
    const branchServerId = getSavedServer().id;
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
    const groupDetail = await getInteractor(prefixKey).LocalGroupService.get(groupId);
    const isValidGroup = Boolean(
      groupDetail && groupDetail.id && Array.isArray(groupDetail.groupMembers) && groupDetail.groupMembers.length > 0,
    );
    if (!content || content === "" || false === Boolean(branchServerId) || false === isValidGroup) return;

    const mentions = Array.isArray(mentionIds) ? mentionIds : convertString2JSON(mentionIds, []);
    const options = JSON.stringify({
      encryption_f: groupDetail.encryptionF,
    });

    const saveMessage = {
      account_id: accountId,
      branch_id: branchServerId,
      content: content,
      created: Date.now(),
      device_id: deviceId,
      group_id: groupId,
      id: uuid(),
      mentions: JSON.stringify(mentions),
      modified: null,
      options: options,
      parent_id: parentId,
      send_type: getSendType(sendType, content, groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal),
      sender_device_id: deviceId,
      sender_id: accountId,
      source_id: uuid(),
      state: 1,
      status: 1,
      thread_id: threadId,
      call_status: null,
    };

    store.dispatch(
      ConversationActions.sendMessage(
        {
          groupId: groupId,
          sendType: sendType,
          content: content,
          parentId: parentId,
          branchId: branchServerId,
          mentionIdsArr: JSON.stringify(mentions),
          threadId: threadId,
          currentMessage: saveMessage,
          forward: forward,
        },
        StorageUtil.getCurrentPrefixKey(),
      ),
    );
  } catch (error) {
    console.error(error);
  }
};

const getSelectedFile = async (prefixKey, { groupId, sourceMessageId, attachmentId }) => {
  const mediaFileType = [SystemConstant.FILE_TYPE.IMAGE, SystemConstant.FILE_TYPE.VIDEO];
  const queryCondition = {
    group_id: groupId,
    source_message_id: sourceMessageId,
    file_id: attachmentId,
    file_type: mediaFileType,
  };
  const selectedFile = await getInteractor(prefixKey).LocalFileGroupService.findOne(queryCondition);

  // Try to store file_group record from sourceMessage
  if (!(selectedFile && selectedFile.id)) {
    const message = await getInteractor(prefixKey).LocalMessageService.findOne({
      source_id: sourceMessageId,
    });
    console.log({ message });

    if (message && message.id) {
      await getInteractor(prefixKey).LocalFileGroupService.saveFromMessage([message]);
    }
  }

  return getInteractor(prefixKey).LocalFileGroupService.findOne(queryCondition);
};

import React, { Suspense, memo, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { ConversationActions } from "redux-store";
import { People, Grid3x3, Lock, ArrowBack } from "@mui/icons-material";
import { getInteractor } from "services/local.service";
import { StorageUtil } from "utils";

const ThreadViewMode = React.lazy(() => import("./ThreadViewMode"));
const Thread = ({ groupDetail, threadingId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const [senderParentMsg, setSenderParentMsg] = useState({});
  const [isOnline, setIsOnline] = useState(false);

  const isChannel = groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.channel;
  const isGroup = groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.group;
  const isPersonal = groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;
  const isChannelPrivate = isChannel && Boolean(groupDetail.privateF);

  const onCloseThreading = () => {
    dispatch(
      ConversationActions.setSelectGroupId({
        threadingId: null,
        selectedGroupId: groupDetail.id,
      }),
    );
  };

  const getParentSender = async () => {
    const parentMsg =
      (await getInteractor(prefixKey).LocalMessageService.findOne({
        source_id: threadingId,
      })) || {};
    const senderId = parentMsg.sender_id;
    const senderAccount = await getInteractor(prefixKey).LocalAccountService.get(senderId, branchId);

    setSenderParentMsg(senderAccount);
  };

  useEffect(() => {
    getParentSender();
  }, [threadingId]);

  useEffect(() => {
    if (threadingId && isPersonal && senderParentMsg.id) {
      const checkOnlineInterval = setInterval(() => {
        getInteractor()
          .LocalDeviceService.isOnlineAccount(senderParentMsg.id)
          .then(isOnlineAccount => setIsOnline(Boolean(isOnlineAccount)));
      }, 1000);

      return () => {
        clearInterval(checkOnlineInterval);
      };
    }
  }, [threadingId, senderParentMsg]);

  return (
    <Box className={classes.container}>
      <Box className={classes.threadTitle}>
        <IconButton onClick={onCloseThreading}>
          <ArrowBack />
        </IconButton>
        <Box className={classes.titleText}>
          <Typography className={classes.title}>{getLabel(LangConstant.TXT_THREAD)}</Typography>
          <Box className={classes.subtitleBox}>
            {isPersonal ? (
              <>
                <Box className={isOnline ? classes.activeState : classes.inactiveStatus} />
                <Typography className={clsx(classes.subtitle, "regular-sm-txt ellipsis")}>
                  {senderParentMsg?.name}
                </Typography>
              </>
            ) : (
              <>
                {isGroup ? (
                  <People className={classes.icon} />
                ) : isChannelPrivate ? (
                  <Lock className={classes.icon} />
                ) : (
                  <Grid3x3 className={classes.icon} />
                )}
                <Typography className={clsx(classes.subtitle, "regular-sm-txt ellipsis")}>
                  {groupDetail.groupName}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box className={classes.viewContainer}>
        <Suspense fallback={<Box p={2}>Loading...</Box>}>
          <ThreadViewMode />
        </Suspense>
      </Box>
    </Box>
  );
};

export default memo(Thread);

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    backgroundColor: theme.palette.white,
    border: 20,
    width: "100%",
    height: "calc(100% - 177px)",
    flex: 1,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },

  threadTitle: {
    padding: "35px 28px",
    display: "flex",
    alignItems: "center",
    maxWidth: "70%",
  },

  title: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 4,
  },

  subtitle: {
    color: theme.palette.grey[500],
  },

  titleText: {
    marginLeft: 20,
  },

  viewContainer: {
    overflow: "auto",
  },

  activeState: {
    width: 10,
    height: 10,
    backgroundColor: "#0bd50b",
    borderRadius: "50%",
    marginRight: 4,
  },

  inactiveStatus: {
    width: 10,
    height: 10,
    backgroundColor: "gray",
    borderRadius: "50%",
    marginRight: 4,
  },

  icon: {
    color: "gray",
    fontSize: 16,
    marginRight: 4,
  },

  subtitleBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

import { KeyConstant } from "const";
import { camelizeKeys } from "humps";
import store, { BranchActions, ConversationActions } from "redux-store";
import { CommonBranchInfoService } from "services";
import { convertString2JSON, getPrefixKey, isJSONString, StorageUtil } from "utils";

export const DEFAULT_SEVER_OPTIONS = {
  meetMaxCallPerson: 20,
  maxFileSize: 100, // Default 100MB
};
export const getBranchOptions = options => {
  const branchOptions =
    options && isJSONString(options)
      ? camelizeKeys(convertString2JSON(options, DEFAULT_SEVER_OPTIONS))
      : DEFAULT_SEVER_OPTIONS;
  branchOptions.meetMaxCallPerson = branchOptions.meetMaxCallPerson || DEFAULT_SEVER_OPTIONS.meetMaxCallPerson;
  branchOptions.maxFileSize = branchOptions.maxFileSize || DEFAULT_SEVER_OPTIONS.maxFileSize;

  return branchOptions;
};

export const getSavedServer = selectedBranch => {
  selectedBranch = selectedBranch?.id
    ? selectedBranch
    : StorageUtil.getCommonKey(KeyConstant.KEY_SELECTED_SERVER) || {};

  // Init branch Icon if it not exist
  if (false === Boolean(selectedBranch.branchIcon)) {
    selectedBranch.branchIcon = CommonBranchInfoService.getBranchAvatarUrl(selectedBranch.domain, selectedBranch.id);
  }

  // Create serverOptions json object
  selectedBranch.serverOptions = getBranchOptions(selectedBranch.options);

  return selectedBranch;
};

export const changeBranchServer = selectedServer => {
  const prefixKey = getPrefixKey(selectedServer.accountId, selectedServer.id);

  StorageUtil.setCommonKey(KeyConstant.KEY_PREFIX, prefixKey);
  StorageUtil.setCommonKey(KeyConstant.KEY_CURRENT_DOMAIN, selectedServer.domain);
  StorageUtil.setCommonKey(KeyConstant.KEY_SELECTED_SERVER, selectedServer);

  store.dispatch(BranchActions.updateSelectedBranch(selectedServer));
  store.dispatch(
    ConversationActions.setSelectGroupId({
      threadingId: null,
      selectedGroupId: null,
    }),
  );

  return getBranchOptions(selectedServer.options);
};

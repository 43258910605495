import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { getExternalLinkFromString, isExternalLink, StorageUtil } from "utils";
import { PREFIX_MENTION, SUFFIX_MENTION, replaceId2Name, replaceName2Id } from "utils/message.utils";
import { useSelector } from "react-redux";
import { ConversationSelectors } from "redux-store";
import { getMentionMembers } from "./ChatItem.helper";
import { useEffect, useState } from "react";
import { useCleanUpEffect } from "hooks";

const ChatTypography = ({ messageContent, mentions, mentionList, ...otherProps }) => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { searchValue } = useSelector(ConversationSelectors.getSearchingMessage);
  const { isMounted } = useCleanUpEffect();

  const [contentList, setContentList] = useState([]);

  const handleChatContent = async () => {
    mentionList = Array.isArray(mentionList) ? mentionList : await getMentionMembers(prefixKey, mentions);
    let chatContent = await separateMessageContent(replaceName2Id(messageContent, mentionList), mentionList);
    if (searchValue) chatContent = separateSearchValue(contentList, searchValue);

    if (isMounted()) setContentList(chatContent);
  };

  useEffect(() => {
    handleChatContent();
  }, [messageContent, mentions, mentionList, prefixKey, searchValue]);

  return (
    <Box
      sx={{ whiteSpace: "pre-wrap", lineBreak: "normal", wordBreak: "break-word", color: "inherit" }}
      {...otherProps}
    >
      {contentList.map((item, index) =>
        item.isHtml ? (
          <Typography key={index} component="b" dangerouslySetInnerHTML={{ __html: item.content }} color="inherit" />
        ) : (
          <Typography key={index} component="span" color="inherit">
            {item.content}
          </Typography>
        ),
      )}
    </Box>
  );
};

ChatTypography.propTypes = {
  messageContent: PropTypes.string.isRequired,
  mentionList: PropTypes.array,
};

export default ChatTypography;

const separateMentionContent = msgContent => {
  if (!msgContent || false === msgContent.includes(PREFIX_MENTION)) {
    return {
      rawText: msgContent,
      startMentionIndex: 0,
      endMentionIndex: 0,
    };
  }

  const startMentionIndex = msgContent.indexOf(PREFIX_MENTION);
  const rawText = msgContent.slice(0, startMentionIndex);
  const newMsgContent = msgContent.slice(startMentionIndex);
  const endMentionIndex = newMsgContent.indexOf(SUFFIX_MENTION) + 1;

  return {
    rawText,
    startMentionIndex,
    endMentionIndex,
    mentionContent: newMsgContent.slice(0, endMentionIndex),
    otherContent: newMsgContent.slice(endMentionIndex),
  };
};

const separateLinkContent = (msgContent, resultArr = []) => {
  const hasLinkContent = msgContent && isExternalLink(msgContent);

  if (!hasLinkContent) {
    resultArr = [...resultArr, { content: msgContent, isHtml: false }];
    return resultArr;
  }

  try {
    const url = getExternalLinkFromString(msgContent)[0];
    if (url) {
      const startUrlIndex = msgContent.indexOf(url);
      resultArr = [
        ...resultArr,
        { content: msgContent.slice(0, startUrlIndex), isHtml: false },
        {
          content: `<a href="${url}">${url}</a>`,
          isHtml: true,
          isLink: true,
        },
      ];

      return separateLinkContent(msgContent.slice(startUrlIndex + url.length, msgContent.length), resultArr);
    } else {
      return resultArr;
    }
  } catch (error) {
    console.error(error);
    console.warn({ msgContent });
    return resultArr;
  }
};

const separateMessageContent = async (msgContent = "", mentions) => {
  const isSpecialContent = Boolean(msgContent) && (isExternalLink(msgContent) || msgContent.includes(PREFIX_MENTION));

  if (!msgContent || !isSpecialContent) {
    return [
      {
        content: msgContent,
        isHtml: false,
      },
    ];
  }

  /**
   * Array object that structure like:
   * {
   *  content: <message content>
   *  isHtml: <true: display html text - false: raw text>
   * }
   */
  let result = [];
  const { rawText, mentionContent, otherContent } = separateMentionContent(msgContent);
  if (rawText) result = result.concat(separateLinkContent(rawText));

  if (mentionContent) {
    result.push({
      content: await replaceId2Name(mentionContent, mentions),
      isHtml: true,
    });
  }

  if (otherContent) result = result.concat(await separateMessageContent(otherContent, mentions));

  return result.filter(item => Boolean(item.content));
};

const separateSearchValue = (contentList, searchValue) => {
  let convertContentList = contentList;
  let results = [];
  let replaceIndex = 0;

  contentList.forEach(item => {
    const itemContent = item.content || "";
    const startSearchIndex = itemContent.indexOf(searchValue);
    if (startSearchIndex >= 0 && false === Boolean(item.isLink)) {
      let result = [];
      result[0] = {
        content: itemContent.slice(0, startSearchIndex),
        isHtml: item.isHtml,
      };
      result[1] = {
        content: `<strong style="color: black; background: yellow">${searchValue}</strong>`,
        isHtml: true,
      };
      result[2] = {
        content: itemContent.slice(startSearchIndex + searchValue.length, itemContent.length),
        isHtml: item.isHtml,
      };

      let startArr = convertContentList.splice(replaceIndex);
      startArr.shift();
      results = convertContentList.concat(result).concat(startArr);

      convertContentList = results;
      replaceIndex = replaceIndex + 3;
    } else {
      results.push(convertContentList[replaceIndex]);
      replaceIndex = replaceIndex + 1;
    }
  });

  return results;
};

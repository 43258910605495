import { useCallback, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Stack } from "@mui/material";
import { getLabel } from "language";
import { ConfirmDialog, InfiniteScroll } from "components";
import { LangConstant, SystemConstant } from "const";
import { useCallHistory } from "hooks";
import { getInteractor } from "services/local.service";
import { StorageUtil } from "utils";
import { useDispatch } from "react-redux";
import { CallingActions } from "redux-store";
import { getNSLang } from "utils/lang.utils";
import { useAlertContext } from "components/context/AlertContext";
import CallHistoryItem from "./CallHistoryItem";

const CallHistoryTab = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const { showAlert } = useAlertContext();
  const { callHistoryList, handleSetCallHistory, deleteCallHistory } = useCallHistory();

  const [selectedList, setSelectedList] = useState([]);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const handleSelect = callHistory => () => {
    let newSelectedList = [];
    const isExisted = selectedList.findIndex(item => item.id === callHistory.id) >= 0;
    if (isExisted) {
      newSelectedList = selectedList.filter(item => item.id !== callHistory.id);
    } else {
      newSelectedList = [...selectedList, callHistory];
    }

    setSelectedList(newSelectedList);
  };

  const handleConfirmDelete = () => {
    setIsOpenConfirm(true);
  };

  const handleCancelDelete = () => {
    setIsOpenConfirm(false);
    setSelectedList([]);
  };

  const handleDeleteCallHistory = () => {
    const deleteRoomIds = selectedList.map(item => item.roomId);
    const updateCallList = deleteRoomIds.map(item => ({
      updateValues: {
        state: SystemConstant.STATE.inactive,
        modified: Date.now(),
      },
      condition: {
        room_id: item,
      },
    }));
    getInteractor(prefixKey).LocalCallHistoryService.updateListByCondition(updateCallList);
    deleteCallHistory(deleteRoomIds);
    // update to server
    dispatch(
      CallingActions.updateCallHistory(
        {
          room_ids: deleteRoomIds,
          state: SystemConstant.STATE.inactive,
        },
        prefixKey,
      ),
    );
    showAlert({ content: getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_DELETE_CALL_HISTORY_SUCCESS") });
    setSelectedList([]);
    setIsOpenConfirm(false);
  };

  const handleSelectAll = () => {
    setSelectedList(callHistoryList);
  };

  const handleLoadingMore = () => {
    handleSetCallHistory(prefixKey);
  };

  const handleCheckSelected = useCallback(
    selectedItem => selectedList.findIndex(item => item.id === selectedItem.id) >= 0,
    [selectedList],
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" p="0px 16px">
        <Button variant="text" className={classes.textBtn} onClick={handleSelectAll}>
          {getLabel("TXT_SELECT_ALL")}
        </Button>
        <Button
          variant="text"
          className={classes.textBtn}
          disabled={selectedList.length === 0}
          onClick={handleConfirmDelete}
        >
          {getLabel("FM_DELETE", { number: selectedList.length })}
        </Button>
      </Stack>
      <InfiniteScroll className={classes.scrollBox} onScrollToBottom={handleLoadingMore}>
        {callHistoryList.map(item => (
          <CallHistoryItem
            key={item.id}
            callHistory={item}
            onSelect={handleSelect(item)}
            checked={handleCheckSelected(item)}
          />
        ))}
      </InfiniteScroll>

      {isOpenConfirm && (
        <ConfirmDialog
          open
          title={getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_DELETE_CALL_HISTORY_TITLE")}
          content={getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_DELETE_CALL_HISTORY_CONTENT")}
          submitProps={{ submitText: getLabel("TXT_DELETE"), onClick: handleDeleteCallHistory }}
          cancelProps={{ onClick: handleCancelDelete }}
        />
      )}
    </>
  );
};

export default CallHistoryTab;

const useStyles = makeStyles({
  scrollBox: {
    maxHeight: "calc(100vh - 300px)",
    overflowY: "auto",
  },

  textBtn: {
    textTransform: "unset",
  },
});

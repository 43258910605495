import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Box, CardMedia, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ImageConstant, LangConstant } from "const";
import NoData from "./NoData";
import FileBox from "../MessengerChat/ChatItem/FileBox";
import { getExistFilesByTime } from "./Media";
import clsx from "clsx";

const File = ({ data, groupId, ...otherProps }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const { className, ...others } = otherProps;

  const [dateTimeData, setDateTimeData] = useState({});

  useEffect(() => {
    setDateTimeData(getExistFilesByTime(data));
  }, [data]);

  return (
    <Box className={clsx(classes.root, className)} {...others}>
      {dateTimeData && Object.keys(dateTimeData).length > 0 ? (
        <>
          {Object.keys(dateTimeData).map((key, index) => (
            <Box key={index} className={classes.margin}>
              <Typography variant="subtitle2" className={classes.margin}>
                {key}
              </Typography>
              <Stack spacing={2}>
                {dateTimeData[key].map((dataMap, index) => (
                  <FileBox groupId={groupId} mediaData={dataMap} key={index} />
                ))}
              </Stack>
            </Box>
          ))}
        </>
      ) : (
        <NoData
          media={<CardMedia component="img" alt="cover" src={ImageConstant.NoFileImage} />}
          textOne={getLabel(LangConstant.TXT_CONTENT_NO_FILE)}
          textTwo={getLabel(LangConstant.TXT_NO_FILE)}
        />
      )}
    </Box>
  );
};

File.propTypes = {
  data: PropTypes.array,
};

File.defaultProps = {
  data: [],
};

export default memo(File);

const useStyles = makeStyles({
  root: {
    padding: 10,
  },

  margin: {
    marginBottom: 14,
  },
});

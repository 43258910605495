import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import StringFormat from "string-format";
import { Box, Fade, IconButton, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { makeStyles, useTheme } from "@mui/styles";
import { FormatConstant, LangConstant } from "const";
import { getNSLang } from "utils/lang.utils";
import { SearchBar } from "components";
import { ConversationActions, ConversationSelectors } from "redux-store";
import { getInteractor } from "services/local.service";
import { toCamel, toSnake } from "utils";

const SearchPopup = ({ anchor }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const isSearchModeRedux = useSelector(state => state.conversationRedux.isSearchMode);
  const selectedGroupId = useSelector(ConversationSelectors.getSelectedGroupId);

  const [isSearching, setIsSearching] = useState(false);
  const [searchContent, setSearchContent] = useState("");
  // displayMessageList is array of messages that contain searchingContent and the messages around it
  const [displayMessageList, setDisplayMessageList] = useState([]);
  // searchMessageIds is array message's id that contain searchingContent
  const [searchMessageIds, setSearchMessageIds] = useState([]);
  const [selectedMessageIndex, setSelectedMessageIndex] = useState();

  const resultLength = displayMessageList.length || 0;

  const onChangeInput = value => setSearchContent(value.trim());

  const onSearch = async event => {
    event.preventDefault();

    if (searchContent) {
      let exactSearchMessages = await getInteractor().LocalMessageService.searchMessage({
        keyword: searchContent,
        group_id: selectedGroupId,
      });
      exactSearchMessages = toCamel(exactSearchMessages);

      let searchMessages = await getInteractor().LocalMessageService.getMessagesAround(
        toSnake(exactSearchMessages),
        searchContent,
      );
      searchMessages = toCamel(searchMessages);
      const selectedMsgIndex = searchMessages.length - 1;
      const messageIds = exactSearchMessages.map(messageItem => messageItem.id);

      dispatch(
        ConversationActions.conversationSet({
          isSearchMode: true,
          searchingMessage: {
            selectedMessageId: messageIds[selectedMsgIndex],
            messageList: searchMessages[selectedMsgIndex] || [],
            searchValue: searchContent,
          },
        }),
      );

      setSearchMessageIds(messageIds);
      setDisplayMessageList(searchMessages);
      setSelectedMessageIndex(selectedMsgIndex);
    }
  };

  const handleGoToSearchingResult = newSelectedIndex => {
    setSelectedMessageIndex(newSelectedIndex);
    dispatch(
      ConversationActions.conversationSet({
        searchingMessage: {
          selectedMessageId: searchMessageIds[newSelectedIndex],
          messageList: displayMessageList[newSelectedIndex] || [],
          searchValue: searchContent,
        },
      }),
    );
  };

  const handleClickUpButton = () => {
    handleGoToSearchingResult(selectedMessageIndex > 0 ? selectedMessageIndex - 1 : 0);
  };

  const handleClickDownButton = () => {
    handleGoToSearchingResult(
      resultLength - selectedMessageIndex > 1 ? selectedMessageIndex + 1 : selectedMessageIndex,
    );
  };

  const handleResetSearching = () => {
    setSearchContent("");
    setSelectedMessageIndex();
    setSearchMessageIds([]);
    setDisplayMessageList([]);
    dispatch(
      ConversationActions.conversationSet({
        isSearchMode: false,
        searchingMessage: {
          selectedMessageId: null,
          messageList: [],
          searchValue: "",
        },
      }),
    );
  };

  useEffect(() => {
    if (anchor) {
      const popupElement = document.getElementById(SEARCH_POPUP_ID);
      if (popupElement) popupElement.style.top = anchor.offsetHeight + "px";
    }
  }, [anchor]);

  useEffect(() => {
    if (isSearchModeRedux !== isSearching) setIsSearching(isSearchModeRedux);

    if (!isSearchModeRedux) {
      handleResetSearching();
    }
  }, [isSearchModeRedux]);

  if (!isSearching) return <Box className={classes.root} id={SEARCH_POPUP_ID} />;

  return (
    <Box className={classes.root} id={SEARCH_POPUP_ID}>
      <Fade in={isSearching} timeout={theme.transitions.duration.shortest}>
        <Box component="form" onSubmit={onSearch} className={classes.form}>
          <Box className={classes.inputWrapper}>
            <SearchBar
              disableDebounce={true}
              onChange={onChangeInput}
              classes={{ root: classes.inputRoot, input: classes.inputEl }}
            />
            {selectedMessageIndex >= 0 && (
              <Typography className={clsx(classes.result, "regular-md-txt")}>
                {StringFormat(FormatConstant.FM_SEARCH_RESULT, selectedMessageIndex + 1, resultLength)}
              </Typography>
            )}
          </Box>
          <Box className={classes.btnWrapper}>
            <IconButton
              onClick={handleClickUpButton}
              className={clsx(classes.disabledIcon, {
                [classes.activeIcon]: resultLength > 1 && selectedMessageIndex > 0,
              })}
            >
              <KeyboardArrowUp />
            </IconButton>
            <IconButton
              onClick={handleClickDownButton}
              className={clsx(classes.disabledIcon, {
                [classes.activeIcon]: resultLength > 1 && selectedMessageIndex < resultLength - 1,
              })}
            >
              <KeyboardArrowDown />
            </IconButton>
          </Box>
          <Typography className={classes.closeBtn} onClick={handleResetSearching}>
            {getNSLang(LangConstant.NS_COMMON, LangConstant.TXT_CLOSE)}
          </Typography>
        </Box>
      </Fade>
    </Box>
  );
};

export default SearchPopup;

SearchPopup.propTypes = {};

SearchPopup.defaultProps = {};

const SEARCH_POPUP_ID = "conversation-search-popup-id";

const useStyles = makeStyles(theme => ({
  root: {
    width: "calc(100% - 50px)",
    position: "absolute",
    left: 25,
    zIndex: theme.zIndex.appBar,
  },

  form: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: theme.palette.white,
    padding: 12,
    borderRadius: 10,
    border: "2px solid #008FE8",
  },

  inputWrapper: {
    width: "100%",
    display: "flex",
    borderRadius: 10,
    paddingRight: 14,
    alignItems: "center",
    backgroundColor: theme.palette.grey[100],
    marginRight: 20,
  },

  inputRoot: {
    height: 40,
    backgroundColor: "transparent",
  },

  result: {
    height: 40,
    display: "flex",
    alignItems: "center",
    color: theme.palette.grey[200],
    flexShrink: 0,
  },

  btnWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
    marginRight: 20,
  },

  disabledIcon: {
    color: theme.palette.grey[200],
    pointerEvents: "none",
  },

  activeIcon: {
    color: theme.palette.primary.main,
    pointerEvents: "auto",
  },

  closeBtn: {
    flexShrink: 0,
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: 15,
    fontWeight: 700,
    color: theme.palette.black,
    cursor: "pointer",
    marginRight: 20,
    userSelect: "none",
  },
}));

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box, CardMedia, Typography } from "@mui/material";
import { toCamel, toSnake } from "utils";
import { ImageConstant, LangConstant, SystemConstant } from "const";
import { AppDrawer, InfiniteScroll } from "components";
import { getInteractor } from "services/local.service";
import { ConversationActions, SystemActions, SystemSelectors } from "redux-store";
import NotificationInviteItem from "./NotificationInviteItem";
import NotificationTextItem from "./NotificationTextItem";
import NotificationOwnerItem from "./NotificationOwnerItem";
import NotificationOTPItem from "./NotificationOTPItem";
import { getCommonLang } from "utils/lang.utils";
import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";
import { getSavedServer } from "utils/branch.utils";

const NotificationDrawer = ({ onClose, ...otherProps }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const dispatch = useDispatch();

  const newNotification = useSelector(SystemSelectors.getNewNotification);

  const [displayNotices, setDisplayNotices] = useState([]);
  const [allList, setAllList] = useState([]);
  const [limit, setLimit] = useState(SystemConstant.LIMIT_PAGING_DEFAULT);

  const handleScrollToBottom = () => {
    if (limit <= allList.length - SystemConstant.LIMIT_PAGING_DEFAULT)
      setLimit(limit + SystemConstant.LIMIT_PAGING_DEFAULT);
    else setLimit(allList.length);
  };

  const convertInviteNotification = (inviteNoticeList = [], newInvitation = null) => {
    if (newInvitation) inviteNoticeList = [...inviteNoticeList, newInvitation];
    const maxedByBranchId = {};
    inviteNoticeList.forEach(item => {
      if (!maxedByBranchId[item.branchId] || item.created > maxedByBranchId[item.branchId].created) {
        maxedByBranchId[item.branchId] = item;
      }
    });
    if (Object.keys(maxedByBranchId).length > 0) return Object.values(maxedByBranchId);
    return [];
  };

  useEffect(() => {
    const savedServer = getSavedServer();
    getInteractor()
      .LocalNotificationService.getNormalNotification(savedServer.id)
      .then(recordList => {
        const normalNotification = toCamel(recordList);
        if (!isEqual(normalNotification, allList)) setAllList(normalNotification);
      });
  }, []);

  useEffect(() => {
    // If new invitation
    if (newNotification && newNotification.branchId && newNotification.type === SystemConstant.NotifyType.INVITE) {
      const notificationList = displayNotices.filter(item => item.type !== SystemConstant.NotifyType.INVITE);
      const tmpInviteNotices = displayNotices.filter(item => item.type === SystemConstant.NotifyType.INVITE) || [];
      const invitationNotice = convertInviteNotification(tmpInviteNotices, newNotification);
      setDisplayNotices(invitationNotice.concat(notificationList));
      dispatch(
        SystemActions.systemSet({
          newNotification: null,
        }),
      );
    }
  }, [newNotification]);

  useEffect(() => {
    const savedServer = getSavedServer();
    const tmpNotificationList = allList.slice(0, limit);
    let notificationList = tmpNotificationList.filter(item => item.type !== SystemConstant.NotifyType.INVITE);

    if (savedServer.type === SystemConstant.SERVER_TYPE.server) {
      const tmpRequestList = tmpNotificationList.filter(item => item.type === SystemConstant.NotifyType.INVITE);
      const invitationList = convertInviteNotification(tmpRequestList);
      notificationList = notificationList.concat(invitationList);
    }

    notificationList = orderBy(notificationList, ["created"], ["desc"]);
    setDisplayNotices(notificationList);

    const notSeen = tmpNotificationList.filter(
      noticeItem => noticeItem.status !== SystemConstant.NOTIFICATION_STATUS.read,
    );

    if (notSeen.length > 0) {
      getInteractor().LocalNotificationService.save(
        notSeen.map(item =>
          toSnake({
            ...item,
            status: SystemConstant.NOTIFICATION_STATUS.read,
          }),
        ),
      );
      dispatch(
        ConversationActions.updateMessageStatus({
          notifyIds: notSeen.map(noticeItem => noticeItem.id),
          status: SystemConstant.NOTIFICATION_STATUS.read,
        }),
      );
    }
  }, [allList, limit]);

  return (
    <AppDrawer title={getCommonLang("TXT_NOTIFICATION")} onClose={onClose} {...otherProps}>
      <InfiniteScroll className={classes.chatList} onScroll={handleScrollToBottom}>
        {displayNotices.length === 0 ? (
          <Box className={classes.emptyChatList}>
            <CardMedia component="img" src={ImageConstant.WelcomeImage} className={classes.emptyImage} />
            <Typography className={classes.emptyMessage}>{getLabel(LangConstant.TXT_NO_NOTIFICATION)}</Typography>
          </Box>
        ) : (
          <NotificationList notificationList={displayNotices} onClose={onClose} />
        )}
      </InfiniteScroll>
    </AppDrawer>
  );
};

export default NotificationDrawer;

const NotificationList = ({ notificationList, onClose }) => {
  if (!Array.isArray(notificationList) || notificationList.length === 0) return null;
  return notificationList.map(notice => (
    <Box key={notice.id}>
      {notice.type === SystemConstant.NotifyType.INVITE && <NotificationInviteItem data={notice} onAccept={onClose} />}

      {notice.type === SystemConstant.NotifyType.NOTIFICATION && <NotificationTextItem data={notice} />}

      {notice.type === SystemConstant.NotifyType.OWNER_NOTIFICATION && <NotificationOwnerItem data={notice} />}

      {notice.type === SystemConstant.NotifyType.SMARTOTP && <NotificationOTPItem data={notice} />}
    </Box>
  ));
};

const useStyles = makeStyles(theme => ({
  chatList: {
    height: "100%",
    overflow: "auto",
  },

  emptyChatList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "0 56px",
    borderTop: "0.5px solid " + theme.palette.divider,
  },

  emptyImage: {
    padding: "0 14px",
  },

  emptyMessage: {
    fontSize: 15,
    fontWeight: 600,
    marginTop: 16,
    lineHeight: "20px",
  },
}));

import React, { useState, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ConversationAction, { ConversationActions, ConversationSelectors } from "redux-store/conversation.redux";
import CallingAction from "redux-store/calling.redux";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import {
  Call,
  VideoCall,
  PersonAdd,
  Notifications,
  NotificationsOff,
  PermMedia,
  Search,
  InsertPhoto,
  GroupRemove,
  Logout,
  RemoveCircle,
  Videocam,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { AvatarGroup, ConfirmDialog, AppDrawer, HandleUploadingInput } from "components";
import Gallery from "../Gallery";
import ButtonGroupInfo from "./ButtonGroupInfo";
import GroupMemberList from "../GroupMemberList";
import PopupGroupMember from "./GroupMember/PopupGroupMember";
import ChooseAdminDrawer from "./GroupMember/ChooseAdminDrawer";
import DialogGroupMember from "./DialogGroupMember";
import GroupMemberRemove from "../GroupMemberList/GroupMemberRemove";
import AddingGroupMembers from "../GroupMemberList/AddingGroupMembers";
import GroupInfoAction, { GroupInfoActions, GroupInfoSelectors } from "redux-store/group-info.redux";
import { showConfirmLeaveGroup } from "utils/view.utils";
import InitGroupCallPopup from "../MessengerChat/InitGroupCallPopup";
import { isGroupOrChannelType } from "pages/Call";
import DialogConfirmLeave from "../AddingContact/DialogConfirmLeave";
import { useAccount, useBlockedAccountStatus } from "hooks";
import { getInteractor } from "services/local.service";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { AccountActions, AccountSelectors, BranchSelectors, ContactActions } from "redux-store";
import { StorageUtil, toCamel, uuid } from "utils";
import { isLoginBranch } from "utils/auth.utils";
import { getSavedServer } from "utils/branch.utils";
import { DeleteIcon } from "components/icons";
import { useAlertContext } from "components/context/AlertContext";

const GroupInfoDrawer = ({ onClose, open, data, isAdminGroup, isInactive }) => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const isPersonal = data.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;
  const isChannelGroup = data.groupType === SystemConstant.GROUP_CHAT_TYPE.channel;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const { currentAccount } = useAccount();
  const { isBlocked, isBlockedAccount } = useBlockedAccountStatus();
  const { showAlert } = useAlertContext();

  const isBranchServer = useSelector(BranchSelectors.isBranchServer);
  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);
  const serverOptions = useSelector(BranchSelectors.getServerOptions);

  const selectedId = useSelector(ConversationSelectors.getSelectedGroupId);
  const blockedAccountId = useSelector(AccountSelectors.getBlockAccountId);
  const unfriendContactId = useSelector(state => state.contactRedux.unfriendContactId);
  const isInCall = useSelector(state => state.callingRedux.isInCall);
  const hasInternet = useSelector(state => state.profileRedux.hasInternet);
  const updatingGroupData = useSelector(GroupInfoSelectors.getUpdatingGroup);

  const [isContact, setIsContact] = useState(false);
  const [isOpenGallery, setIsOpenGallery] = useState(false);
  const [isChangeGroupName, setIsChangeGroupName] = useState(false);
  const [isConfirmLeave, setIsConfirmLeave] = useState(false);
  const [isChooseAdmin, setIsChooseAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [titleGroup, setTitleGroup] = useState();
  const [isOpenAdmin, setIsOpenAdmin] = useState(false);
  const [isConfirmDialog, setIsConfirmDialog] = useState(false);
  const [isRemoveMember, setIsRemoveMember] = useState(false);
  const [isOpenAddMember, setIsOpenAddMember] = useState(false);
  const [isShowInitCall, setIsShowInitCall] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isDeleteGroup, setIsDeleteGroup] = useState(false);

  const onBlockUser = () => {
    let other = data?.groupMembers?.find(s => s.id !== accountId);
    if (other) {
      // TODO: Need to refactor - other should not be undefined/ null
      dispatch(AccountActions.blockUser({ groupId: data.id, blockedAccountId: other.id }));
    }
    onClose();
  };

  const onUnblockUser = () => {
    let other = data?.groupMembers?.find(s => s.id !== accountId);
    if (other) {
      // TODO: Need to refactor - other should not be undefined/ null
      dispatch(AccountActions.unblockUser({ groupId: data.id, blockedAccountId: other.id }));
    }
    onClose();
  };

  const onClickSetAdmin = () => {
    setIsAdmin(true);
    setIsChooseAdmin(false);
    setIsConfirmLeave(false);
  };

  const onChangeGroupName = newGroupName => {
    dispatch(
      GroupInfoAction.updateGroup({
        groupId: selectedId,
        groupName: newGroupName,
        name: JSON.stringify({ name: newGroupName }),
      }),
    );
    setIsChangeGroupName(false);
  };

  const onClickSearchButton = () => {
    dispatch(
      ConversationActions.conversationSet({
        isSearchMode: true,
      }),
    );
    onClose();
  };

  const handleChangeGroupPhoto = files => {
    let file = files[0];
    dispatch(GroupInfoAction.changeGroupPhoto({ upload: file, groupId: data.id }));
  };

  const addMember = () => {
    setIsOpenAddMember(true);
  };

  const createBrowserWindow = () => {
    const calleeId = isGroupOrChannelType(data.groupType) ? accountId : data.groupMembers[0].id;
    dispatch(
      CallingAction.callingSet({
        isOpenCallingDialog: AppConstant.CALLING_STATUS.checking,
        callingGroupDetail: toCamel({ ...data, room_id: uuid() }),
      }),
    );
    dispatch(CallingAction.onCallCheck({ accountId: calleeId }));
  };

  const onCallVideo = () => {
    const calleeId = isGroupOrChannelType(data.groupType) ? accountId : data.groupMembers[0].id;
    dispatch(
      CallingAction.callingSet({
        isOpenCallingDialog: AppConstant.CALLING_STATUS.checking,
        callingGroupDetail: toCamel({ ...data, room_id: uuid() }),
        isVideoCall: true,
      }),
    );
    dispatch(CallingAction.onCallCheck({ accountId: calleeId }));
  };

  const handleNotificationSetting = () => {
    dispatch(
      GroupInfoActions.muteGroupNotification({
        groupId: data.id,
        status: isMuted ? SystemConstant.STATE.active : SystemConstant.STATE.inactive,
      }),
    );
    setIsMuted(!isMuted);
  };

  const checkRemoveGroup = () => {
    showConfirmLeaveGroup(data, setIsConfirmLeave, setIsChooseAdmin);
  };

  const onCloseGroupInfo = () => {
    setIsConfirmLeave(false);
    onClose();
  };

  const onClosePopupInitCall = () => {
    setIsShowInitCall(false);
  };

  const onInitCall = () => {
    // const calleeId = isGroupOrChannelType(data.groupType) ? accountId : data.groupMembers[0].id;
    // dispatch(CallingAction.onCallCheck({ accountId: calleeId }));
    setIsShowInitCall(true);
  };

  const onLeave = () => {
    dispatch(
      ConversationAction.sendMessage({
        groupId: data.id,
        sendType: SystemConstant.SEND_TYPE.leaveGroup,
        content: accountId,
        parentId: null,
        deviceList: [],
        branchId: selectedBranch?.id,
        mentionIdsArr: [],
        threadId: null,
        removingId: accountId,
      }),
    );

    setIsConfirmLeave(false);
    onClose();
  };

  const handleGroupInfo = data => {
    const groupMembers = data.groupMembers || [];
    const isNotValidCallingPeople = groupMembers.length > serverOptions.meetMaxCallPerson;

    handleMuteNoticeStatus();

    setTitleGroup(data.groupName);
    setIsDisable(isNotValidCallingPeople);
    setIsContact(Boolean(data.isContact));
  };

  const addNewContact = () => {
    let other = data?.groupMembers?.find(s => s.id !== accountId);
    if (other) {
      // TODO: Need to refactor - other should not be undefined/ null
      dispatch(
        ContactActions.addNewContact({
          phoneNumber: other.phone,
          contactName: other.accountName,
        }),
      );
      //TODO using refresh when success
      setIsContact(true);
    }
  };

  const handleMuteNoticeStatus = () =>
    getInteractor()
      .LocalGroupSettingService.isMutedNotify(data.id)
      .then(isMutedGroup => setIsMuted(Boolean(isMutedGroup)));

  const handleDelete = () => {
    dispatch(
      GroupInfoActions.deleteGroup({
        groupId: data.id,
        all: 1,
      }),
    );
    setIsDeleteGroup(false);
    onClose();
  };

  const handleClickDeleteAll = () => {
    const lastCallHistory = getInteractor().LocalCallHistoryService.getLastByGroupId(data.id);
    if (lastCallHistory && lastCallHistory.status === SystemConstant.CALL_HISTORY_STATUS.calling) {
      showAlert({ content: getLabel("TXT_BLOCK_DELETE_GROUP"), alertProps: { severity: "error" } });
    } else {
      setIsDeleteGroup(true);
    }
  };

  useEffect(() => {
    if (false === isLoginBranch(selectedBranch)) return;
    if (data && data.id) {
      handleGroupInfo(data);
    }
  }, [data, selectedBranch, currentAccount]);

  useEffect(() => {
    if (blockedAccountId) {
      setIsConfirmDialog(false);
    }
  }, [blockedAccountId]);

  useEffect(() => {
    if (
      data.id &&
      data.groupType === SystemConstant.GROUP_CHAT_TYPE.personal &&
      unfriendContactId === data.groupMembers[0]?.id
    ) {
      setIsContact(false);
    }
  }, [unfriendContactId]);

  useEffect(() => {
    if (updatingGroupData && data && updatingGroupData.id === data.id && updatingGroupData.status >= 0) {
      handleMuteNoticeStatus();
    }
  }, [updatingGroupData]);

  const isShowAddingIcon = !isBranchServer && !isContact;

  return (
    <AppDrawer open={open} onClose={onClose} anchor="right" hideBackdrop={false}>
      <Box className={classes.boxAvatar}>
        <Box className={classes.chatItemAvatar}>
          <AvatarGroup
            group={{
              groupType: data.groupType,
              groupName: data.groupName,
              privateF: data.privateF,
            }}
            avatarId={data.avatarId}
          />
        </Box>
        <Typography>{titleGroup}</Typography>
      </Box>
      <Box className={classes.boxBtnHeader}>
        <Grid className={classes.gridGroupBtn} container>
          {isGroupOrChannelType(data?.groupType) ? (
            <CustomGrid isPersonal={isPersonal} className={classes.gridBtn} item>
              <IconButton
                className={classes.iconBtn}
                onClick={onInitCall}
                disabled={isDisable || isInCall || !hasInternet}
              >
                <Videocam />
              </IconButton>
              <Typography variant="body2">{getLabel(LangConstant.TXT_CONFERENCE_CALL)}</Typography>
            </CustomGrid>
          ) : (
            false === isBlocked && (
              <>
                {isShowAddingIcon && (
                  <CustomGrid isPersonal={isPersonal} className={classes.gridBtn} onClick={addNewContact} item>
                    <IconButton className={classes.iconBtn}>
                      <PersonAdd />
                    </IconButton>
                    <Typography variant="body2">{getLabel(LangConstant.TXT_ADD)}</Typography>
                  </CustomGrid>
                )}
                <CustomGrid isPersonal={isPersonal} className={classes.gridBtn} item>
                  <IconButton
                    className={classes.iconBtn}
                    onClick={createBrowserWindow}
                    disabled={isInCall || !hasInternet || isInactive}
                  >
                    <Call />
                  </IconButton>
                  <Typography variant="body2">{getLabel(LangConstant.TXT_VOICE_CALL)}</Typography>
                </CustomGrid>
                <CustomGrid isPersonal={isPersonal} className={classes.gridBtn} item>
                  <IconButton
                    className={classes.iconBtn}
                    onClick={onCallVideo}
                    disabled={isInCall || !hasInternet || isInactive}
                  >
                    <VideoCall />
                  </IconButton>
                  <Typography variant="body2">{getLabel(LangConstant.TXT_VIDEO_CALL)}</Typography>
                </CustomGrid>
              </>
            )
          )}
          {!isPersonal && isAdminGroup && (
            <CustomGrid isPersonal={isPersonal} className={classes.gridBtn} item onClick={addMember}>
              <IconButton className={classes.iconBtn}>
                <PersonAdd />
              </IconButton>
              <Typography variant="body2">{getLabel(LangConstant.TXT_ADD)}</Typography>
            </CustomGrid>
          )}
          <CustomGrid isPersonal={isPersonal} className={classes.gridBtn} item onClick={handleNotificationSetting}>
            <IconButton className={classes.iconBtn}>{isMuted ? <NotificationsOff /> : <Notifications />}</IconButton>
            <Typography variant="body2">{getCommonLang("TXT_NOTIFICATION")}</Typography>
          </CustomGrid>
        </Grid>
      </Box>
      <Box className={classes.boxGroupInfo}>
        {!isPersonal && (
          <>
            <ButtonGroupInfo
              name={getLabel(isChannelGroup ? "TXT_SEE_CHANNEL_MEMBERS" : "TXT_SEE_GROUP_MEMBERS")}
              onClick={() => setIsOpenAdmin(true)}
            />
            {isAdminGroup && (
              <ButtonGroupInfo
                name={getLabel(isChannelGroup ? "TXT_CHANGE_NAME_CHANNEL" : "TXT_CHANGE_NAME_GROUP")}
                onClick={() => setIsChangeGroupName(true)}
              />
            )}
          </>
        )}
        <Typography className={clsx(classes.typographyGroupInfo, "semiBold-sm-txt")}>
          {getLabel(
            isPersonal
              ? LangConstant.TXT_MORE_ACTIONS
              : isChannelGroup
              ? LangConstant.TXT_CHANNEL_INFO
              : LangConstant.TXT_GROUP_INFO,
          )}
        </Typography>
        <ButtonGroupInfo
          name={getLabel(LangConstant.TXT_GALLERY)}
          icon={<PermMedia className={classes.iconGroupInfoBtn} />}
          onClick={() => setIsOpenGallery(true)}
        />
        <ButtonGroupInfo
          name={getLabel(LangConstant.TXT_SEARCH_IN_CONVERSATION)}
          icon={<Search className={classes.iconGroupInfoBtn} />}
          onClick={onClickSearchButton}
        />
        {!isChannelGroup && isAdminGroup && (
          <Box>
            <HandleUploadingInput
              accept={AppConstant.ACCEPTABLE_IMAGE_TYPES}
              className="hidden"
              id="photo_group"
              type="file"
              onChange={handleChangeGroupPhoto}
            />
            <label htmlFor="photo_group">
              <Box className={classes.boxInput}>
                <Typography variant="body1">{getLabel(LangConstant.TXT_CHANGE_GROUP_PHOTO)}</Typography>
                <Box className={classes.boxInsertPhoto}>
                  <InsertPhoto className={classes.iconGroupInfoBtn} />
                </Box>
              </Box>
            </label>
          </Box>
        )}
        <Typography className={clsx(classes.typographyGroupInfo, "semiBold-sm-txt")}>
          {getLabel(isPersonal ? LangConstant.TXT_PERSONAL_PRIVACY : LangConstant.TXT_PRIVACY)}
        </Typography>
        {isPersonal ? (
          getSavedServer()?.type === SystemConstant.SERVER_TYPE.server && (
            <ButtonGroupInfo
              onClick={() => {
                isBlockedAccount ? onUnblockUser() : setIsConfirmDialog(true);
              }}
              name={getLabel(isBlockedAccount ? LangConstant.TXT_UN_BLOCK_USER : LangConstant.TXT_BLOCK_USER)}
              icon={<RemoveCircle className={classes.iconGroupInfoBtn} />}
            />
          )
        ) : (
          <>
            {isAdminGroup && (
              <>
                <ButtonGroupInfo
                  onClick={() => setIsRemoveMember(true)}
                  name={getLabel("TXT_REMOVE_A_MEMBER")}
                  icon={<GroupRemove className={classes.iconGroupInfoBtn} />}
                />
                <ButtonGroupInfo
                  onClick={handleClickDeleteAll}
                  name={getNSLang(
                    LangConstant.NS_HOME_CONVERSATION,
                    data.groupType === SystemConstant.GROUP_CHAT_TYPE.group ? "TXT_DELETE_GROUP" : "TXT_DELETE_CHANNEL",
                  )}
                  icon={<DeleteIcon className={classes.iconGroupInfoBtn} />}
                />
              </>
            )}
            <ButtonGroupInfo
              onClick={checkRemoveGroup}
              name={
                isChannelGroup
                  ? getLabel(LangConstant.TXT_LEAVE_CHANNEL_INFO)
                  : getLabel(LangConstant.TXT_LEAVE_GROUP_INFO)
              }
              icon={<Logout className={classes.iconGroupInfoBtn} />}
            />
          </>
        )}
      </Box>

      <Gallery open={isOpenGallery} groupId={selectedId} onClose={() => setIsOpenGallery(false)} />

      <PopupGroupMember
        open={isChangeGroupName}
        onClose={() => setIsChangeGroupName(false)}
        onSubmit={onChangeGroupName}
        title={
          isChannelGroup ? getLabel(LangConstant.TXT_CHANGE_NAME_CHANNEL) : getLabel(LangConstant.TXT_CHANGE_NAME_GROUP)
        }
        placeholder={
          isChannelGroup
            ? getLabel(LangConstant.TXT_PLACEHODLER_CHANGE_CHANNEL_NAME)
            : getLabel(LangConstant.TXT_CHANGE_GROUP_CHANGE_NAME)
        }
      />

      <DialogConfirmLeave
        open={isConfirmLeave}
        onClose={onCloseGroupInfo}
        title={isChannelGroup ? getLabel(LangConstant.TXT_LEAVE_CHANNEL) : getLabel(LangConstant.TXT_LEAVE_GROUP)}
        content={getLabel(LangConstant.TXT_CONTENT_BUTTON_GROUP)}
        submitProps={{
          submitText: isChannelGroup
            ? getLabel(LangConstant.TXT_LEAVE_CHANNEL_INFO)
            : getLabel(LangConstant.TXT_LEAVE_GROUP_INFO),
          onClick: onLeave,
        }}
        cancelProps={{
          cancelText: getLabel(LangConstant.TXT_CANCEL_CHANNEL_INFO),
          onClick: onCloseGroupInfo,
        }}
      />

      <DialogGroupMember
        open={isChooseAdmin}
        onClickSetAdmin={onClickSetAdmin}
        groupDetail={data}
        onClose={() => {
          setIsChooseAdmin(false);
        }}
        subTitle={
          isChannelGroup
            ? getLabel(LangConstant.TXT_SUBTITLE_LEAVE_CHANNEL)
            : getLabel(LangConstant.TXT_DES_BUTTON_CONFIRM)
        }
        confirmButtonText={
          isChannelGroup
            ? getLabel(LangConstant.TXT_LEAVE_CHANNEL)
            : getLabel(LangConstant.TXT_BUTTON_LEAVE_GROUP_SET_ADMIN)
        }
      />

      <ChooseAdminDrawer
        dataAdmin={data.groupMembers}
        onClose={() => {
          onCloseGroupInfo();
          setIsAdmin(false);
        }}
        open={isAdmin}
        groupId={data.id}
        groupDetail={data}
      />

      {/* Confirm block personal */}
      {isConfirmDialog && (
        <ConfirmDialog
          open
          title={getLabel("TXT_BLOCK_AND_CALL")}
          content={getLabel("FM_CONFIRM_BLOCK_DETAIL", { groupName: titleGroup })}
          submitProps={{
            submitText: getCommonLang("TXT_CONFIRM"),
            onClick: onBlockUser,
            className: classes.submitPropsConfirmDialog,
          }}
          cancelProps={{
            onClick: () => setIsConfirmDialog(false),
            className: classes.cancelPropsConfirmDialog,
          }}
        />
      )}

      {/* Open choose remove member drawer */}
      <GroupMemberRemove
        data={data}
        open={isRemoveMember}
        onClose={() => setIsRemoveMember(false)}
        title={getLabel("TXT_REMOVE_A_MEMBER")}
      />

      <GroupMemberList
        data={data}
        open={isOpenAdmin}
        onClose={() => setIsOpenAdmin(false)}
        onCloseParent={onClose}
        isAdminGroup={isAdminGroup}
      />

      {isOpenAddMember && (
        <AddingGroupMembers groupId={data.id} open={true} onClose={() => setIsOpenAddMember(false)} />
      )}

      {isShowInitCall && <InitGroupCallPopup open={isShowInitCall} onClose={onClosePopupInitCall} data={data} />}

      {isDeleteGroup && (
        <ConfirmDialog
          open
          title={getLabel(isChannelGroup ? "TXT_DELETE_CHANNEL" : "TXT_DELETE_GROUP")}
          content={getLabel(isChannelGroup ? "TXT_DELETE_CHANNEL_CONFIRM" : "TXT_DELETE_GROUP_CONFIRM")}
          submitProps={{
            submitText: getCommonLang("TXT_CONFIRM"),
            onClick: handleDelete,
          }}
          cancelProps={{ onClick: () => setIsDeleteGroup(false) }}
        />
      )}
    </AppDrawer>
  );
};

GroupInfoDrawer.propTypes = {
  onClose: PropTypes.func,
};

GroupInfoDrawer.defaultProps = {
  onClose: () => {},
};

const CustomGrid = ({ isPersonal, children, ...props }) => (
  <Grid {...props} xs={isPersonal ? 4 : 3}>
    {children}
  </Grid>
);

export default memo(GroupInfoDrawer);

const useStyles = makeStyles(theme => ({
  boxBtnHeader: {
    paddingTop: 10,
    marginBottom: 20,
  },

  boxAvatar: {
    textAlign: "-webkit-center",
    padding: "0px 16px",
  },

  chatItemAvatar: {
    marginBottom: 10,
    width: 50,
    height: 50,
  },

  gridBtn: {
    textAlign: "center",
  },

  gridGroupBtn: {
    padding: "0 10px",
    display: "flex",
    justifyContent: "center",
  },

  boxGroupInfo: {
    padding: "0 7px",
    display: "inline-grid",
    width: "100%",
  },

  btnGroupInfo: {
    textTransform: "none",
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    height: 50,
    alignItems: "center",
  },

  typographyGroupInfo: {
    color: theme.palette.text.secondary,
    padding: 10,
  },

  iconBtn: {
    backgroundColor: theme.palette.grey[100],
    marginBottom: 4,
  },

  iconGroupInfoBtn: {
    width: 18,
    height: 18,
  },

  boxInput: {
    cursor: "pointer",
    textTransform: "none",
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
  },

  boxInsertPhoto: {
    width: 30,
    height: 30,
    backgroundColor: "#C4C4C4",
    color: "#010101",
    borderRadius: "50%",
    paddingTop: 7,
    display: "flex",
    justifyContent: "center",
  },

  cancelPropsConfirmDialog: {
    color: theme.palette.grey[200],
    "&:hover": {
      color: theme.palette.grey[500],
    },
  },

  submitPropsConfirmDialog: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      boxShadow: "none",
    },
  },
}));

import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AvatarGroup } from "components";
import { LangConstant } from "const";
import { useEffect, useState } from "react";
import { toCamel } from "utils";
import InfoIcon from "@mui/icons-material/Info";
import VideocamIcon from "@mui/icons-material/Videocam";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import InitGroupCallPopup from "../MessengerChat/InitGroupCallPopup";
import { useSelector } from "react-redux";
import { BranchSelectors } from "redux-store";

const ConferenceItem = ({ data }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);

  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);
  const isInCall = useSelector(state => state.callingRedux.isInCall);
  const hasInternet = useSelector(state => state.profileRedux.hasInternet);

  const [memberLength, setMemberLength] = useState();
  const [isShowInit, setIsShowInit] = useState(false);

  const maxPersonNumber = toCamel(JSON.parse(selectedBranch.options))?.meetMaxCallPerson;

  const onShowInitConference = () => {
    setIsShowInit(true);
  };

  useEffect(() => {
    if (data.id) {
      setMemberLength(data.groupMembers.length);
    }
  }, [data]);

  return (
    <>
      <Box className={classes.item}>
        <Box className={classes.groupNameAndAvatar}>
          <Box className={classes.chatItemAvatar}>
            <AvatarGroup
              group={{
                groupType: data.groupType,
                groupName: data.groupName,
                privateF: data.privateF,
              }}
              avatarId={data.avatarId}
            />
          </Box>
          <Typography className={clsx(classes.chatName, "ellipsis")}>{data.groupName}</Typography>
        </Box>

        <Box className={classes.actionButton}>
          {memberLength > maxPersonNumber && (
            <Tooltip title={getLabel(LangConstant.TXT_MEMBER_OVER_TOOLTIP)}>
              <IconButton>
                <InfoIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
          <Button
            variant="contained"
            className={clsx(classes.callButton)}
            disabled={memberLength > maxPersonNumber || isInCall || !hasInternet}
            classes={{ root: classes.rootButton, disabled: classes.disabledButton }}
            onClick={onShowInitConference}
          >
            <VideocamIcon color="white" />
          </Button>
        </Box>
      </Box>

      {isShowInit && <InitGroupCallPopup open={isShowInit} onClose={() => setIsShowInit(false)} data={data} />}
    </>
  );
};

export default ConferenceItem;

const useStyles = makeStyles(theme => ({
  groupNameAndAvatar: {
    display: "flex",
    alignItems: "center",
    width: "70%",
  },

  chatItemAvatar: {
    marginBottom: 10,
    marginRight: 10,
    minWidth: 50,
    width: 50,
    height: 50,
  },

  actionButton: {
    display: "flex",
    alignItems: "center",
  },

  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  callButton: {
    marginLeft: 10,
  },

  disabledButton: {
    backgroundColor: "#72B9E6 !important",
    color: theme.palette.white + "!important",
  },

  rootButton: {
    padding: 0,
    minWidth: 42,
    minHeight: 30,
  },

  chatName: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    marginBottom: 2,
  },
}));

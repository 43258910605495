import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { PlayArrow } from "@mui/icons-material";
import { Box, ListItemAvatar, Avatar as MuiAvatar } from "@mui/material";
import { FilePreviewIcon } from "components/icons";
import { AppConstant } from "const";
import { FileUtil, isAudio, isImage, isVideo } from "utils";
import { getFileColor } from "utils/file.utils";

const FileAvatar = ({ fileName, url, mimeType }) => {
  const filePreviewIconColor = fileName ? getFileColor(fileName) : "";

  const [fileUrl, setFileUrl] = useState();

  useEffect(() => {
    if ((isImage(mimeType) || isVideo(mimeType)) && url) {
      const getUrlTimeout = setTimeout(() => {
        FileUtil.getUrlFileByPath(url, mimeType, { width: 72 }).then(result => {
          setFileUrl(result);
        });
      }, AppConstant.DEBOUNCE_TIME);

      return () => clearTimeout(getUrlTimeout);
    }
  }, [mimeType, url]);

  return (
    <ListItemAvatar>
      <Avatar alt={`Avatar - ${fileName}`}>
        {isImage(mimeType) && fileUrl ? (
          <img src={fileUrl} alt={fileName} />
        ) : isVideo(mimeType) && fileUrl ? (
          <video src={fileUrl} />
        ) : isAudio(mimeType) ? (
          <Box className="flex-center">
            <PlayArrow fontSize="large" />
          </Box>
        ) : (
          <FilePreviewIcon color={filePreviewIconColor} />
        )}
      </Avatar>
    </ListItemAvatar>
  );
};

export default FileAvatar;

const Avatar = styled(MuiAvatar)(props => ({
  width: 48,
  height: 48,
  backgroundColor: props.theme.palette.white,
  color: props.theme.palette.grey[400],

  "& img, & video": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  "& .flex-center": {
    width: "100%",
    height: "100%",
    backgroundColor: props.theme.palette.grey[400],
    color: props.theme.palette.white,
  },
}));

/* eslint-disable no-unused-vars */
export enum PROCESS_STATUS {
  nothing = "nothing",
  synchEvent = "synchEvent",
  executeEvent = "executeEvent",
}

export enum EventChannel {
  SYSTEM, // 0
  FIREBASE, // 1
  OTHERS, // 2
}

export enum EventState {
  NEW, // 0
  RESENT, // 1
  SUCCESSES, // 2
  TIMEOUT, // 3
  FAIL, // 4
}

export enum EventType {
  BRANCH = "BRANCH",
  ACCOUNT = "ACCOUNT",
  GROUP = "GROUP",
  MESSAGE = "MESSAGE",
  MESSAGE_STATUS = "MESSAGE_STATUS",
  CALL = "CALL",
  PERSONAL = "PERSONAL",
  DEVICE = "DEVICE",
  FILE = "FILE",
  CALL_HISTORY = "CALL_HISTORY",
}

// High priority
export enum CallEventSubType {
  INVITE = "INVITE",
  ACK = "ACK",
  BYE = "BYE",
  CANCEL = "CANCEL",
  CLEAN_CALL_LEAVE = "CLEAN_CALL_LEAVE",
}

export enum EventSubType {
  NEW = "NEW",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  ADD_ACCOUNT = "ADD_ACCOUNT",
  DEL_ACCOUNT = "DEL_ACCOUNT",
  ADD_BLOCK = "ADD_BLOCK",
  DELETE_BLOCK = "DELETE_BLOCK",
  AUTO_END_CALL = "AUTO_END_CALL",
  MANUAL_END_CALL = "MANUAL_END_CALL",
  LEAVE_CALL = "LEAVE_CALL",
  KEY_UPDATE = "KEY_UPDATE",
  RECEIVED = "RECEIVED",
  SEEN = "SEEN",
  INVITE = "INVITE",
  REMOVE = "REMOVE",
  CHANGE_MASTER = "CHANGE_MASTER",
  SMART_OTP = "SMART_OTP",
  MENTIONS = "MENTIONS",
  OWNER_NOTIFICATION = "OWNER_NOTIFICATION",
  UPLOADED = "UPLOADED",
  FAILED = "FAILED",
  DELETE_CHANNEL = "DELETE_CHANNEL",
}

export enum PriorityEvent {
  LOW = "LOW",
  MIDDLE = "MIDDLE",
  HIGH = "HIGH",
}

export enum ExecuteEvent {
  FAIL = "FAILED",
  SUCCESSES = "SUCCESSES",
  RETRY = "RETRY",
}

export const CHECKING_INTERVAL_TIME = 1000;

import { AppConstant, KeyConstant, SystemConstant } from "const";
import { call, put, select } from "redux-saga/effects";
import { convertJSONObject, convertString2JSON, isJSONString, toCamel, uuid } from "utils";
import { getInteractor } from "services/local.service";
import { CallingActions, ConversationActions, ConversationSelectors, GroupInfoActions } from "redux-store";
import { saveKeysByAccountIds } from "./account-key.saga";
import { updateThread } from "./thread.saga";
import { StorageUtil } from "utils";
import { getSendType } from "utils/view.utils";
import { isEncryptionType } from "./saga.helper";
import { Mutex } from "async-mutex";
import { SystemActions } from "redux-store";

const mutexes = {};
// Save messages locally and storing encrypted messages in queue table for pending send to the server
export function* saveMsgInQueue(action) {
  const { groupId, sendType, roomId, content, branchId, callStatus } = action.data;
  const mutex = mutexes[groupId] || new Mutex();
  mutexes[groupId] = mutex;

  const release = yield mutex.acquire();
  console.log("saveMsgInQueue");
  try {
    const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();

    const branchServerId = branchId || StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

    const isValidData = content && branchServerId && groupId;
    const isCallingType = SystemConstant.ARR_CALLING_TYPES.includes(sendType);
    const isValidCalling =
      isCallingType && roomId && Object.values(SystemConstant.MESSAGE_CALL_STATUS).includes(callStatus);
    if (!isValidData || (isCallingType && !isValidCalling)) {
      return;
    }

    const groupDetail = toCamel(yield getInteractor(prefixKey).LocalGroupService.get(groupId));
    const isValidGroup = Boolean(
      groupDetail && groupDetail.id && Array.isArray(groupDetail.groupMembers) && groupDetail.groupMembers.length > 0,
    );
    if (false === isValidGroup) return;

    // Query database by group id for members account ids
    const isPersonal = groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;
    const memberIdArray = groupDetail.groupMembers.map(member => member.id) || [];
    const newSendType = getSendType(sendType, content, isPersonal);

    // Synch key by members account ids
    yield call(saveKeysByAccountIds, prefixKey, memberIdArray);

    // Handle message and save it to local
    yield call(isPersonal ? sendE2EMessage : sendE2EEMessage, prefixKey, {
      ...action.data,
      groupDetail: groupDetail,
      sendType: newSendType,
      memberIdArray: memberIdArray,
    });
  } catch (e) {
    console.log(e);
  } finally {
    release();
    console.log("release saveMsgInQueue");
  }
}

function* sendE2EEMessage(prefixKey, data) {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
  const {
    groupDetail,
    parentId,
    content,
    sendType,
    branchId,
    threadId,
    mentionIdsArr,
    memberIdArray,
    callStatus,
    roomId,
    removingId,
    currentMessage,
    option,
    forward,
  } = data;
  const messageOption = isJSONString(option || currentMessage?.options)
    ? convertString2JSON(option || currentMessage?.options, {})
    : option || currentMessage?.options || {};

  const deviceList = yield getInteractor(prefixKey).LocalDeviceService.find({
    account_id: memberIdArray,
    state: SystemConstant.STATE.active,
  });

  const hasEncryption =
    deviceList.findIndex(item => isEncryptionType(item, sendType) !== SystemConstant.ENCRYPTION_TYPE.NO_ENCRYPTION) >=
    0;
  messageOption.encryption_f = hasEncryption
    ? SystemConstant.ENCRYPTION_TYPE.NORMAL_ENCRYPTION
    : SystemConstant.ENCRYPTION_TYPE.NO_ENCRYPTION;

  let values = yield Promise.all(
    deviceList.map(async device => {
      const checkShared = await getInteractor(prefixKey).LocalCryptoService.checkMarkSenderKeyShared(
        device.account_id,
        device.id,
        groupDetail.id,
      );
      if (checkShared || device.id === deviceId) {
        return null;
      }

      return device;
    }),
  );
  values = values.filter(device => device !== null && device.account_id && device.id);

  let messageList = [];
  const localId = uuid();
  if (values.length > 0 && hasEncryption) {
    const distributionKey = yield getInteractor(prefixKey).LocalCryptoService.generateDistributionKey(
      accountId,
      deviceId,
      groupDetail.id,
    );
    messageList = yield Promise.all(
      values.map(async device => {
        const text = await getInteractor(prefixKey).LocalCryptoService.encryptE2EMessage(
          device.account_id,
          device.id,
          groupDetail.id,
          distributionKey,
        );
        if (text === null) return null;
        const mesId = deviceId === device.id ? localId : uuid();
        const mes = {
          messageId: mesId,
          sendToDeviceId: device.id,
          sendToAccountId: device.account_id,
          content: text,
          options: JSON.stringify(messageOption),
          status: SystemConstant.MESSAGE_STATUS.send,
          sendType: SystemConstant.SEND_TYPE.senderKey,
          mentions: "[]",
          callStatus: callStatus,
          roomId: roomId,
          threadId: threadId,
          removingId: removingId,
        };
        return mes;
      }),
    );
  }
  const messageDistribution = messageList.filter(message => message !== null);

  if (messageDistribution.length > 0) {
    const marks = [];
    const sourceId = uuid();
    messageDistribution.forEach(mesItem => {
      marks.push({
        group_id: groupDetail.id,
        account_id: mesItem.sendToAccountId,
        device_id: mesItem.sendToDeviceId,
      });
    });

    if (marks && marks.length > 0) {
      yield getInteractor(prefixKey).LocalSenderKeySharedService.save(marks);
    }

    const newMessage = {
      marks: marks,
      isSendingKey: true,
      groupId: groupDetail.id,
      sourceId: sourceId,
      groupType: groupDetail.groupType,
      sendType: SystemConstant.SEND_TYPE.senderKey,
      created: Date.now(),
      messages: messageDistribution,
      branchId: branchId,
      roomId: roomId,
      threadId: threadId,
      removingId: removingId,
      options: JSON.stringify(messageOption),
      messageId: localId,
    };

    yield getInteractor(prefixKey).LocalApiCallService.save([
      {
        id: uuid(),
        task: `${AppConstant.TASK_MESSAGE_SEND}`,
        original_uid: sourceId,
        query: "",
        content: JSON.stringify({ data: newMessage }),
        original_content: JSON.stringify({ data: newMessage }),
        created: new Date().getTime(),
        retry: 0,
        branch_id: branchId,
        group_id: newMessage.groupId,
      },
    ]);
    yield put(SystemActions.executeQueue());
  }

  let messageId = uuid();
  let sourceId = uuid();
  let created = Date.now();
  if (currentMessage) {
    messageId = currentMessage.id;
    sourceId = currentMessage.source_id;
    if (currentMessage.created) created = currentMessage.created;
  }

  const saveMessage = {
    account_id: accountId,
    branch_id: branchId,
    content: content,
    created: created,
    device_id: deviceId,
    group_id: groupDetail.id,
    id: messageId,
    mentions: getMentions(mentionIdsArr),
    modified: 0,
    options: JSON.stringify(messageOption),
    parent_id: parentId,
    send_type: sendType,
    sender_device_id: deviceId,
    sender_id: accountId,
    source_id: sourceId,
    state: 1,
    status: SystemConstant.MESSAGE_STATUS.read,
    thread_id: threadId,
    call_status: callStatus,
    room_id: roomId,
  };

  if (!removingId) yield removeInLocal(prefixKey, toCamel(saveMessage), groupDetail);
  yield getInteractor(prefixKey).LocalMessageService.save([saveMessage]);

  yield updateThread(prefixKey, saveMessage);

  const device = deviceList.find(s => s.id === deviceId);
  if (!device) return;

  const createdMessage = yield select(state => state.callingRedux.createdMessage);

  if (SystemConstant.ARR_CALLING_TYPES.includes(sendType) && !parentId) {
    yield put(
      CallingActions.callingSet({
        message: toCamel(saveMessage),
        createdMessage: {
          ...createdMessage,
          [groupDetail.id]: toCamel(saveMessage),
        },
      }),
    );
  }

  const text = hasEncryption
    ? yield getInteractor(prefixKey).LocalCryptoService.encryptE2EEMessage(
        device.account_id,
        device.id,
        groupDetail.id,
        content,
      )
    : content;

  if (text) {
    const mesId = device.id === deviceId ? messageId : uuid();

    const newCurrentMessage = {
      messageId: mesId,
      sendToDeviceId: device.id,
      sendToAccountId: device.account_id,
      content: text,
      options: JSON.stringify(messageOption),
      status: SystemConstant.MESSAGE_STATUS.send,
      sendType: sendType,
      mentions: getMentions(mentionIdsArr),
      callStatus: callStatus,
      roomId: roomId,
      threadId: threadId,
      removingId: removingId,
    };

    const newMessage = {
      isSendingKey: false,
      groupId: groupDetail.id,
      sourceId: sourceId,
      groupType: groupDetail.groupType,
      sendType: sendType,
      created: Date.now(),
      messages: [newCurrentMessage],
      deviceList: deviceList.map(s => s.id),
      branchId: branchId,
      parentId: parentId,
      threadId: threadId,
      roomId: roomId,
      removingId: removingId,
      options: JSON.stringify(messageOption),
      messageId: mesId,
      forward: forward,
    };

    yield getInteractor(prefixKey).LocalApiCallService.save([
      {
        id: uuid(),
        task: `${AppConstant.TASK_MESSAGE_SEND}`,
        original_uid: sourceId,
        query: "",
        content: JSON.stringify({ data: newMessage }),
        original_content: JSON.stringify({ data: newMessage }),
        created: new Date().getTime(),
        retry: 0,
        branch_id: branchId,
        group_id: newMessage.groupId,
      },
    ]);
    yield put(SystemActions.executeQueue());
  }
}

function* sendE2EMessage(prefixKey, data) {
  try {
    const branchInfo = StorageUtil.getItem(KeyConstant.KEY_BRANCH_INFO, prefixKey) || {};
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
    const {
      groupDetail,
      parentId,
      content,
      sendType,
      branchId,
      threadId,
      mentionIdsArr,
      memberIdArray,
      callStatus,
      isReceiver,
      option,
      currentMessage,
      forward,
    } = data;
    const messageContent = convertJSONObject(content);
    const roomId = messageContent?.room_id || null;
    const messageOption = isJSONString(option || currentMessage?.options)
      ? convertString2JSON(option || currentMessage?.options, {})
      : option || currentMessage?.options || {};
    if (messageOption.encryption_f !== SystemConstant.ENCRYPTION_TYPE.NO_ENCRYPTION)
      messageOption.encryption_f = SystemConstant.ENCRYPTION_TYPE.NORMAL_ENCRYPTION;

    let messageId = uuid();
    let sourceId = uuid();
    let created = Date.now();
    if (currentMessage) {
      messageId = currentMessage.id;
      sourceId = currentMessage.source_id;
      if (currentMessage.created) created = currentMessage.created;
    }

    const saveMessage = {
      account_id: accountId,
      branch_id: branchInfo?.id,
      content: content,
      created: created,
      device_id: deviceId,
      group_id: groupDetail.id,
      id: messageId,
      mentions: getMentions(mentionIdsArr),
      modified: 0,
      options: JSON.stringify(messageOption),
      parent_id: parentId,
      send_type: sendType,
      sender_device_id: deviceId,
      sender_id: accountId,
      source_id: sourceId,
      state: 1,
      status: SystemConstant.MESSAGE_STATUS.read,
      thread_id: threadId,
      call_status: callStatus,
    };

    yield removeInLocal(prefixKey, toCamel(saveMessage), groupDetail);
    yield getInteractor(prefixKey).LocalMessageService.save([saveMessage]);

    yield updateThread(prefixKey, saveMessage);

    const deviceList = yield getInteractor(prefixKey).LocalDeviceService.find({
      account_id: memberIdArray,
      state: SystemConstant.STATE.active,
    });

    console.log({ deviceList });

    const values = yield Promise.all(
      deviceList.map(async device => {
        const encryption_f = isEncryptionType(device, sendType);

        const text =
          encryption_f !== SystemConstant.ENCRYPTION_TYPE.NO_ENCRYPTION
            ? await getInteractor(prefixKey).LocalCryptoService.encryptE2EMessage(
                device.account_id,
                device.id,
                groupDetail.id,
                content,
              )
            : content;

        if (!text) return null;

        const mesId = device.id === deviceId ? messageId : uuid();

        let newCallStatus = callStatus;
        if (callStatus === SystemConstant.MESSAGE_CALL_STATUS.accept) {
          if (device.account_id === accountId && device.id !== deviceId) {
            newCallStatus = SystemConstant.MESSAGE_CALL_STATUS.inAnotherCall;
          } else if (
            (device.account_id === accountId && device.id === deviceId) ||
            isReceiver ||
            sendType === SystemConstant.SEND_TYPE.reconnect
          ) {
            newCallStatus = callStatus;
          } else {
            newCallStatus = SystemConstant.MESSAGE_CALL_STATUS.waiting;
          }
        }

        return {
          messageId: mesId,
          sendToDeviceId: device.id,
          sendToAccountId: device.account_id,
          content: text,
          options: JSON.stringify({ ...messageOption, encryption_f }),
          status: SystemConstant.MESSAGE_STATUS.send,
          sendType: sendType,
          mentions: getMentions(mentionIdsArr),
          callStatus: newCallStatus,
          roomId: roomId,
        };
      }),
    );
    const validMessages = values.filter(msg => msg !== null);
    console.log({ validMessages });

    // Skip if dont have any message
    if (validMessages.length === 0) throw Error("EMPTY_MESSAGE");

    const newMessage = {
      isSendingKey: false,
      groupId: groupDetail.id,
      sourceId: sourceId,
      groupType: groupDetail.groupType,
      sendType: sendType,
      created: Date.now(),
      messages: validMessages,
      branchId: branchId,
      parentId: parentId,
      threadId: threadId,
      roomId: roomId,
      messageId: messageId,
      forward: forward,
    };

    console.log({ newMessage, saveMessage });

    yield getInteractor(prefixKey).LocalApiCallService.save([
      {
        id: uuid(),
        task: `${AppConstant.TASK_MESSAGE_SEND}`,
        original_uid: sourceId,
        query: "",
        content: JSON.stringify({ data: newMessage }),
        original_content: JSON.stringify({ data: newMessage }),
        created: new Date().getTime(),
        retry: 0,
        branch_id: branchId,
        group_id: newMessage.groupId,
      },
    ]);
    yield put(SystemActions.executeQueue());

    const createdMessage = yield select(state => state.callingRedux.createdMessage);

    if (SystemConstant.ARR_CALLING_TYPES.includes(sendType) && !parentId) {
      yield put(
        CallingActions.callingSet({
          message: toCamel(saveMessage),
          createdMessage: {
            ...createdMessage,
            [groupDetail.id]: toCamel(saveMessage),
          },
        }),
      );
    }
  } catch (e) {
    console.trace(e);
  }
}

function* removeInLocal(prefixKey, sendingMessageLocal, groupDetail) {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

  // Delete group in local db when send message leaveGroup
  if (sendingMessageLocal.sendType === SystemConstant.SEND_TYPE.leaveGroup) {
    const savingGroup = {
      state: SystemConstant.STATE.inactive,
      modified: Date.now(),
    };
    const isPersonalConversation = groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;

    if (isPersonalConversation) {
      const options = groupDetail.options ? JSON.parse(groupDetail.options) : {};

      if (options.hidden && Array.isArray(options.hidden) && !options.hidden.includes(accountId)) {
        options.hidden.push(accountId);
      } else {
        options.hidden = [accountId];
      }
      savingGroup.options = JSON.stringify(options);
    } else {
      yield getInteractor(prefixKey).LocalAccountGroupService.removeMemberGroup(groupDetail.id, accountId);
    }

    yield getInteractor(prefixKey).LocalGroupService.update(savingGroup, { id: groupDetail.id });

    yield put(
      GroupInfoActions.groupInfoSuccess({
        deleteGroup: { groupId: groupDetail.id, modified: Date.now() },
      }),
    );

    const selectedGroupId = yield select(ConversationSelectors.getSelectedGroupId);
    if (selectedGroupId === groupDetail.id) {
      yield put(
        ConversationActions.setSelectGroupId({
          threadingId: null,
          selectedGroupId: null,
        }),
      );
    }
  }
}

const getMentions = mentionIdsArr =>
  JSON.stringify(Array.isArray(mentionIdsArr) ? mentionIdsArr : convertString2JSON(mentionIdsArr, []));

import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { GroupInfoSelectors } from "redux-store";
import { AvatarGroup } from "components";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import clsx from "clsx";
import SearchPopup from "../SearchPopup";
import { toCamel } from "utils";
import { isGroupOrChannelType } from "pages/Call";
import { useOnlineGroup } from "hooks";
import { getInteractor } from "services/local.service";
import { StorageUtil } from "utils";
import { useConversationContext } from "../ConversationContext";
import { isEqual } from "lodash";
import TitleChatAction from "./TitleChatAction";
import { isChangeGroupView } from "utils/view.utils";
import { Error } from "@mui/icons-material";
import { withDrawerLayout } from "components/hoc";
import GroupInfoDrawer from "../../GroupInfoDrawer";

const GroupInfoWithIcon = withDrawerLayout(
  GroupInfoDrawer,
  <IconButton>
    <Error color="action" />
  </IconButton>,
);

const TitleChat = () => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const classes = useStyles();
  const searchAnchorRef = useRef(null);
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const { isOnline } = useOnlineGroup();
  const {
    groupDetail: contextGroupDetail,
    store: { isInactive },
  } = useConversationContext();
  const isGroupOrChannel = isGroupOrChannelType(contextGroupDetail.groupType);
  const updatingGroupData = useSelector(GroupInfoSelectors.getUpdatingGroup);

  const [isAdminGroup, setIsAdminGroup] = useState(false);
  const [groupDetail, setGroupDetail] = useState({
    groupMembers: [],
  });

  const handleChangeGroupDetail = async groupDetail => {
    const isValidData = groupDetail && groupDetail.id;
    if (false === isValidData) return;

    getInteractor()
      .LocalAccountGroupService.find({ group_id: groupDetail.id })
      .then(accountGroupRecords => {
        const findingItem = toCamel(accountGroupRecords).find(
          item => item.accountId === accountId && item.type === SystemConstant.ROLE_OBJ.admin,
        );
        setIsAdminGroup(Boolean(findingItem));
      });
  };

  useEffect(() => {
    if (isChangeGroupView(groupDetail, updatingGroupData)) {
      const newGroupDetail = { ...groupDetail, ...updatingGroupData };
      setGroupDetail(newGroupDetail);
      handleChangeGroupDetail(newGroupDetail);
    }
  }, [updatingGroupData]);

  useEffect(() => {
    if (!isEqual(groupDetail, contextGroupDetail)) {
      setGroupDetail(contextGroupDetail);
      handleChangeGroupDetail(contextGroupDetail);
    }
  }, [contextGroupDetail]);

  return (
    <Stack className={classes.wapFlex} ref={searchAnchorRef}>
      <Stack direction="row" alignItems="center">
        <Stack direction="row" alignItems="center" flexGrow={1}>
          <Box className={classes.avatarAndStatus}>
            <AvatarGroup
              group={{
                groupType: groupDetail.groupType,
                groupName: groupDetail.groupName,
                privateF: groupDetail.privateF,
              }}
              avatarId={groupDetail.avatarId}
            />

            <Box
              className={clsx(
                classes.status,
                isOnline ? classes.onlineStatus : classes.offline,
                isGroupOrChannel && "hidden",
              )}
            />
          </Box>

          <Stack ml={1}>
            <Typography variant="subtitle2" className={clsx(classes.titleChat, "ellipsis")}>
              {groupDetail.groupName}
            </Typography>
            {isGroupOrChannel && (
              <Box className={clsx(classes.groupTypeText, "regular-sm-txt")}>
                {getLabel(
                  groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.group
                    ? LangConstant.TXT_GROUP
                    : LangConstant.TXT_CHANNEL,
                )}
              </Box>
            )}
          </Stack>
        </Stack>

        {!isInactive && <TitleChatAction groupDetail={groupDetail} />}
        <GroupInfoWithIcon data={groupDetail} isInactive={isInactive} isAdminGroup={isAdminGroup} />
      </Stack>

      {searchAnchorRef.current && <SearchPopup anchor={searchAnchorRef.current} />}
    </Stack>
  );
};

export default memo(TitleChat);

const useStyles = makeStyles(theme => ({
  wapFlex: {
    position: "relative",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 22,
  },

  avatarAndStatus: {
    display: "flex",
    position: "relative",
    width: 50,
    height: 50,
    minWidth: 50,
  },

  titleChat: {
    color: theme.palette.black,
    position: "relative",
  },

  status: {
    position: "absolute",
    width: 10,
    height: 10,
    borderRadius: "50%",
    bottom: 7,
    right: 0,
  },

  onlineStatus: {
    backgroundColor: "#2CC84A",
    border: "1px solid white",
  },

  offline: {
    backgroundColor: theme.palette.divider,
    border: `1px solid ${theme.palette.grey[300]}`,
  },

  groupTypeText: {
    color: theme.palette.grey[500],
  },
}));

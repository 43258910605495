import { useState, useEffect } from "react";
import { AppDrawer, EmptyResult, MainContent, MainLayout, MainSidebar, SearchBar } from "components";
import { Stack, List } from "@mui/material";
import { getInteractor } from "services/local.service";
import { useTranslation } from "react-i18next";
import { Apps } from "@mui/icons-material";
import { getCommonLang, getNSLang } from "utils/lang.utils";
import { FileFilter, FILTER_FILE_TYPE, TimeFilter } from "./components/FileFilter";
import { Container, FileListLoading, FileTabButton, LimitContainer, ReplyIcon } from "./components";
import { ListItemByDate } from "./components/FileList";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { StorageUtil, toSnake } from "utils";
import Scrollable from "./components/Scrollable";
import { getDisplayData } from "./file.helper";
import { SystemActions } from "redux-store";
import { useDispatch } from "react-redux";

const ManageFilePage = () => {
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const accountObjContent = getLabel(LangConstant.OBJ_ACCOUNT_ME, { returnObjects: true });
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);

  const [viewMode, setViewMode] = useState(VIEW_MODE.searching);
  const [selectedTab, setSelectedTab] = useState(FILE_TAB.all);
  const [filterCondition, setFilterCondition] = useState(DEFAULT_FILTER);
  const [searchValue, setSearchValue] = useState("");
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [fileList, setFileList] = useState({}); // List data by date
  const [displayItemTotal, setDisplayItemTotal] = useState(0);

  const handleOpenHomePage = () => {
    dispatch(
      SystemActions.systemSet({
        isShowMnFile: false,
      }),
    );
  };
  const handleChangeFilter = condition => setFilterCondition(preState => ({ ...preState, ...condition }));
  const handleSearch = keyword => setSearchValue(keyword);
  const handleFilterByTime = (startDate, endDate) => handleChangeFilter({ startDate, endDate });

  const handleSelectTabFunc = tabValue => () => {
    setSelectedTab(tabValue);
    setSearchValue("");

    switch (tabValue) {
      case FILE_TAB.all:
        handleChangeFilter(DEFAULT_FILTER);
        break;

      case FILE_TAB.mine:
        handleChangeFilter({ ...DEFAULT_FILTER, createdByAccountId: accountId, createdByMe: true });
        break;

      case FILE_TAB.other:
        handleChangeFilter({ ...DEFAULT_FILTER, createdByAccountId: accountId, createdByMe: false });
        break;

      default:
        break;
    }
  };

  const handleFilterByType = fileTypes => {
    switch (true) {
      // View all file types
      case [Object.values(FILTER_FILE_TYPE).length, 0].includes(fileTypes.length):
        handleChangeFilter({ fileType: null });
        break;

      // Select other file
      case fileTypes.includes(FILTER_FILE_TYPE.otherFile):
        handleChangeFilter({ fileType: fileTypes.concat(OTHER_FILE_TYPES) });
        break;

      default:
        handleChangeFilter({ fileType: fileTypes });
        break;
    }
  };

  const handleLoadMore = async () => {
    if (!isLoadMore) return;

    const records = await getInteractor(prefixKey).LocalFileGroupService.searchFileByCondition(
      toSnake({
        keyword: searchValue,
        ...filterCondition,
        offset: displayItemTotal,
      }),
    );
    setIsLoadMore(records.length === PAGING_SIZE);
    const result = await getDisplayData(prefixKey, records, fileList);
    setFileList(result);
    setDisplayItemTotal(displayItemTotal + records.length);
  };

  useEffect(() => {
    setViewMode(VIEW_MODE.searching);

    const getFileTimeout = setTimeout(() => {
      getInteractor(prefixKey)
        .LocalFileGroupService.searchFileByCondition(
          toSnake({
            keyword: searchValue,
            ...filterCondition,
            offset: 0,
            limit: PAGING_SIZE,
          }),
        )
        .then(listItems => {
          setViewMode(listItems.length === 0 ? VIEW_MODE.empty : VIEW_MODE.list);
          setIsLoadMore(listItems.length === PAGING_SIZE);

          getDisplayData(prefixKey, listItems).then(result => {
            setFileList(result);
            setDisplayItemTotal(listItems.length);
          });
        });
    }, AppConstant.DEBOUNCE_TIME);

    return () => clearTimeout(getFileTimeout);
  }, [prefixKey, searchValue, filterCondition]);

  return (
    <MainLayout>
      <MainSidebar>
        <AppDrawer open title={accountObjContent.manageFile} onClose={handleOpenHomePage}>
          <Stack px={2} spacing={0.5}>
            <FileTabButton
              isSelected={FILE_TAB.all === selectedTab}
              label={getCommonLang(LangConstant.TXT_ALL)}
              startIcon={<Apps />}
              onClick={handleSelectTabFunc(FILE_TAB.all)}
            />

            <FileTabButton
              isSelected={FILE_TAB.mine === selectedTab}
              label={getNSLang(LangConstant.NS_HOME_CONVERSATION, LangConstant.TXT_SENT)}
              startIcon={<ReplyIcon sx={{ transform: "scale(-1,1)" }} />}
              onClick={handleSelectTabFunc(FILE_TAB.mine)}
            />

            <FileTabButton
              isSelected={FILE_TAB.other === selectedTab}
              label={getLabel("TXT_SHARE_BY_OTHER")}
              startIcon={<ReplyIcon sx={{ transform: "scale(1,-1)" }} />}
              onClick={handleSelectTabFunc(FILE_TAB.other)}
            />
          </Stack>
        </AppDrawer>
      </MainSidebar>

      <MainContent className="align-items">
        <Container>
          <LimitContainer>
            <SearchBar value={searchValue} onChange={handleSearch} placeholder={getCommonLang("TXT_FINDING_FILE")} />
          </LimitContainer>

          <LimitContainer component={Stack} direction="row">
            <FileFilter onChangeFileType={handleFilterByType} />
            <TimeFilter onChangeTime={handleFilterByTime} />
          </LimitContainer>

          <Scrollable onBottom={handleLoadMore}>
            {viewMode === VIEW_MODE.searching && <FileListLoading />}
            {viewMode === VIEW_MODE.list && (
              <Stack component={List} gap={2} disablePadding paddingBottom={2}>
                {Object.keys(fileList).map(date => (
                  <ListItemByDate key={date} date={date} list={fileList[date]} />
                ))}
              </Stack>
            )}
          </Scrollable>
          {viewMode === VIEW_MODE.empty && (
            <LimitContainer margin="auto" pb={8}>
              <EmptyResult />
            </LimitContainer>
          )}
        </Container>
      </MainContent>
    </MainLayout>
  );
};

export default ManageFilePage;

const FILE_TAB = {
  all: 0,
  mine: 1,
  other: 2,
};
const PAGING_SIZE = 10;
const DEFAULT_FILTER = {
  createdByAccountId: null,
  fileType: null,
  startDate: null,
  endDate: null,
  offset: 0,
  limit: PAGING_SIZE,
};
const VIEW_MODE = {
  searching: 1,
  empty: 2,
  list: 3,
};
const OTHER_FILE_TYPES = [SystemConstant.FILE_TYPE.WORD, SystemConstant.FILE_TYPE.EXCEL, SystemConstant.FILE_TYPE.PPT];

import { AttachmentUtil } from "utils";
import { getFileFromTus } from "./multiple-file";
import { SystemConstant } from "const";
import { getInteractor } from "./local.service";

const OPTION_ARGUMENT = { groupId: null, isCurrentDevice: false };
export const AttachmentQueue = {};
export const getFileLocalDetails = (prefixKey, fileInfo, option = OPTION_ARGUMENT) => {
  const attachmentId = fileInfo.attachmentId || fileInfo.fileId || fileInfo.id;

  if (AttachmentQueue[attachmentId]) {
    const checkingPromise = makeQueryablePromise(AttachmentQueue[attachmentId]);

    if (checkingPromise.isFulfilled() && false === Boolean(AttachmentQueue[attachmentId].mediaPath)) {
      AttachmentQueue[attachmentId] = getAttachment(prefixKey, fileInfo, option);
    }
  } else {
    AttachmentQueue[attachmentId] = getAttachment(prefixKey, fileInfo, option);
  }

  return AttachmentQueue[attachmentId];
};

export const getAttachment = async (prefixKey, fileInfo, option = OPTION_ARGUMENT) => {
  const metaData = fileInfo.metaData;
  const fileName = metaData.fileName;
  const attachmentId = fileInfo.attachmentId;
  const mediaPath = await getFilePath(prefixKey, fileInfo, option);

  return {
    attachmentId: attachmentId,
    mediaPath: mediaPath,
    fileName: fileName,
    size: metaData.size,
    mimeType: metaData.mimeType,
    status: Boolean(mediaPath) ? SystemConstant.UPLOAD_STATUS.success : SystemConstant.UPLOAD_STATUS.failed,
  };
};

// Create url with protocol electron
// Synch file from tus if not exist
export const getFilePath = async (prefixKey, fileInfo, option = OPTION_ARGUMENT) => {
  const metaData = fileInfo.metaData;
  const fileName = metaData.fileName;
  const attachmentId = fileInfo.attachmentId;
  const { groupId, isCurrentDevice } = option || {};

  let mediaPath = "";
  let isGetFileSuccess = false;
  let fileRecord = {};
  try {
    fileRecord = await getInteractor(prefixKey).LocalFileService.get(attachmentId);

    const isGetFileLocalSuccess = AttachmentUtil.exitsLocalFile(prefixKey, attachmentId, fileName);

    const isExistedFile = fileRecord && isGetFileLocalSuccess;
    if (isExistedFile) {
      isGetFileSuccess = true;
    } else {
      const fileRecordRemote = await getFileFromTus(prefixKey, fileInfo, groupId);
      fileRecord = fileRecordRemote || fileRecord;
      if (fileRecordRemote) {
        isGetFileSuccess = true;
      } else {
        isGetFileSuccess = false;
      }
    }

    if (isGetFileSuccess || isCurrentDevice) {
      mediaPath = AttachmentUtil.getWebSrc(fileRecord?.url, fileName);
    }
  } catch (error) {
    console.log("get file local path error: ", error);
  }

  return mediaPath;
};

export const getFileLocalPath = async (prefixKey, fileInfo, options) => {
  const fileDetails = await getFileLocalDetails(prefixKey, fileInfo, options);
  if (fileDetails) return fileDetails.mediaPath;

  return "";
};

function makeQueryablePromise(promise) {
  // Don't modify any promise that has been already modified.
  if (promise.isFulfilled) return promise;

  // Set initial state
  var isPending = true;
  var isRejected = false;
  var isFulfilled = false;

  // Observe the promise, saving the fulfillment in a closure scope.
  var result = promise.then(
    function (v) {
      isFulfilled = true;
      isPending = false;
      return v;
    },
    function (e) {
      isRejected = true;
      isPending = false;
      throw e;
    },
  );

  result.isFulfilled = function () {
    return isFulfilled;
  };
  result.isPending = function () {
    return isPending;
  };
  result.isRejected = function () {
    return isRejected;
  };
  return result;
}

import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Badge, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant } from "const";
import { ContactIcon } from "components/icons";
import ContactListTab from "./ContactListTab";
import { PrimaryTab } from "components";
import ChatTab from "./ChatTab";
import AddingChat from "../../AddingChat";
import { useSelector } from "react-redux";
import { useCreateConversation, useUnreadMessage } from "hooks";
import { Call, ChatBubbleOutline } from "@mui/icons-material";
import { BranchSelectors, ConversationActions } from "redux-store";
import clsx from "clsx";
import { getNSLang } from "utils/lang.utils";
import CallingTab from "./CallingTab";

const ViewModeTabs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { createWithPersonal } = useCreateConversation();

  const isCreateGroupSuccess = useSelector(state => state.groupInfoRedux.isCreateGroupSuccess);

  const [selectedViewMode, setSelectedViewMode] = useState(VIEW_MODE_TAB_INDEX.message);

  const onClickContact = contact => {
    if (contact.groupType) {
      dispatch(
        ConversationActions.setSelectGroupId({
          selectedGroupId: contact.id,
        }),
      );
    } else {
      createWithPersonal(contact);
    }

    setSelectedViewMode(VIEW_MODE_TAB_INDEX.message);
  };

  useEffect(() => {
    if (isCreateGroupSuccess) {
      setSelectedViewMode(VIEW_MODE_TAB_INDEX.message);
    }
  }, [isCreateGroupSuccess]);

  return (
    <Box className={classes.viewModeRoot}>
      <Box className={clsx(classes.tabPanel, VIEW_MODE_TAB_INDEX.message !== selectedViewMode && "hidden")}>
        <ChatTab />
        <AddingChat />
      </Box>

      <Box className={clsx(classes.tabPanel, VIEW_MODE_TAB_INDEX.contact !== selectedViewMode && "hidden")}>
        <ContactListTab onClick={onClickContact} />
      </Box>

      {VIEW_MODE_TAB_INDEX.conference === selectedViewMode && (
        <Box className={clsx(classes.tabPanel)}>
          <CallingTab />
        </Box>
      )}

      <TabList classes={classes} selectedTabIndex={selectedViewMode} onChange={setSelectedViewMode} />
    </Box>
  );
};

const VIEW_MODE_TAB_INDEX = {
  message: 0,
  contact: 1,
  conference: 2,
};

ViewModeTabs.propTypes = {};

export default ViewModeTabs;

const TabList = ({ classes, selectedTabIndex, onChange }) => {
  const tabArray = useTabArray(classes);

  return (
    <Box className={classes.tabContainer}>
      <PrimaryTab
        tabArray={tabArray}
        onChange={onChange}
        TabIndicatorProps={{
          className: "hidden",
        }}
        classes={{
          tabLabel: "regular-sm-txt",
        }}
        selectedTabIndex={selectedTabIndex}
      />
    </Box>
  );
};

const useTabArray = classes => {
  const { allUnreadMessages } = useUnreadMessage();
  const isBranchServer = useSelector(BranchSelectors.isBranchServer);

  const tabArray = useMemo(() => {
    const tabs = [
      {
        label: getNSLang(LangConstant.NS_HOME, "TXT_CHATS"),
        icon: (
          <Badge badgeContent={allUnreadMessages} color="primary" classes={{ root: classes.numNotify, badge: "badge" }}>
            <ChatBubbleOutline />
          </Badge>
        ),
      },
      {
        label: isBranchServer
          ? getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_MEMBERS")
          : getNSLang(LangConstant.NS_HOME, "TXT_CONTACT"),
        icon: <ContactIcon />,
      },
      {
        label: getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_CALL"),
        icon: (
          <Badge badgeContent={9} color="primary" classes={{ root: classes.numNotify, badge: "badge" }}>
            <Call />
          </Badge>
        ),
      },
    ];

    return tabs;
  }, [isBranchServer, allUnreadMessages]);

  return tabArray;
};

const useStyles = makeStyles(theme => ({
  viewModeRoot: {
    height: "-webkit-fill-available",
    maxHeight: "calc(100% - 142px)",
  },

  tabContainer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    padding: 4,
    borderTop: "0.5px solid " + theme.palette.divider,
    background: "white",
  },

  tabLabel: {
    marginTop: 10,
  },

  tabPanel: {
    height: "calc(100% - 66px)",
    overflowY: "hidden",
  },

  numNotify: {
    "& .badge": {
      top: 4,
      right: -5,
      backgroundColor: "#EE4F49",
      border: "2px solid white",
    },
  },
}));

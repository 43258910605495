import { EventState, EventSubType, EventType, ExecuteEvent } from "pubsub/PubSub.const";
import IEvent from "./IEvent";
import { EventDto, IEventDto } from "pubsub/dto";
import { getInteractor } from "services/local.service";
import { deleteCallHistory } from "pubsub/services/call-history.service";
import { refactorSavingEvent } from "pubsub/PubSub.helper";

export default class CallHistoryEvent extends IEvent {
  constructor(prefixKey: string) {
    super(prefixKey, EventType.CALL_HISTORY);
  }

  handleEvent = async (limitRetry: number) => {
    const eventList: EventDto[] = (
      await getInteractor(this.prefixKey).LocalEventService.find({
        type: this.eventType,
        state: [EventState.NEW, EventState.RESENT],
      })
    ).map((item: IEventDto) => new EventDto(item));
    const savingEvents: IEventDto[] = [];

    for (let index = 0; index < eventList.length; index++) {
      const eventItem = eventList[index];
      const eventContent = eventItem.contentObj;
      const accountId = eventContent.account_id;
      const updateRoomIds = eventContent.room_ids;
      let executeEventValue: ExecuteEvent = ExecuteEvent.FAIL;

      if (accountId) {
        switch (eventItem.subtype) {
          case EventSubType.DELETE:
            executeEventValue = await deleteCallHistory(this.prefixKey, updateRoomIds);
            break;

          default:
            break;
        }
      }

      // Update event before saving in local db
      const refactorEvent = refactorSavingEvent(eventItem, executeEventValue, limitRetry);
      if (refactorEvent) savingEvents.push(refactorEvent);
    }

    await getInteractor(this.prefixKey).LocalEventService.save(savingEvents);
    return true;
  };
}

import { CardActionArea, IconButton } from "@mui/material";
import clsx from "clsx";
import { ImageMedia, VideoMedia } from "components/AppMedia";
import { isImage, isVideo } from "utils";
import { makeStyles } from "@mui/styles";
import { Close, Download, FolderOutlined } from "@mui/icons-material";
import { ForwardingIcon } from "components/icons";
import { getCommonLang } from "utils/lang.utils";

export const THUMBNAIL_LIST_CLASS_TRIGGER = "thumbnailList";
export const THUMBNAIL_CLASS_TRIGGER = "active";

export const CarouseAction = ({ onOpenFolder, onForward, onDownload, onClose }) => {
  const isDisableOpenFolder = false === Boolean(onOpenFolder);
  const isDisableForward = false === Boolean(onForward);
  const isDisableDownload = false === Boolean(onDownload);

  return (
    <>
      <IconButton
        color="inherit"
        disabled={isDisableOpenFolder}
        onClick={onOpenFolder}
        title={getCommonLang("TXT_OPEN_FOLDER")}
      >
        <FolderOutlined />
      </IconButton>
      <IconButton color="inherit" disabled={isDisableForward} onClick={onForward} title={getCommonLang("TXT_FORWARD")}>
        <ForwardingIcon />
      </IconButton>
      <IconButton disabled={isDisableDownload} onClick={onDownload} title={getCommonLang("TXT_DOWNLOAD")}>
        <Download />
      </IconButton>
      <IconButton onClick={onClose} title={getCommonLang("TXT_CLOSE")}>
        <Close />
      </IconButton>
    </>
  );
};

export const CarouselMediaItem = props => {
  const { file, isThumbnail } = props;
  const isVideoUrl = isVideo(file?.metaData?.mimeType);
  const isImageUrl = isImage(file?.metaData?.mimeType);

  return (
    <>
      {isVideoUrl && <VideoMedia {...props} />}
      {isImageUrl && <ImageMedia isImageAction={!isThumbnail} {...props} />}
    </>
  );
};

export const ThumbnailItem = ({ onClick, active, data }) => {
  const classes = useStyles();

  return (
    <CardActionArea
      className={clsx(classes.thumbnailItem, active && [classes.thumbnailItemActive, THUMBNAIL_CLASS_TRIGGER])}
      onClick={onClick}
    >
      <CarouselMediaItem file={data} width={150} height={150} isThumbnail={true} />
    </CardActionArea>
  );
};

const useStyles = makeStyles(theme => ({
  thumbnailItem: {
    display: "inline-block",
    width: 95,
    height: 95,
    margin: 6,
    borderRadius: 10,
    overflow: "hidden",

    [theme.breakpoints.up(1500)]: {
      width: 108,
      height: 108,
    },

    "& img, & video": {
      objectFit: "cover",
      width: "100%",
      height: "100%",
    },

    // Overwrite loading box
    "& > div": {
      minWidth: "unset",
      minHeight: "unset",
    },
  },

  thumbnailItemActive: {
    border: "3px solid #008FE8",
    boxSizing: "border-box",
  },
}));

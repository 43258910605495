import { useEffect, useState } from "react";
import { sortBy } from "lodash";
import { StorageUtil, toCamel } from "utils";
import { useSelector } from "react-redux";
import { BranchSelectors } from "redux-store";
import { getInteractor } from "services/local.service";
import { KeyConstant } from "const";

const useAccountList = () => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);

  const [accountList, setAccountList] = useState([]); // List accounts of current branch

  const getAccountList = async (keyword = "") => {
    keyword = keyword ? keyword.trim() : "";
    const accountsInBranch = await getInteractor(prefixKey).LocalAccountService.searchAccountList({
      keyword,
      account_id: accountId,
      branch_id: selectedBranch.id,
    });

    const sortedAccountList = sortBy(accountsInBranch, [account => account.name?.toLowerCase()?.trim()]);
    setAccountList(toCamel(sortedAccountList));
  };

  useEffect(() => {
    const isValidData = accountId && selectedBranch.id;
    if (isValidData) {
      getAccountList();
    }
  }, [selectedBranch]);

  return { accountList };
};

export default useAccountList;

import React, { useState, memo, useMemo, Suspense } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Button, Collapse, Divider, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LangConstant, SystemConstant } from "const";
import { ExpandLess, ExpandMore, AddOutlined } from "@mui/icons-material";
import AddingChannelDrawer from "../../AddingChat/AddingChannel/AddingChannelDrawer";
import AddingPersonalDrawer from "../../AddingChat/AddingPersonalDrawer";
import AddingGroupDrawer from "../../AddingChat/AddingGroup/AddingGroupDrawer";
import useConversation, { LIMIT_DISPLAY } from "hooks/useConversationList";
const ChatGroupItem = React.lazy(() => import("./ChatGroupItem"));

const ChatAllTab = ({ type }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const { groupList, totalItems, getConversation } = useConversation([type]);

  const [isExpand, setIsExpand] = useState(true);
  const [isShowDrawer, setIsShow] = useState(false);

  const [isAddingChannelDrawer, isAddingGroupDrawer, isAddingPersonalDrawer] = useMemo(
    () => [
      isShowDrawer && type === SystemConstant.GROUP_CHAT_TYPE.channel,
      isShowDrawer && type === SystemConstant.GROUP_CHAT_TYPE.group,
      isShowDrawer && type === SystemConstant.GROUP_CHAT_TYPE.personal,
    ],
    [isShowDrawer],
  );

  const handleChangeList = () => {
    const newLength = groupList.length + LIMIT_DISPLAY;
    getConversation(newLength, 0);
  };

  const handleExpand = () => {
    setIsExpand(!isExpand);
    getConversation(LIMIT_DISPLAY, 0);
  };

  const handleControlDrawer = open => () => setIsShow(open);

  let content = getLabel(LangConstant.FM_GROUP_TAG, { number: totalItems });
  switch (type) {
    case SystemConstant.GROUP_CHAT_TYPE.channel:
      content = getLabel(LangConstant.FM_CHANNEL_TAG, { number: totalItems });
      break;

    case SystemConstant.GROUP_CHAT_TYPE.personal:
      content = getLabel(LangConstant.FM_PERSONAL_TAG, { number: totalItems });
      break;

    default:
      break;
  }
  const hasMore = Boolean(totalItems > groupList.length);

  return (
    <Box>
      <Box className={classes.category}>
        <Box className={classes.categoryName}>
          <Typography className={classes.title}>{content}</Typography>
          <IconButton onClick={handleExpand}>{isExpand ? <ExpandLess /> : <ExpandMore />}</IconButton>
        </Box>
        <IconButton color="primary" onClick={handleControlDrawer(true)}>
          <AddOutlined />
        </IconButton>
      </Box>

      <Collapse in={isExpand}>
        <Suspense fallback={<Box />}>
          {groupList.map(item => (
            <ChatGroupItem key={item.id} data={item} />
          ))}

          {hasMore && (
            <Divider className={classes.viewMore}>
              <Button variant="text" className={classes.viewMoreButton} onClick={handleChangeList}>
                {getLabel(LangConstant.TXT_VIEW_MORE)}
              </Button>
            </Divider>
          )}
        </Suspense>
      </Collapse>

      {isAddingChannelDrawer && (
        <AddingChannelDrawer open onComplete={handleControlDrawer(false)} onClose={handleControlDrawer(false)} />
      )}

      {isAddingGroupDrawer && (
        <AddingGroupDrawer open onComplete={handleControlDrawer(false)} onClose={handleControlDrawer(false)} />
      )}

      {isAddingPersonalDrawer && <AddingPersonalDrawer open onClose={handleControlDrawer(false)} />}
    </Box>
  );
};

ChatAllTab.propTypes = {
  type: PropTypes.number,
};

ChatAllTab.defaultProps = {
  chatList: [],
};

export default memo(ChatAllTab);

const useStyles = makeStyles(() => ({
  chatList: {
    height: "100%",
    overflow: "auto",
    maxHeight: "calc(100vh - 336px)",
  },

  viewMore: {
    margin: "10px 14px",
  },

  viewMoreButton: {
    textTransform: "none",
  },

  category: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  categoryName: {
    display: "flex",
    alignItems: "center",
  },

  title: {
    fontSize: 13,
    color: "gray",
  },
}));

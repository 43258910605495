import { useEffect, useState } from "react";
import { Box, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppDrawer, InfiniteScroll } from "components";
import { ImageConstant, LangConstant } from "const";
import { useTranslation } from "react-i18next";
import MessageThread from "./MessageThread";
import { useThreadInfo, useUnreadThread } from "hooks";
import { MarkReadIcon } from "components/icons";
import { getNSLang } from "utils/lang.utils";
import { useAlertContext } from "components/context/AlertContext";

const PAGING_NUMBER = 4;
const ThreadListDrawer = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const { totalThreads, updateThreadAndMessage } = useThreadInfo();
  const { unreadThreadNum } = useUnreadThread();
  const { showAlert } = useAlertContext();

  const [displayThreads, setDisplayThreads] = useState([]);

  const handleScrollToBottom = () => {
    const newDisplay = totalThreads.slice(displayThreads.length, displayThreads.length + PAGING_NUMBER);
    setDisplayThreads(state => [...state, ...newDisplay]);
  };

  const handleReadAll = () => {
    updateThreadAndMessage(totalThreads);
    showAlert({ content: getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_READ_ALL_THREAD") });
  };

  const handleClose = () => {
    updateThreadAndMessage(displayThreads);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      const newDisplay = totalThreads.slice(0, displayThreads.length || PAGING_NUMBER);
      setDisplayThreads(newDisplay);
    }
  }, [totalThreads, isOpen]);

  useEffect(() => {
    const unreadThread = displayThreads.filter(item => item.totalUnread > 0);
    if (unreadThread.length > 10) updateThreadAndMessage(unreadThread);
  }, [displayThreads]);

  return (
    <AppDrawer
      open={isOpen}
      onClose={handleClose}
      title={getLabel(LangConstant.TXT_FLOW_MESSAGE)}
      classes={{ title: classes.drawerTitle }}
      secondIcon={<MarkReadIcon />}
      secondIconProps={{
        onClick: handleReadAll,
        title: getNSLang(LangConstant.NS_HOME_CONVERSATION, "TXT_MARK_AS_READ"),
        className: classes.markRead,
        disabled: unreadThreadNum <= 0,
      }}
    >
      {displayThreads && displayThreads.length > 0 ? (
        <InfiniteScroll
          className={classes.infiniteScroll}
          onScrollToBottom={handleScrollToBottom}
          hasMore={displayThreads.length < totalThreads.length}
        >
          {displayThreads.map(item => (
            <MessageThread
              key={item.id}
              threadId={item.threadId}
              parentMessage={item.parentMessage}
              replyMessageList={item.replyMessageList}
              totalReply={item.totalReply}
              groupId={item.groupId}
              senderParentMsg={item.senderParentMsg}
            />
          ))}
        </InfiniteScroll>
      ) : (
        <Box className={classes.emptyChatList}>
          <CardMedia component="img" src={ImageConstant.WelcomeImage} className={classes.emptyImage} />
          <Typography className={classes.emptyMessage}>{getLabel(LangConstant.TXT_NO_UNSEEN_THREAD)}</Typography>
        </Box>
      )}
    </AppDrawer>
  );
};

export default ThreadListDrawer;

const useStyles = makeStyles(theme => ({
  rootSpace: {
    paddingLeft: 16,
    paddingRight: 16,
  },

  unseenText: {
    fontSize: 14,
    marginBottom: 10,
  },

  emptyImage: {
    padding: "10px 14px",
  },

  emptyMessage: {
    fontSize: 15,
    fontWeight: 600,
    marginTop: 16,
    lineHeight: "20px",
    textAlign: "center",
  },

  emptyChatList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "0 56px",
    borderTop: "0.5px solid " + theme.palette.divider,
  },

  infiniteScroll: {
    height: "100%",
    paddingTop: 5,
  },

  drawerTitle: {
    textAlign: "left",
  },

  markRead: {
    color: theme.palette.primary.main,
  },
}));

import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Menu, MenuItem } from "@mui/material";
import { Delete, Notifications, RemoveCircle, Logout } from "@mui/icons-material";
import { NotificationOffIcon } from "components/icons";
import { SystemConstant, LangConstant, KeyConstant } from "const";
import { showConfirmLeaveGroup } from "utils/view.utils";
import DialogGroupMember from "../../GroupInfoDrawer/DialogGroupMember";
import ChooseAdminDrawer from "../../GroupInfoDrawer/GroupMember/ChooseAdminDrawer";
import DialogConfirmLeave from "../../AddingContact/DialogConfirmLeave";
import { getInteractor } from "services/local.service";
import { AccountActions, BranchSelectors, ConversationActions, GroupInfoActions } from "redux-store";
import { StorageUtil } from "utils";
import { getSavedServer } from "utils/branch.utils";

const ChatItemMenu = ({ anchorEl, onClose, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  let accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const isChannelGroup = data?.groupType === SystemConstant.GROUP_CHAT_TYPE.channel;
  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);

  const [isConfirmLeave, setIsConfirmLeave] = useState(false);
  const [isChooseAdmin, setIsChooseAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const [thirdRowContent, setThirdRowContent] = useState({
    content: "",
    icon: null,
    function: null,
  });
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const onDeleteConversation = () => {
    setIsConfirmDelete(true);
  };

  const onCloseConfirmDialog = () => {
    setIsConfirmDelete(false);
    onClose();
  };

  const onConfirmDelete = () => {
    setIsConfirmDelete(false);
    dispatch(
      GroupInfoActions.deleteGroup({
        groupId: data.id,
      }),
    );
  };

  const handleNotificationSetting = () => {
    dispatch(
      GroupInfoActions.muteGroupNotification({
        groupId: data.id,
        status: isMuted ? SystemConstant.STATE.active : SystemConstant.STATE.inactive,
      }),
    );
    setIsMuted(!isMuted);
    onClose();
  };

  const onBlockUser = () => {
    let other = data?.groupMembers?.find(s => s.id !== accountId);
    if (other) {
      if (data.isBlocked) {
        dispatch(AccountActions.unblockUser({ groupId: data.id, blockedAccountId: other.id }));
      } else {
        dispatch(AccountActions.blockUser({ groupId: data.id, blockedAccountId: other.id }));
      }
    }
    onClose();
  };
  const onLeaveGroupChannel = () => {
    showConfirmLeaveGroup(data, setIsConfirmLeave, setIsChooseAdmin);
  };

  const onClickSetAdmin = () => {
    onClose();
    setIsAdmin(true);
    setIsChooseAdmin(false);
  };

  const onLeave = () => {
    dispatch(
      ConversationActions.sendMessage({
        groupId: data.id,
        sendType: SystemConstant.SEND_TYPE.leaveGroup,
        content: accountId,
        parentId: null,
        deviceList: [],
        branchId: selectedBranch?.id,
        mentionIdsArr: [],
        threadId: null,
        removingId: accountId,
      }),
    );

    setIsConfirmLeave(false);
    onClose();
  };

  useEffect(() => {
    let result = { ...thirdRowContent };
    let isSet = true;
    if (data.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
      result.content = getLabel(data.isBlocked ? LangConstant.TXT_UN_BLOCK : LangConstant.TXT_BLOCK);
      result.icon = <RemoveCircle />;
      result.function = onBlockUser;
    } else if (data.groupType === SystemConstant.GROUP_CHAT_TYPE.group) {
      result.content = getLabel(LangConstant.TXT_LEAVE_GROUP);
      result.icon = <Logout />;
      result.function = onLeaveGroupChannel;
    } else {
      result.content = getLabel(LangConstant.TXT_LEAVE_CHANNEL);
      result.icon = <Logout />;
      result.function = onLeaveGroupChannel;
    }
    if (isSet) setThirdRowContent(result);

    getInteractor()
      .LocalGroupSettingService.isMutedNotify(data.id)
      .then(isMutedGroup => setIsMuted(Boolean(isMutedGroup)));
  }, [data]);

  return (
    <>
      <Menu
        open={Boolean(anchorEl)}
        onClose={onClose}
        classes={{
          root: classes.chatMenuRoot,
          paper: classes.chatMenuPaper,
          list: classes.chatMenuList,
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        PaperProps={{
          elevation: 0,
        }}
      >
        <MenuItem className={classes.menuItem} onClick={onDeleteConversation}>
          <Delete /> {getLabel(LangConstant.TXT_DELETE_CONVERSATION)}
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={handleNotificationSetting}>
          {isMuted ? <Notifications /> : <NotificationOffIcon />}
          {getLabel(isMuted ? LangConstant.TXT_UNMUTE_CONVERSATION : LangConstant.TXT_MUTE_CONVERSATION)}
        </MenuItem>
        {!(
          getSavedServer()?.type === SystemConstant.SERVER_TYPE.branch &&
          data.groupType === SystemConstant.GROUP_CHAT_TYPE.personal
        ) && (
          <MenuItem className={classes.menuItem} onClick={thirdRowContent.function}>
            {thirdRowContent.icon} {thirdRowContent.content}
          </MenuItem>
        )}
      </Menu>
      {isConfirmLeave && (
        <DialogConfirmLeave
          open={isConfirmLeave}
          onClose={() => {
            setIsConfirmLeave(false);
            onClose();
          }}
          title={isChannelGroup ? getLabel(LangConstant.TXT_LEAVE_CHANNEL) : getLabel(LangConstant.TXT_LEAVE_GROUP)}
          content={getLabel(LangConstant.TXT_CONTENT_BUTTON_GROUP)}
          submitProps={{
            submitText: isChannelGroup
              ? getLabel(LangConstant.TXT_LEAVE_CHANNEL_INFO)
              : getLabel(LangConstant.TXT_LEAVE_GROUP_INFO),
            onClick: onLeave,
          }}
          cancelProps={{
            cancelText: getLabel(LangConstant.TXT_CANCEL_CHANNEL_INFO),
            onClick: () => {
              setIsConfirmLeave(false);
              onClose();
            },
          }}
        />
      )}
      {isChooseAdmin && (
        <DialogGroupMember
          open={isChooseAdmin}
          onClickSetAdmin={onClickSetAdmin}
          onClose={() => {
            setIsChooseAdmin(false);
            onClose();
          }}
          groupDetail={data}
          subTitle={
            data.groupType === SystemConstant.GROUP_CHAT_TYPE.channel
              ? getLabel(LangConstant.TXT_SUBTITLE_LEAVE_CHANNEL)
              : getLabel(LangConstant.TXT_DES_BUTTON_CONFIRM)
          }
          confirmButtonText={
            data.groupType === SystemConstant.GROUP_CHAT_TYPE.channel
              ? getLabel(LangConstant.TXT_LEAVE_CHANNEL)
              : getLabel(LangConstant.TXT_BUTTON_LEAVE_GROUP_SET_ADMIN)
          }
        />
      )}
      {isAdmin && (
        <ChooseAdminDrawer
          dataAdmin={data.groupMembers}
          onClose={() => {
            setIsAdmin(false);
            onClose();
          }}
          open={isAdmin}
          groupDetail={data}
        />
      )}
      {isConfirmDelete && (
        <DialogConfirmLeave
          open
          onClose={onCloseConfirmDialog}
          title={getLabel(LangConstant.TXT_DELETE_CONVERSATION_TITLE)}
          content={getLabel(LangConstant.TXT_DELETE_CONVERSATION_SUBTITLE)}
          submitProps={{ submitText: getLabel(LangConstant.TXT_DELETE_CONVERSATION), onClick: onConfirmDelete }}
          cancelProps={{ onClick: onCloseConfirmDialog }}
        />
      )}
    </>
  );
};

ChatItemMenu.propTypes = {
  anchorEl: PropTypes.object,

  onClose: PropTypes.func,

  data: PropTypes.shape({
    type: PropTypes.number,

    isMuted: PropTypes.bool,
  }),
};

export default memo(
  ChatItemMenu,
  (prev, next) => next && prev?.data?.id === next.data.id && prev?.anchorEl === next.anchorEl,
);

const useStyles = makeStyles(theme => ({
  chatMenuRoot: {},

  chatMenuPaper: {
    borderRadius: 20,
    padding: "13px 10px",
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "50%",
      left: -10,
      width: 20,
      height: 20,
      backgroundColor: theme.palette.white,
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },

  chatMenuList: {},

  menuItem: {
    borderRadius: 10,
    padding: "10px 14px",
    "&::hover": {
      backgroundColor: "#F2F2F2",
    },

    "&>svg": {
      marginRight: 10,
    },

    fontSize: 15,
    fontWeight: 400,
    lineHeight: "20px",
    color: "#1E272E",
  },
}));

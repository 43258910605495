import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { KeyConstant, SystemConstant } from "const";
import FileBox from "pages/HomePage/components/MessengerChat/ChatItem/FileBox";
import { ImagePreview } from "pages/HomePage/components/MessengerChat/ChatItem/MediaPreview/ImageMessage";
import { MyMessage } from "pages/HomePage/components/MessengerChat/ChatItem/MessengerPeople";
import { convertString2JSON, isExternalLink, StorageUtil, toCamel } from "utils";
import { getMessageFileList } from "utils/message.utils";

const PreviewMessage = ({ messageFileList, message, isForwardAttachment, imageProps, fileProps, msgProps }) => {
  const groupId = message.groupId;
  const mentions = message.mentions;
  const sendType = message.sendType;
  messageFileList = messageFileList || getMessageFileList(message);
  const isMine = message.senderId === StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);

  const fileObj = toCamel(convertString2JSON(message.content, {}));
  const caption = isForwardAttachment ? "" : fileObj.contentMessage || "";

  return sendType === SystemConstant.SEND_TYPE.image ? (
    <ImagePreview
      fileInfoList={messageFileList}
      groupId={groupId}
      caption={caption}
      mentions={mentions}
      isMine={isMine}
      {...imageProps}
    />
  ) : sendType === SystemConstant.SEND_TYPE.file ? (
    <PreviewForwardFile fileInfoList={messageFileList} isMine={isMine} groupId={groupId} {...fileProps} />
  ) : (
    <PreviewForwardMsg message={message} isMine={isMine} {...msgProps} />
  );
};

export default PreviewMessage;

const PreviewForwardFile = ({ fileInfoList, groupId, ...otherProps }) => {
  return (
    <Stack spacing={1}>
      {fileInfoList.map(item => {
        return <FileBox key={item.attachmentId} mediaData={item} groupId={groupId} {...otherProps} />;
      })}
    </Stack>
  );
};

const PreviewForwardMsg = ({ message, ...otherProps }) => {
  const hasExternalLink = isExternalLink(message.content);
  const classes = useStyles();
  const { isMine, ...other } = otherProps;

  return (
    <MyMessage
      message={message}
      hasExternalLink={hasExternalLink}
      customStyles={clsx(classes.messageCustom, !isMine && classes.otherMessage)}
      {...other}
    />
  );
};

const useStyles = makeStyles({
  messageCustom: {
    width: "100%",
  },

  otherMessage: {
    color: "#000000",
    backgroundColor: "#F1F3F6",
  },
});

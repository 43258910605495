import React, { useEffect, useState } from "react";
import { IconButton, Badge } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { LocalAppNotificationService } from "services/local.service";
import { formatLimitNumber } from "utils";
import { useUnreadMessage } from "hooks";
import { NotificationsOutlined } from "@mui/icons-material";
import NotificationDrawer from "./NotificationDrawer";
import { SystemSelectors } from "redux-store";

const NotificationBadge = () => {
  const classes = useStyles();
  const { unreadNotification, totalUnread, updateTotalUnread } = useUnreadMessage();

  const newNotification = useSelector(SystemSelectors.getNewNotification);

  const [isOpenNoti, setIsOpenNoti] = useState(false);
  const [messageCount, setMessageCount] = useState(0);

  const onOpenNotiTab = () => {
    setIsOpenNoti(true);
  };

  useEffect(() => {
    setMessageCount(unreadNotification);
    LocalAppNotificationService.setBadgeCount(totalUnread);
  }, [unreadNotification]);

  useEffect(() => {
    updateTotalUnread();
  }, [newNotification]);

  return (
    <>
      <Badge
        badgeContent={formatLimitNumber(messageCount)}
        color="primary"
        classes={{ root: classes.numNotify, badge: "badge" }}
      >
        <IconButton onClick={onOpenNotiTab}>
          <NotificationsOutlined />
        </IconButton>
      </Badge>
      {isOpenNoti && <NotificationDrawer open onClose={() => setIsOpenNoti(false)} />}
    </>
  );
};

NotificationBadge.propTypes = {};

export default NotificationBadge;

const useStyles = makeStyles({
  numNotify: {
    "& .badge": {
      top: 4,
      right: -1,
      backgroundColor: "#EE4F49",
      border: "1px solid white",
    },
  },
});

import React, { useState, useEffect, Fragment, memo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import StringFormat from "string-format";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toCamel, toSnake } from "utils";
import { LangConstant, FormatConstant } from "const";
import { InfiniteScroll } from "components";
import { getInteractor } from "services/local.service";
import { ConversationActions, ConversationSelectors } from "redux-store";
import { useConversationContext } from "../ConversationContext";
import { refactorMessageList } from "../ViewMode/ViewMode.helper";
import { isEqual } from "lodash";
import MessageItem from "../MessageItem";

const SearchMode = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);
  const { groupDetail } = useConversationContext();
  const selectedGroupId = groupDetail.id;

  const { selectedMessageId, messageList } = useSelector(ConversationSelectors.getSearchingMessage);

  const [messages, setMessages] = useState([]);
  const [showMessage, setShowMessage] = useState([]);
  const [isChangedByScroll, setIsChangedByScroll] = useState(false);

  const handleScrollTop = async () => {
    if (showMessage.length === 0) return;
    const snakeMsg = toSnake(messages);
    const result = await getInteractor().LocalMessageService.getEarlierMessage(snakeMsg, selectedGroupId);
    handleSetMessage(toCamel(result), true);
  };

  const handleScrollBottom = async () => {
    if (showMessage.length === 0) return;
    const snakeMsg = toSnake(messages);
    const result = await getInteractor().LocalMessageService.getLaterMessage(snakeMsg, selectedGroupId);
    handleSetMessage(toCamel(result), true);
  };

  const handleClickScrollButton = () => {
    dispatch(
      ConversationActions.conversationSet({
        isSearchMode: false,
        searchingMessage: {
          selectedMessageId: null,
          messageList: [],
          searchValue: "",
        },
      }),
    );
  };

  const handleScrollToMessage = (selectedMessageId, messages) => {
    return setTimeout(() => {
      const selectedElementId = StringFormat(FormatConstant.FM_CHAT_ITEM_ID, selectedMessageId);
      const messageRootElement = document.getElementById(CHAT_WRAPPER_ID);
      const scrollChildElement = document.getElementById(selectedElementId);
      const centerHeight = messageRootElement.offsetHeight ? Math.floor(messageRootElement.offsetHeight / 2) : 0;

      let scrollToPosition = scrollChildElement?.offsetTop || messageRootElement.scrollHeight;
      if (scrollToPosition > messageRootElement.scrollHeight) scrollToPosition = messageRootElement.scrollHeight;
      scrollToPosition = scrollToPosition - centerHeight;

      messageRootElement.scroll({
        top: selectedMessageId === messages[0].id ? 0 : scrollToPosition,
        behavior: "smooth",
      });
    }, 500);
  };

  const handleSetMessage = (newMessageList, isChangingDataByScroll) => {
    refactorMessageList(newMessageList).then(refactorMsgList => {
      setShowMessage(refactorMsgList);
      setMessages([...newMessageList]);
      setIsChangedByScroll(Boolean(isChangingDataByScroll));
    });
  };

  useEffect(() => {
    if (!isEqual(messageList, messages)) {
      handleSetMessage(messageList);
    }
  }, [messageList]);

  useEffect(() => {
    if (selectedMessageId && messages.length > 0 && false === isChangedByScroll) {
      const scrollTimeout = handleScrollToMessage(selectedMessageId, messages);
      return () => clearTimeout(scrollTimeout);
    }
  }, [selectedMessageId, messages, isChangedByScroll]);

  return (
    <>
      {showMessage.length > 0 && (
        <InfiniteScroll
          className={classes.wrapChatItem}
          id={CHAT_WRAPPER_ID}
          onScrollToTop={handleScrollTop}
          onScrollToBottom={handleScrollBottom}
          onClickScrollButton={handleClickScrollButton}
        >
          {showMessage.map(messageItem => (
            <MessageItem key={messageItem.id} message={messageItem} />
          ))}
        </InfiniteScroll>
      )}

      <Typography className={showMessage && showMessage.length > 0 ? "hidden" : classes.notFoundText}>
        {getLabel(LangConstant.TXT_NOT_FOUND)}
      </Typography>
    </>
  );
};

SearchMode.propTypes = {};

SearchMode.defaultProps = {};

export default memo(SearchMode);

const CHAT_WRAPPER_ID = "chat-wrapper-id";

const useStyles = makeStyles(theme => ({
  wrapChatItem: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0 22px",
    paddingBottom: 15,
    paddingTop: 70,
  },

  notFoundText: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.grey[200],
  },
}));

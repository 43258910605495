import { all } from "redux-saga/effects";
import { StorageUtil, toSnake } from "utils";
import { ApiConstant, KeyConstant, SystemConstant } from "const";
import { getInteractor } from "services/local.service";
import { remoteApiFactory } from "services";

// Logic function, not be called from react
let fetchingTimeKeys = {};
export const saveKeysOfDevice = async (prefixKey, data) => {
  const { accountId, deviceId } = data;
  if (!prefixKey) prefixKey = StorageUtil.getCurrentPrefixKey();
  const device = await getInteractor(prefixKey).LocalDeviceService.get(deviceId);
  const isDeviceWithoutKey = device && Boolean(device.upload_key_f) && !Boolean(device.key_f);
  const isSkipFetching = fetchingTimeKeys[deviceId] && Date.now() - fetchingTimeKeys[deviceId] < 2000; // True: Fetching keys in 2s
  // Do nothing if device not upload keys to server or it has enough keys
  if (!isDeviceWithoutKey || isSkipFetching) return true;

  try {
    const limit = 10000;
    const offset = 0;
    const payloadToGetKey = {
      mode: 0,
      accountId,
      deviceId,
      limit: limit,
      offset: offset,
      paging: 0,
      asc: 1,
      order_by: "created",
      filter: "",
    };

    const response = await remoteApiFactory.getBranchApi(prefixKey).getKeys(toSnake(payloadToGetKey));
    if (response.status === ApiConstant.STT_OK) {
      const responseData = response.data;

      for (let i1 = 0; i1 < Object.values(responseData).length; i1++) {
        const deviceIdWithKey = Object.values(responseData)[i1];
        for (let i = 0; i < Object.values(deviceIdWithKey).length; i++) {
          const accountKey = Object.values(deviceIdWithKey)[i];
          let keys = [];
          if (accountKey.identityKey) {
            keys.push({
              ...accountKey.identityKey,
              id: accountKey.identityKey.uuid,
            });
          }

          if (accountKey.signPreKey) {
            keys.push({
              ...accountKey.signPreKey,
              id: accountKey.signPreKey.uuid,
            });
          }

          if (accountKey.oneTimeKeys) {
            keys = [
              ...keys,
              ...accountKey.oneTimeKeys.map(item => ({
                ...item,
                id: item.uuid,
              })),
            ];
          }

          let isEnuf = keys.length === SystemConstant.MAX_KEY_LENGTH;
          let currentDevice = await getInteractor(prefixKey).LocalDeviceService.get(Object.keys(deviceIdWithKey)[0]);
          currentDevice.key_f = Number(isEnuf);

          await getInteractor(prefixKey).LocalDeviceService.save([currentDevice]);
          await getInteractor(prefixKey).LocalAccountKeyService.save(keys);
        }
      }

      fetchingTimeKeys[deviceId] = Date.now();
      return true;
    }
  } catch (error) {
    console.log(error);
  }

  return false;
};

export function* saveKeysByAccountIds(prefixKey, accountIds) {
  if (!prefixKey) prefixKey = StorageUtil.getCurrentPrefixKey();
  const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  if (false === Boolean(accountIds.find(item => item === currentAccountId))) accountIds.push(currentAccountId);

  try {
    let deviceList = yield getInteractor(prefixKey).LocalDeviceService.find({
      account_id: accountIds,
      state: SystemConstant.STATE.active,
    });
    deviceList = deviceList.filter(item => Boolean(item.upload_key_f) && !Boolean(item.key_f));

    const stepArr = [];

    for (let index = 0; index < Math.ceil(deviceList.length / 5); index++) {
      let toPush = [];
      if (index === 0) {
        toPush = deviceList.slice(index * 5, (index + 1) * 5);
      } else {
        toPush = deviceList.slice(index * 5 + 1, (index + 1) * 5);
      }
      stepArr.push(toPush);
    }

    if (stepArr.length > 0) {
      function* promiseGetDeviceKey() {
        if (stepArr.length > 0) {
          const curr = stepArr.shift();

          yield all(
            curr.map(item =>
              saveKeysOfDevice(prefixKey, {
                accountId: item.account_id,
                deviceId: item.id,
              }),
            ),
          );

          yield promiseGetDeviceKey();
        } else return;
      }
      yield promiseGetDeviceKey();
    }
  } catch (error) {
    console.log(error);
  }
}

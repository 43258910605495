import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isJSONString, toCamel } from "utils";
import { AppConstant, KeyConstant, SystemConstant } from "const";
import {
  BranchSelectors,
  ConversationActions,
  ConversationSelectors,
  GroupInfoSelectors,
  getReduxState,
} from "redux-store";
import { StorageUtil } from "utils";
import { getInteractor } from "services/local.service";
import { ARR_NOTICE_NORMAL } from "sagas/saga.helper";
import isEqual from "lodash/isEqual";
import { convertContentFileMsg } from "utils/message.utils";

const MEDIA_SEND_TYPE = [
  SystemConstant.SEND_TYPE.image,
  SystemConstant.SEND_TYPE.file,
  SystemConstant.SEND_TYPE.audio,
  SystemConstant.SEND_TYPE.video,
];

export const useUnreadThread = () => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);
  const selectedServerRedux = useSelector(BranchSelectors.getSelectedBranch);
  const deleteGroup = useSelector(GroupInfoSelectors.getDeleteGroup);
  const updateThreadStatus = useSelector(state => state.conversationRedux.updateThreadStatus);

  const [unreadThreadNum, setUnreadThreadNum] = useState(0);

  const countUnreadThread = async () => {
    const unreadNum = await getInteractor(prefixKey).LocalThreadService.countUnreadThreadByBranchId(branchId);
    if (unreadNum !== unreadThreadNum) setUnreadThreadNum(unreadNum);
  };

  useEffect(() => {
    countUnreadThread();
  }, [isUpdateViewMode, selectedServerRedux, deleteGroup, isUpdateViewMode, updateThreadStatus]);

  return { unreadThreadNum };
};

export const useThreadInfo = () => {
  const dispatch = useDispatch();
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);

  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);
  const selectedServerRedux = useSelector(BranchSelectors.getSelectedBranch);
  const deleteGroup = useSelector(GroupInfoSelectors.getDeleteGroup);
  const updateGroupData = useSelector(GroupInfoSelectors.getUpdatingGroup);
  const updateThreadStatus = useSelector(state => state.conversationRedux.updateThreadStatus);

  const [totalThreads, setTotalThreads] = useState([]);

  const getThreadsInfo = async () => {
    let threadList = await getInteractor(prefixKey).LocalThreadService.getAllByBranchId(branchId);

    if (threadList && threadList.length > 0) {
      threadList = await Promise.all(
        threadList.map(async item => {
          const threadInfo = toCamel(item);

          const accountGroup =
            (await getInteractor(prefixKey).LocalAccountGroupService.findOne({
              group_id: threadInfo.groupId,
              account_id: accountId,
            })) || {};
          if (accountGroup.state === SystemConstant.STATE.inactive) return null;

          const senderParentMsg = await getInteractor(prefixKey).LocalAccountService.get(
            threadInfo.threadAccountId,
            branchId,
          );
          const newestMsgInThread =
            (await getInteractor(prefixKey).LocalMessageService.getThreadMessage(threadInfo.threadId, { limit: 2 })) ||
            [];
          // Convert parent message content
          let parentMessage = await getInteractor(prefixKey).LocalMessageService.findOne({
            source_id: threadInfo.threadId,
            send_type: ARR_NOTICE_NORMAL.concat(MEDIA_SEND_TYPE),
          });
          parentMessage = await convertMessageContent(parentMessage);

          const replyMessageList = await Promise.all(
            newestMsgInThread.reverse().map(async item => await convertMessageContent(item)),
          );

          return {
            ...threadInfo,
            replyMessageList: replyMessageList,
            parentMessage: toCamel(parentMessage),
            senderParentMsg: toCamel(senderParentMsg),
          };
        }),
      );
      threadList = threadList.filter(item => Boolean(item));

      if (!isEqual(threadList, totalThreads)) {
        setTotalThreads(threadList);
      }
    } else {
      setTotalThreads([]);
    }
  };

  const convertMessageContent = async message => {
    if (!message) return message;
    let convertMessage = { ...message };

    try {
      if (MEDIA_SEND_TYPE.includes(message.send_type) && isJSONString(message.content)) {
        convertMessage = { ...message, content: convertContentFileMsg(message) };
      } else {
        const lastEditMsg = await getInteractor(prefixKey).LocalMessageService.getNewestEditMsg(message.source_id);
        if (lastEditMsg && Object.keys(lastEditMsg).length > 0) {
          convertMessage = { ...message, content: lastEditMsg.content };
        }
      }
    } catch (error) {
      console.error(error);
    }

    return toCamel(convertMessage);
  };

  const updateThreadAndMessage = async displayThreads => {
    let displayThreadIds = displayThreads.map(item => item.id);
    const unreadThreadIds = totalThreads
      .filter(item => item.totalUnread > 0 && displayThreadIds.includes(item.id))
      .map(item => item.id);
    displayThreadIds = displayThreads.filter(item => unreadThreadIds.includes(item.id));

    let unreadMessageIdArr = [];
    for (let index = 0; index < displayThreadIds.length; index++) {
      const item = displayThreadIds[index];
      await getInteractor(prefixKey).LocalThreadService.update({ total_unread: 0 }, { thread_id: item.threadId });
      const messagesInThread = await getInteractor(prefixKey).LocalMessageService.getThreadMessage(item.threadId);
      const unreadMessagesInThread = messagesInThread.filter(
        item => item.status !== SystemConstant.MESSAGE_STATUS.read,
      );
      const unreadId = unreadMessagesInThread.map(item => item.id);
      unreadMessageIdArr = [...unreadMessageIdArr, ...unreadId];
    }

    // Dispatch event update message status = read with chunk = 20;
    const chunkMessage = 20;
    let offset = 0;
    let countMessage = 0;
    while (offset === countMessage) {
      const subUnreadMsgIdArr = unreadMessageIdArr.slice(offset, offset + chunkMessage);
      if (subUnreadMsgIdArr && subUnreadMsgIdArr.length > 0) {
        dispatch(
          ConversationActions.updateMessageStatus({
            messageIds: [...subUnreadMsgIdArr],
            status: SystemConstant.MESSAGE_STATUS.read,
          }),
        );

        await getInteractor(prefixKey).LocalMessageService.updateMessageStatusByMsgIdArr([...subUnreadMsgIdArr]);
      }
      offset += subUnreadMsgIdArr.length;
      countMessage += chunkMessage;
    }

    const selectedGroupId = getReduxState(ConversationSelectors.getSelectedGroupId);
    const isNeedUpdateViewMode = displayThreads.find(item => item.groupId === selectedGroupId);
    if (isNeedUpdateViewMode) {
      const parentMessageList = await getInteractor(prefixKey).LocalMessageService.find({
        source_id: displayThreads.map(item => item.threadId),
      });
      dispatch(
        ConversationActions.conversationSet({
          updateMessageStatus: {
            updateMessageIds: parentMessageList.map(item => item.id),
            modified: Date.now(),
          },
        }),
      );
    }
  };

  useEffect(() => {
    const fetchDataTimeout = setTimeout(() => {
      getThreadsInfo();
    }, AppConstant.DEBOUNCE_TIME);

    return () => clearTimeout(fetchDataTimeout);
  }, [isUpdateViewMode, selectedServerRedux, updateThreadStatus]);

  useEffect(() => {
    if (deleteGroup && deleteGroup.groupId) {
      const deleteGroupThread = totalThreads.find(item => item.groupId === deleteGroup.groupId);
      if (deleteGroupThread) {
        const currentTotalThread = totalThreads.filter(item => item.groupId !== deleteGroup.groupId);

        if (!isEqual(currentTotalThread, totalThreads)) setTotalThreads(currentTotalThread);
      }
    }
  }, [deleteGroup]);

  useEffect(() => {
    if (updateGroupData && updateGroupData.isAddMember) {
      getInteractor(prefixKey)
        .LocalThreadService.findOne({ group_id: updateGroupData.id })
        .then(groupThread => {
          if (false === Boolean(totalThreads.find(item => item.groupId === updateGroupData.id)) && groupThread?.id) {
            getThreadsInfo();
          }
        });
    }
  }, [updateGroupData]);

  return { totalThreads, updateThreadAndMessage };
};

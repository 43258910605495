import { createReducer, createActions } from "reduxsauce";
import {
  REDUX_STATE,
  requestReducerFunc,
  failureReducerFunc,
  successReducerFunc,
  setReducerFunc,
} from "./redux-structure";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  createGroup: ["data", "prefixKey"],
  updateGroup: ["data", "prefixKey"],
  deleteGroup: ["data", "prefixKey"],
  changeGroupPhoto: ["data", "prefixKey"],
  removeMemberGroup: ["data", "prefixKey"],
  addMemberGroup: ["data", "prefixKey"],
  muteGroupNotification: ["data", "prefixKey"],
  addAdmin: ["data", "prefixKey"],

  groupInfoSuccess: ["data"],
  groupInfoFailure: ["error", "data"],
  groupInfoSet: ["data"],
});

export const GroupInfoTypes = Types;
export const GroupInfoActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  ...REDUX_STATE,

  isCreateGroupSuccess: false,

  dataMemberToAdmin: null,
  updatingGroupData: {},
  deleteGroup: null,
};

/* ------------- Selector ------------- */
export const GroupInfoSelectors = {
  getUpdatingGroup: state => state.groupInfoRedux.updatingGroupData,
  getDeleteGroup: state => state.groupInfoRedux.deleteGroup,
};

/* ------------- Reducers ------------- */
const request = (state = INITIAL_STATE) =>
  requestReducerFunc({
    ...state,
    isCreateGroupSuccess: false,
    isDeleteGroupSuccess: false,
  });

const set = (state = INITIAL_STATE, action) => setReducerFunc(state, action);

const success = (state = INITIAL_STATE, action) => successReducerFunc(state, action);

const failure = (state = INITIAL_STATE, action) => failureReducerFunc(state, action);

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.CREATE_GROUP]: request,
  [Types.DELETE_GROUP]: request,
  [Types.UPDATE_GROUP]: request,
  [Types.CHANGE_GROUP_PHOTO]: request,
  [Types.REMOVE_MEMBER_GROUP]: request,
  [Types.ADD_MEMBER_GROUP]: request,
  [Types.MUTE_GROUP_NOTIFICATION]: request,
  [Types.ADD_ADMIN]: request,

  [Types.GROUP_INFO_SET]: set,
  [Types.GROUP_INFO_SUCCESS]: success,
  [Types.GROUP_INFO_FAILURE]: failure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const GroupInfoReducer = createReducer(INITIAL_STATE, HANDLERS);
